import React from "react";

function Map() {
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6718.784446205118!2d-16.915664!3d32.6490052!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xc606008f0b3d245%3A0xead0aedca95922f3!2sMadeira%20Parques%20Empresariais%20-%20Sociedade%20Gestora%20S.A.!5e0!3m2!1spt-PT!2spt!4v1741000282737!5m2!1spt-PT!2spt"
        width="100%"
        height="100%"
        title="Google embed map"
        style={{ border: 0, borderRadius: "5px" }}
        allowfullscreen
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
}

export default Map;