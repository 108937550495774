import React from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useNavigate } from "react-router-dom";

const BackButton = ({ className, onClick }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (onClick) {
      onClick();
    } else {
      navigate(-1);
    }
  };

  return (
    <div
      className={`flex text-xl items-center absolute cursor-pointer ${className}`}
      onClick={handleClick}
      style={{
        textShadow: "1px 1px grey",
        paddingLeft: "10px",
      }}
    >
      <KeyboardArrowLeftIcon fontSize="large" /> Voltar
    </div>
  );
};

export default BackButton;
