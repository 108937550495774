import styled from "styled-components";
import BottomDivider from "../../../resources/images/news/divider_bottom.svg";
import EventBG from "../../../resources/images/news/event_bg.svg";

export const NewsHero = styled("div")`
  width: 100%;
  /* min-height: 80vh; */
  position: relative;
  z-index: 1;

  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: -40px;
    background-image: url(${BottomDivider});
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1;
    width: 100%;
    transform: rotate(180deg);
  }
  & .newscontainer {
    position: relative;
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: -40px;
      background-image: url(${BottomDivider});
      background-size: cover;
      background-repeat: no-repeat;
      z-index: -1;
      width: 100%;
      height: 100px;
      transform: rotate(0deg);
    }
  }
  
`;
export const EventSideBar = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 100px;
  height: 100%;
  padding: 100px 12px 12px 12px;
  flex-basis: 33.33%;
  position: relative;
  background-color: #172726;

  &::after {
    position: absolute;
    left: -25px;
    top: 0;
    z-index: -1;
    height: 100%;
    width: 100px;
    content: "";
    display: block;
    background-image: url(${EventBG});
    background-size: cover;
    background-repeat: no-repeat;
  }
`;
export const TextureBackground = styled("div")`
  width: 100%;
  position: relative;
`;

export const Wrapped = styled.div`
    margin-left: 200px;
    margin-right: 200px;
    font-family: 'Open Sans', sans-serif;

  @media (min-width: 100px) and (max-width: 600px){
    margin-left: 16px;
    margin-right: 16px;
    font-family: 'Open Sans', sans-serif;
  }

  @media  (min-width:601px) and (max-width: 1020px) {
    margin-left: 80px;
    margin-right: 80px;
    font-family: 'Open Sans', sans-serif;
  }

  @media  (min-width: 1021px) and (max-width: 1440px) { 
    margin-left: 100px;
    margin-right: 100px;
    font-family: 'Open Sans', sans-serif;
  }

  @media only screen and (min-width: 1441px) and (max-width: 2550px) { 
    margin-left: 200px;
    margin-right: 200px;
    font-family: 'Open Sans', sans-serif;
  }
`;

export const ImagesAbout = styled("img")`
  @media (min-width: 100px) and (max-width: 600px){
    max-width: 12rem;
  }
  @media  (min-width:601px) and (max-width: 1020px) {
    max-width: 18rem;
  }
  @media  (min-width: 1021px) and (max-width: 1440px) { 
    max-width: 20rem;
  }
  @media only screen and (min-width: 1441px) and (max-width: 2550px) { 
    max-width: 24rem;
  }

`;

export const Card = styled("div")`

`;
