import { useEffect, useState } from "react";
import Warehouse from "../../images/home/top_house_button.png";
import WarehouseHover from "../../images/home/top_check_button.png"; // New hover image

const ScrollTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleScroll = () => {
    const scrollTop = document.documentElement.scrollTop;
    setIsVisible(scrollTop > 400);
  };

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const pulseAnimation = {
    animation: "pulse-size 1.5s infinite",
    transformOrigin: "center",
  };

  const styleSheet = document.styleSheets[0];
  const pulseKeyframes = `
    @keyframes pulse-size {
      0% { transform: scale(1); }
      50% { transform: scale(1.1); }
      100% { transform: scale(1); }
    }
  `;
  styleSheet.insertRule(pulseKeyframes, styleSheet.cssRules.length);

  return (
    <button
      className={`fixed z-[10000] scale-50 md:scale-75 origin-bottom-right -right-8 -bottom-8 sm:right-4 sm:bottom-4 p-2 rounded-full 
        ${isVisible ? "opacity-100" : "hidden"}`}
      onClick={handleClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        width: "96px", height: "96px", ...(isVisible ? pulseAnimation : {}),
      }}
    >
      <img
        src={Warehouse}
        alt="Botão de redirecionamento para o topo"
        className="absolute top-0 left-0 w-auto h-14 sm:h-20 transition-opacity duration-500"
        style={{ opacity: isHovered ? 0 : 1, transform: isHovered ? "rotateY(180deg) " : "rotateY(0deg)", transition: "opacity 0.5s ease-in-out, transform 0.5s ease-in-out" }}
      />
      <img
        src={WarehouseHover}
        alt="Botão de redirecionamento para o topo"
        className="absolute top-0 left-0 w-auto h-14 sm:h-20 transition-opacity duration-500"
        style={{ opacity: isHovered ? 1 : 0, transform: isHovered ? "rotateY(0deg)" : "rotateY(180deg)", transition: "opacity 0.5s ease-in-out, transform 0.5s ease-in-out" }}
      />
    </button>
  );
};

export default ScrollTopButton;
