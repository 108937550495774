import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";

const ContactDialog = ({ open, onConfirm, onCancel }) => {
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>Confirmar envio</DialogTitle>
      <DialogContent>
        Deseja continuar com o envio da mensagem? Você pode cancelar esta ação.
      </DialogContent>
      <DialogActions>
        <Button size="large" onClick={onCancel} color="error">
          <strong>Cancelar</strong>
        </Button>
        <Button size="large" onClick={onConfirm} variant="contained" sx={{backgroundColor: '#2F5E42'}}>
          Continuar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ContactDialog;