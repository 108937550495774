import "moment/locale/pt";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Wrapper from "../../resources/components/general/Wrapper";
import NewsHeroBG from "../../resources/images/news/news_heroo.svg";
import { NewsHero, Wrapped } from "./styles/inscricao_style";
import FooterHero from "../../resources/images/footer_banner_trees.svg";
import Rectangle from "../../resources/images/inscricao/Rectangle.png";

function Inscricao() {
  const navigate = useNavigate();

  return (
    <>
      <NewsHero className="min-h-[50vh]">
        <div
          className={`absolute top-0 left-0 h-full w-full after:z-[-1] after:block after:absolute after:top-0 after:left-0 after:bg-gradient-to-r  after:to-[transparent] after:w-full after:h-full`}
        >
          <img
            src={
              NewsHeroBG
            }
            alt="main card of highlights"
            className="absolute top-0 left-0 z-[-2] w-full h-full object-cover"
          />
        </div>
        <Wrapper closeFull>
          <div className="flex h-full">
            <div className="flex flex-col gap-5 pt-[100px] text-white basis-full">
              <h2 className="text-white text-3xl  font-bold mt-10">
                Inscrição
              </h2>
            </div>
          </div>
        </Wrapper>
      </NewsHero>

      <div className="flex flex-col items-center gap-10 md:flex-col sm:flex-col lg:flex-row overflow-hidden mt-20 md:mt-32">
        <Wrapped className={"pt-10 pb-10 h-full md:w-full lg:w-1/2 sm:w-full"}>
          <div className="items-center overflow-hidden">
            <div className={`flex flex-col basis-1/2 slide-in-left`}>
              <h2 className="text-left text-xl lg:text-3xl sm:text-2xl mb-10 font-semibold">
                Faça parte do nosso ecossistema<br />
                e impulsione a sua empresa.<br />
                Junte-se à nós!
              </h2>
              <p className="mt-5 sm:mt-10 text-justify text-[#1D2224] text-sm sm:text-lg ">
                Quer levar a sua empresa mais longe? Na Madeira Parques Empresariais, encontra o espaço certo
                para crescer, inovar e conectar-se com outras empresas. Aqui, terá acesso a tudo o que precisa
                para desenvolver o seu negócio com confiança. Junte-se a nós e descubra um ambiente pensado
                para o seu sucesso!
              </p>
              <button
                className="bg-[#2F5E42] text-[#FFFFFF] mt-10 w-full md:w-fit mx-auto md:px-10 py-3 text-lg md:text-1xl font-semibold block shadow-sm rounded-md cursor-pointer hover:shadow-md"
                onClick={() => navigate('/inscricao/formulario')}
              >
                Preencher Formulário
              </button>

            </div>
          </div>
        </Wrapped>
        <div className="flex justify-end items-center h-full md:w-full lg:w-1/2 sm:w-full mb-10">
          <img
            src={Rectangle}
            alt="Qualidade"
            className="w-auto  object-contain md:w-full sm:w-full sm:h-auto sm:mt-10"
          />
        </div>
      </div>

      <div className="relative w-full mt-20 md:mt-32">
        <img src={FooterHero} alt="Footer" className="w-full h-auto" />
        <div className="absolute inset-0 bg-gradient-to-b from-transparent from-80% via-transparent to-[#121E1A] h-1/2 top-1/2"></div>
      </div>
    </>
  );
}

export default Inscricao;
