import { useEffect } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import axiosConfig from "./providers/axiosConfig";
import Page from "./resources/components/general/Page";
import About from "./routes/about";
import Contacts from "./routes/contactos";
import MpeAtiva from "./routes/mpe-ativa";
import Agendar from "./routes/agendar";
import Documents from "./routes/documents";
import Home from "./routes/home";
import News from "./routes/news";
import Quest from "./routes/questionario";
import ListEvents from "./routes/news/Listevents";
import ShowHighlights from "./routes/news/ShowHighlights";
import EventDetails from "./routes/news/Showevents";
import NewsDetails from "./routes/news/Shownews";
import Privacy from "./routes/privacy";
import Aids from "./routes/producers/aids";
import Auxiliaries from "./routes/producers/auxiliaries";
import Conditions from "./routes/producers/conditions";
import Correctives from "./routes/producers/correctives";
import Insurance from "./routes/producers/insurance";
import Media from "./routes/producers/media";
import ShowMedia from "./routes/producers/media/ShowMedia";
import Nurseries from "./routes/producers/nurseries";
import Pests from "./routes/producers/pests";
import Practices from "./routes/producers/practices";
import Quality from "./routes/producers/quality";
import Rules from "./routes/producers/rules";
import Production from "./routes/production";
import Announcements from "./routes/production/announcements";
import Products from "./routes/production/products";
import Terms from "./routes/terms";
import Parks from "./routes/parks";
import Institucional from "./routes/institucional";
import Sustenbility from "./routes/sustentibility";
import Support from "./routes/support";
import SupportInfo from "./routes/support/support_info";



import ReportingChannel from "./routes/reporting-channel";
import Aequipa from "./routes/aequipa";
import Inscricao from "./routes/inscricao";
import Formulario from "./routes/inscricao/formulario";
function App() {
  useEffect(() => {
    axiosConfig.put("website_views");
  }, []);

  return (
    <>
      <Router>
        <Routes>
          <Route
            path="/about"
            element={
              <Page>
                <About />
              </Page>
            }
          />
          <Route
            path="/forms"
            element={
              <Page>
                <Quest />
              </Page>
            }
          />
          <Route
            path="/communications"
            element={
              <Page>
                <News />
              </Page>
            }
          />
          <Route
            path="/events"
            element={
              <Page>
                <ListEvents />
              </Page>
            }
          />
          <Route
            path="/news/show/:id"
            element={
              <Page>
                <NewsDetails />
              </Page>
            }
          />
          <Route
            path="/parks"
            element={
              <Page>
                <Parks />
              </Page>
            }
          />

          <Route
            path="/highlights/show/:id"
            element={
              <Page>
                <ShowHighlights />
              </Page>
            }
          />
          <Route
            path="/events/show/:id"
            element={
              <Page>
                <EventDetails />
              </Page>
            }
          />
          <Route
            path="/production"
            element={
              <Page>
                <Production />
              </Page>
            }
          />
          <Route
            path="/production/products"
            element={
              <Page>
                <Products />
              </Page>
            }
          />
          <Route
            path="/production/announcements"
            element={
              <Page>
                <Announcements />
              </Page>
            }
          />
          <Route
            path="/producers/media"
            element={
              <Page>
                <Media />
              </Page>
            }
          />
          <Route
            path="/producers/media/show/:id"
            element={
              <Page>
                <ShowMedia />
              </Page>
            }
          />
          <Route
            path="/producers/insurance"
            element={
              <Page>
                <Insurance />
              </Page>
            }
          />
          <Route
            path="/producers/aids"
            element={
              <Page>
                <Aids />
              </Page>
            }
          />
          <Route
            path="/producers/nurseries"
            element={
              <Page>
                <Nurseries />
              </Page>
            }
          />
          <Route
            path="/producers/rules"
            element={
              <Page>
                <Rules />
              </Page>
            }
          />
          <Route
            path="/producers/practices"
            element={
              <Page>
                <Practices />
              </Page>
            }
          />
          <Route
            path="/producers/conditions"
            element={
              <Page>
                <Conditions />
              </Page>
            }
          />
          <Route
            path="/producers/quality"
            element={
              <Page>
                <Quality />
              </Page>
            }
          />
          <Route
            path="/producers/pests"
            element={
              <Page>
                <Pests />
              </Page>
            }
          />
          <Route
            path="/producers/correctives"
            element={
              <Page>
                <Correctives />
              </Page>
            }
          />
          <Route
            path="/producers/auxiliaries"
            element={
              <Page>
                <Auxiliaries />
              </Page>
            }
          />
          <Route
            path="/documents"
            element={
              <Page>
                <Documents />
              </Page>
            }
          />
          <Route
            path="/institucional"
            element={
              <Page>
                <Institucional />
              </Page>
            }
          />
          <Route
            path="/sustenbility"
            element={
              <Page>
                <Sustenbility />
              </Page>
            }
          />
          <Route
            path="/support"
            element={
              <Page>
                <Support />
              </Page>
            }
          />
          <Route
            path="/support/info"
            element={
              <Page>
                <SupportInfo />
              </Page>
            }
          />
          <Route
            path="/contacts"
            element={
              <Page>
                <Contacts />
              </Page>
            }
          />
          <Route
            path="/mpe-ativa"
            element={
              <Page>
                <MpeAtiva />
              </Page>
            }
          />
          <Route
            path="/agendar"
            element={
              <Page>
                <Agendar />
              </Page>
            }
          />
          <Route
            path="/privacy"
            element={
              <Page>
                <Privacy />
              </Page>
            }
          />
          <Route
            path="/terms"
            element={
              <Page>
                <Terms />
              </Page>
            }
          />
          <Route
            path="/reporting-channel"
            element={
              <Page>
                <ReportingChannel />
              </Page>
            }
          />
          <Route
            path="/aequipa"
            element={
              <Page>
                <Aequipa />
              </Page>
            }
          />
          <Route
            path="/inscricao"
            element={
              <Page>
                <Inscricao />
              </Page>
            }
          />
          <Route
            path="/inscricao/formulario"
            element={
              <Page>
                <Formulario />
              </Page>
            }
          />

          <Route
            path="*"
            element={
              <Page>
                <Home />
              </Page>
            }
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
