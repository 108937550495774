import React, { useEffect, useState } from "react";
import styled from "styled-components";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useMediaQuery } from "@mui/material";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as Logo } from "../../logo/mpe.svg";
import { ReactComponent as Menu } from "../../menu/menu.svg";
import { ReactComponent as MenuClose } from "../../menu/menu_close.svg";
import SecondaryNavigationJpg from "../../images/navigation/SecondaryNavigationImg.jpg";

const NavigationBar = styled("div")`
  width: 100%;
  height: 120px;
  position: fixed;
  z-index: 100;

  & a,
  p {
    font-family: "Prompt", sans-serif;
  }
  &.scrolled {
    background-color: #121e1a;
  }
  & .nav-links {
    display: none;
  }
  @media (min-width: 1024px) {
    & .nav-links {
      display: flex;
    }
  }
  & .mobilenavigation {
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const SecondaryNavigation = styled("div")`
  display: flex;
  background-image: url(${SecondaryNavigationJpg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  font-size: 15px;
  margin-top: 2px;

  height: 0;
  width: 100%;
  overflow: hidden;
  opacity: 0;

  animation: ${({ isOpen }) => (isOpen ? "expand 0.5s ease-out forwards" : "collapse 0.5s ease-out forwards")};

  @keyframes expand {
    0% {
      height: 0;
      opacity: 0;
    }
    100% {
      height: 110px;
      opacity: 1;
    }
  }

  @keyframes collapse {
    0% {
      height: 110px;
      opacity: 1;
    }
    100% {
      height: 0;
      opacity: 0;
    }
  }
`;

function NavBarWrapper({
  children,
  className,
  right,
  left,
  oneHalf,
  twoThirds,
  full,
  closeFull,
}) {
  return (
    <div
      className={`${className} ${
        oneHalf
          ? "w-6/12"
          : twoThirds
          ? "w-8/12"
          : closeFull
          ? "w-10/12"
          : full
          ? "w-full"
          : "w-12/12"
      } 
      `}
    >
      {children}
    </div>
  );
}

function NavBar() {
  const desktop = useMediaQuery("(min-width:1275px)");
  const [scrolled, setScrolled] = useState(true);
  const [open, setOpen] = useState(false);
  // vars of  the sub Menu
  const [about, setAbout] = useState(false);
  const [companyParks, setCompanyParks] = useState(false);
  const [communication, setCommunication] = useState(false);
  const [documents, setDocuments] = useState(false);
  const [contacts, setContacts] = useState(false);

  const navigate = useNavigate();

  const location = useLocation();

  const activeLink =
    //Sobre Nós
    location.pathname === "/about"
      ? "about"
      : location.pathname === "/aequipa"
      ? "about"
      : //Parques Empresariais
      location.pathname === "/parks"
      ? "parks"
      : location.pathname === "/inscricao"
      ? "parks"
      : //Sustentabilidade
      location.pathname === "/sustenbility"
      ? "sustentability"
      : //Comunicação
      location.pathname === "/production/announcements"
      ? "communications"
      : location.pathname === "/events"
      ? "communications"
      : location.pathname === "/communications"
      ? "communications"
      : location.pathname === "/forms"
      ? "communications"
      : //Apoio ao Investimento
      location.pathname === "/support"
      ? "investment"
      : //Documentos
      location.pathname === "/Institucional"
      ? "documents"
      : location.pathname === "/documents"
      ? "documents"
      : //Contactos
      location.pathname === "/contacts"
      ? "contacts"
      : location.pathname === "/agendar"
      ? "contacts"
      : location.pathname === "/mpe-ativa"
      ? "contacts"
      : "/index";

  useEffect(() => {
    if (open) {
      window.document.body.style.overflow = "hidden";
    } else {
      window.document.body.style.overflow = "auto";
    }
  }, [scrolled, open]);

  return (
    //NavBar for the pc
    <NavigationBar className={scrolled && "scrolled"}>
      {desktop ? (
        <>
          <NavBarWrapper className="relative z-40">
            {/* Gray bar, with all */}
            <div className="flex flex-col">
              {/*Logo and Button */}
              <div className="ml-3 mr-20 flex flex-row h-[75px] justify-between items-center  2xl:pr-12">
                <Logo
                  className="h-[70px] cursor-pointer mt-2"
                  onClick={() => navigate("/")}
                />
                <a
                  href={process.env.REACT_APP_ADMIN_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="group p-1.5 text-[13px] flex space-x-1 px-3 border bg-[#172726] text-white rounded-lg shadow-lg hover:bg-white  hover:text-[#2F5E42] hover:shadow-xl transition duration-300"
                >
                  <h1 className="font-opensans mt-1">Área Reservada</h1>
                  <svg
                    className="h-5 fill-current group:text-white group-hover:text-[#2F5E42]"
                    viewBox="0 0 24 24"
                  >
                    <path d="M17 9V7c0-2.8-2.2-5-5-5S7 4.2 7 7v2c-1.7 0-3 1.3-3 3v7c0 1.7 1.3 3 3 3h10c1.7 0 3-1.3 3-3v-7c0-1.7-1.3-3-3-3M9 7c0-1.7 1.3-3 3-3s3 1.3 3 3v2H9zm4.1 8.5l-.1.1V17c0 .6-.4 1-1 1s-1-.4-1-1v-1.4c-.6-.6-.7-1.5-.1-2.1s1.5-.7 2.1-.1c.6.5.7 1.5.1 2.1" />
                  </svg>
                </a>
              </div>
              {/* bar, de les 2xl-3xl, */}
              <hr
                className="opacity-25"
                onMouseEnter={() => {
                  setAbout(false);
                  setCompanyParks(false);
                  setCommunication(false);
                  setDocuments(false);
                  setContacts(false);
                }}
              />
              {/* titles bar */}
              <div className="inline-block text-base justify-center mr-20 mt-3 nav-links">
                <Link
                  to="/"
                  onClick={() => {
                    setAbout(false);
                    setCompanyParks(false);
                    setCommunication(false);
                    setDocuments(false);
                    setContacts(false);
                  }}
                  onMouseEnter={() => {
                    setAbout(false);
                    setCompanyParks(false);
                    setCommunication(false);
                    setDocuments(false);
                    setContacts(false);
                  }}
                  className={`${
                    activeLink === "/index" ? "border-b-2 border-white" : ""
                  }
                  text-white font-light hover:border-b hover:opacity-100 px-5`}
                >
                  Início
                </Link>
                <Link
                  className={`${
                    activeLink === "about" ? "border-b-2 border-white" : ""
                  }
                  whitespace-nowrap text-white font-light hover:border-b hover:opacity-100 px-5`}
                  onClick={() => {
                    ("about");
                    setAbout(!about);
                    setCompanyParks(false);
                    setCommunication(false);
                    setDocuments(false);
                    setContacts(false);
                  }}
                >
                  Sobre Nós
                </Link>
                <Link
                  className={`${
                    activeLink === "parks" ? "border-b-2 border-white" : ""
                  }
                  whitespace-nowrap text-white font-light hover:border-b hover:opacity-100 px-5`}
                  onClick={() => {
                    ("parks");
                    setAbout(false);
                    setCompanyParks(!companyParks);
                    setCommunication(false);
                    setDocuments(false);
                    setContacts(false);
                  }}
                >
                  Parques Empresariais
                </Link>
                <Link
                  to="/sustenbility"
                  onClick={() => {
                    ("sustentability");
                    setAbout(false);
                    setCompanyParks(false);
                    setCommunication(false);
                    setDocuments(false);
                    setContacts(false);
                  }}
                  onMouseEnter={() => {
                    setAbout(false);
                    setCompanyParks(false);
                    setCommunication(false);
                    setDocuments(false);
                    setContacts(false);
                  }}
                  className={`${
                    activeLink === "sustentability"
                      ? "border-b-2 border-white"
                      : ""
                  }
                  text-white font-light hover:border-b hover:opacity-100 px-5`}
                >
                  Sustentabilidade
                </Link>
                <Link
                  className={`${
                    activeLink === "communications"
                      ? "border-b-2 border-white"
                      : ""
                  }
                  font-prompt text-white font-light hover:border-b hover:opacity-100 px-5 hover:cursor-pointer`}
                  onClick={() => {
                    ("communications");
                    setAbout(false);
                    setCompanyParks(false);
                    setCommunication(!communication);
                    setDocuments(false);
                    setContacts(false);
                  }}
                >
                  Comunicação
                </Link>
                <Link
                  to="/support"
                  onClick={() => {
                    ("investment");
                    setAbout(false);
                    setCompanyParks(false);
                    setCommunication(false);
                    setDocuments(false);
                    setContacts(false);
                  }}
                  onMouseEnter={() => {
                    setAbout(false);
                    setCompanyParks(false);
                    setCommunication(false);
                    setDocuments(false);
                    setContacts(false);
                  }}
                  className={`${
                    activeLink === "investment" ? "border-b-2 border-white" : ""
                  }
                  font-prompt whitespace-nowrap text-white font-light hover:border-b hover:opacity-100 px-5 cursor-pointer`}
                >
                  Apoio ao Investimento
                </Link>
                <Link
                  className={`${
                    activeLink === "documents" ? "border-b-2 border-white" : ""
                  }
                   text-white font-light hover:border-b hover:opacity-100 px-5`}
                  onClick={() => {
                    ("documents");
                    setAbout(false);
                    setCompanyParks(false);
                    setCommunication(false);
                    setDocuments(!documents);
                    setContacts(false);
                  }}
                >
                  Documentos
                </Link>
                <Link
                  className={`${
                    activeLink === "contacts" ? "border-b-2 border-white" : ""
                  } 
                  text-white font-light hover:border-b hover:opacity-100 px-5`}
                  onClick={() => {
                    ("contacts");
                    setAbout(false);
                    setCompanyParks(false);
                    setCommunication(false);
                    setDocuments(false);
                    setContacts(!contacts);
                  }}
                >
                  Contactos
                </Link>
              </div>
            </div>
          </NavBarWrapper>
          {/* All the subMenus */}
          {/* SubMenu About */}
          {about && (
            <>
              {/* text-[22px] nav-links flex gap-5 mx-auto p-5 */}
              <SecondaryNavigation
                isOpen={about}
                className="justify-start items-center relative z-20"
                onMouseLeave={() => {
                  ("about");
                  setAbout(false);
                  setCompanyParks(false);
                  setCommunication(false);
                  setDocuments(false);
                  setContacts(false);
                }}
              >
                <div className="flex mx-auto w-[60%] 3xl:w-[47%] 3xl:px-52">
                  <Link
                    onClick={() => setAbout(false)}
                    to="/about"
                    className="group hover:text-white items-center flex px-1 space-x-2 mr-4 pr-20 border border-black text-black rounded-lg shadow-lg hover:bg-black"
                  >
                    <svg
                      className="h-5 m-2 fill-current text-black group-hover:text-white"
                      viewBox="0 0 24 24"
                    >
                      <path d="M21 19h2v2H1v-2h2V4a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v15h2V9h3a1 1 0 0 1 1 1zM7 11v2h4v-2zm0-4v2h4V7z" />
                    </svg>
                    <h1>A MPE</h1>
                  </Link>
                  {/* 2 */}
                  <Link
                    onClick={() => setAbout(false)}
                    to="/aequipa"
                    className="group hover:text-white items-center flex px-1 space-x-2 mr-4 pr-20 border border-black text-black rounded-lg shadow-lg hover:bg-black"
                  >
                    <svg
                      className="h-5 m-2  fill-current text-black group-hover:text-white"
                      viewBox="0 0 24 24"
                    >
                      <path d="M14.754 10c.966 0 1.75.784 1.75 1.75v4.749a4.501 4.501 0 0 1-9.002 0V11.75c0-.966.783-1.75 1.75-1.75zm-7.623 0a2.7 2.7 0 0 0-.62 1.53l-.01.22v4.749c0 .847.192 1.649.534 2.365Q6.539 18.999 6 19a4 4 0 0 1-4-4.001V11.75a1.75 1.75 0 0 1 1.606-1.744L3.75 10zm9.744 0h3.375c.966 0 1.75.784 1.75 1.75V15a4 4 0 0 1-5.03 3.866c.3-.628.484-1.32.525-2.052l.009-.315V11.75c0-.665-.236-1.275-.63-1.75M12 3a3 3 0 1 1 0 6a3 3 0 0 1 0-6m6.5 1a2.5 2.5 0 1 1 0 5a2.5 2.5 0 0 1 0-5m-13 0a2.5 2.5 0 1 1 0 5a2.5 2.5 0 0 1 0-5" />
                    </svg>
                    <h1>A Equipa</h1>
                  </Link>
                </div>
              </SecondaryNavigation>
              <div
                onClick={() => {
                  setAbout(false);
                }}
                className="fixed w-screen h-screen left-0 top-0 z-10"
              />
            </>
          )}
          {/* SubMenu Parques Empresariais */}
          {companyParks && (
            <>
              <SecondaryNavigation
                isOpen={companyParks}
                className="items-center justify-center relative z-20"
                onMouseLeave={() => {
                  ("about");
                  setAbout(false);
                  setCompanyParks(false);
                  setCommunication(false);
                  setDocuments(false);
                  setContacts(false);
                }}
              >
                <div className="flex mx-auto w-[60%] 3xl:w-[47%] 3xl:px-52 ">
                  {/* <div className="flex flex-row w-[50%] mx-auto px-52"> */}
                  <Link
                    onClick={() => setCompanyParks(false)}
                    to="/parks"
                    className="group hover:text-white  items-center flex px-1 space-x-2 mr-4 pr-20 border border-black text-black rounded-lg shadow-lg hover:bg-black"
                  >
                    <svg
                      className="h-4 m-2 fill-current text-black group-hover:text-white"
                      viewBox="0 0 640 512"
                    >
                      <path d="M0 488V171.3c0-26.2 15.9-49.7 40.2-59.4L308.1 4.8c7.6-3.1 16.1-3.1 23.8 0l267.9 107.1c24.3 9.7 40.2 33.3 40.2 59.4V488c0 13.3-10.7 24-24 24h-48c-13.3 0-24-10.7-24-24V224c0-17.7-14.3-32-32-32H128c-17.7 0-32 14.3-32 32v264c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24m488 24H152c-13.3 0-24-10.7-24-24v-56h384v56c0 13.3-10.7 24-24 24M128 400v-64h384v64zm0-96v-80h384v80z" />
                    </svg>
                    {/* <Parque className=" text-white hover:text-green-500 h-6" />  */}
                    <h1>Os Nossos Parques</h1>
                  </Link>

                  <Link
                    onClick={() => setCompanyParks(false)}
                    to="/inscricao"
                    className="group hover:text-white  items-center flex px-1 space-x-2 mr-4 pr-20 border border-black text-black rounded-lg shadow-lg hover:bg-black"
                  >
                    {/* <NameBook className=" text-black hover:text-white h-6" />  */}
                    <svg
                      className="h-6 m-2 fill-current text-black group-hover:text-white"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M6 6a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2zm3.49 11.598l.001-.005l.004-.02a12 12 0 0 1 .078-.35c.053-.228.129-.53.219-.83c.07-.234.146-.455.222-.633c.094.189.193.424.3.681l.03.074c.117.283.243.587.366.825c.067.128.147.265.24.375c.074.087.26.285.55.285c.326 0 .54-.196.658-.337c.106-.128.193-.287.253-.397l.014-.024l.074-.131q.031.052.073.13l.013.023c.06.11.148.27.255.4c.12.142.334.336.66.336c.256 0 .507-.13.67-.224c.189-.11.383-.25.551-.382a10 10 0 0 0 .57-.482l.047-.044l.004-.003a.5.5 0 0 0-.684-.73l-.002.002l-.008.008l-.032.029a9 9 0 0 1-.51.432a4 4 0 0 1-.44.306q-.06.034-.099.053a4 4 0 0 1-.118-.206l-.006-.01a2.4 2.4 0 0 0-.275-.42A.88.88 0 0 0 12.5 16c-.32 0-.539.18-.668.327c-.12.138-.214.307-.278.422l-.01.02c-.087-.18-.18-.403-.281-.649l-.025-.061a9 9 0 0 0-.417-.911a2 2 0 0 0-.266-.383c-.094-.1-.282-.265-.555-.265c-.276 0-.464.168-.558.273c-.105.117-.189.26-.256.394a6 6 0 0 0-.352.94a15 15 0 0 0-.323 1.286v.006l-.001.002a.5.5 0 0 0 .98.197M9 6.54a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm-.5 2.71a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1H9a.5.5 0 0 1-.5-.5M9 11a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <h1>Inscrição</h1>
                  </Link>
                </div>
              </SecondaryNavigation>
              <div
                onClick={() => {
                  setCompanyParks(false);
                }}
                className="fixed w-screen h-screen left-0 top-0 z-10"
              />
            </>
          )}

          {/* SubMenu Comunicação */}
          {communication && (
            <>
              <SecondaryNavigation
                isOpen={communication}
                className="items-center justify-center relative z-20"
                onMouseLeave={() => {
                  ("about");
                  setAbout(false);
                  setCompanyParks(false);
                  setCommunication(false);
                  setDocuments(false);
                  setContacts(false);
                }}
              >
                {/* here Comunicação */}
                <div className="flex mx-auto w-[60%] 3xl:w-[47%] 3xl:px-52">
                  <Link
                    onClick={() => setCommunication(false)}
                    to="/production/announcements"
                    className="group hover:text-white  items-center flex px-1 space-x-2 mr-4 pr-20 border border-black text-black rounded-lg shadow-lg hover:bg-black"
                  >
                    <svg
                      className="h-5 m-2 fill-current text-black group-hover:text-white"
                      viewBox="0 0 24 24"
                    >
                      <path d="M12 8H4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h1v4a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-4h3l5 4V4zm9.5 4c0 1.71-.96 3.26-2.5 4V8c1.53.75 2.5 2.3 2.5 4" />
                    </svg>
                    {/* <Speaker className=" text-white hover:text-green-500 h-6" />  */}
                    <h1>Anúncios</h1>
                  </Link>

                  <Link
                    onClick={() => setCommunication(false)}
                    to="/events"
                    className="group hover:text-white  items-center flex px-1 space-x-2 mr-4 pr-20 border border-black text-black rounded-lg shadow-lg hover:bg-black"
                  >
                    {/* <Event className=" text-white hover:text-green-500 h-6" /> */}
                    <svg
                      className="h-5 m-2 fill-current text-black group-hover:text-white"
                      viewBox="0 0 24 24"
                    >
                      <path d="M14.5 18q-1.05 0-1.775-.725T12 15.5t.725-1.775T14.5 13t1.775.725T17 15.5t-.725 1.775T14.5 18M5 22q-.825 0-1.412-.587T3 20V6q0-.825.588-1.412T5 4h1V2h2v2h8V2h2v2h1q.825 0 1.413.588T21 6v14q0 .825-.587 1.413T19 22zm0-2h14V10H5z" />
                    </svg>
                    <h1>Eventos</h1>
                  </Link>

                  <Link
                    onClick={() => setCommunication(false)}
                    to="/communications"
                    className="group hover:text-white  items-center flex px-1 space-x-2 mr-4 pr-20 border border-black text-black rounded-lg shadow-lg hover:bg-black"
                  >
                    {/* <Sheet className=" text-white hover:text-green-500 h-6" /> */}
                    <svg
                      className="h-5 m-2 fill-current text-black group-hover:text-white"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 21q-.825 0-1.412-.587T3 19V5q0-.825.588-1.412T5 3h11l5 5v11q0 .825-.587 1.413T19 21zm2-4h10v-2H7zm0-4h10v-2H7zm8-4h4l-4-4zM7 9h5V7H7z" />
                    </svg>
                    <h1>Notícias</h1>
                  </Link>

                  <Link
                    onClick={() => setCommunication(false)}
                    to="/forms"
                    className="group hover:text-white  items-center flex px-1 space-x-2 mr-4 pr-20 border border-black text-black rounded-lg shadow-lg hover:bg-black"
                  >
                    {/* <List className=" text-white hover:text-green-500 h-6" /> */}
                    <svg
                      className="h-5 m-2 fill-current text-black group-hover:text-white"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 21q-.825 0-1.412-.587T3 19V5q0-.825.588-1.412T5 3h11l5 5v11q0 .825-.587 1.413T19 21zm2-4h10v-2H7zm0-4h10v-2H7zm8-4h4l-4-4zM7 9h5V7H7z" />
                    </svg>
                    <h1>Questionários</h1>
                  </Link>
                </div>
              </SecondaryNavigation>
              <div
                onClick={() => {
                  setCommunication(false);
                }}
                className="fixed w-screen h-screen left-0 top-0 z-10"
              />
            </>
          )}
          {/* SubMenu Apoio ao Investimento */}
          {documents && (
            <>
              <SecondaryNavigation
                isOpen={documents}
                className="items-center justify-center relative z-20 "
                onMouseLeave={() => {
                  ("about");
                  setAbout(false);
                  setCompanyParks(false);
                  setCommunication(false);
                  setDocuments(false);
                  setContacts(false);
                }}
              >
                <div className="flex mx-auto w-[60%] 3xl:w-[47%] 3xl:px-52">
                  <Link
                    onClick={() => setDocuments(false)}
                    to="/Institucional"
                    className="group hover:text-white  items-center flex px-1 space-x-2 mr-4 pr-20 border border-black text-black rounded-lg shadow-lg hover:bg-black"
                  >
                    {/* <Institucional className=" text-white hover:text-green-500 h-6" /> */}
                    <svg
                      className="h-5 m-2 fill-current text-black group-hover:text-white"
                      viewBox="0 0 1920 1792"
                    >
                      <path d="m960 0l960 384v128h-128q0 26-20.5 45t-48.5 19H197q-28 0-48.5-19T128 512H0V384zM256 640h256v768h128V640h256v768h128V640h256v768h128V640h256v768h59q28 0 48.5 19t20.5 45v64H128v-64q0-26 20.5-45t48.5-19h59zm1595 960q28 0 48.5 19t20.5 45v128H0v-128q0-26 20.5-45t48.5-19z" />
                    </svg>

                    <h1>Institucional</h1>
                  </Link>

                  <Link
                    onClick={() => setDocuments(false)}
                    to="/documents"
                    className="group hover:text-white  items-center flex px-1 space-x-2 mr-4 pr-20 border border-black text-black rounded-lg shadow-lg hover:bg-black"
                  >
                    {/* <Balance className=" text-black hover:text-white h-6" /> */}
                    <svg
                      className="h-5 m-2 fill-current text-black group-hover:text-white"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8.75.75V2h.985c.304 0 .603.08.867.231l1.29.736q.058.033.124.033h2.234a.75.75 0 0 1 0 1.5h-.427l2.111 4.692a.75.75 0 0 1-.154.838l-.53-.53l.529.531l-.001.002l-.002.002l-.006.006l-.006.005l-.01.01l-.045.04q-.317.265-.686.45C14.556 10.78 13.88 11 13 11a4.5 4.5 0 0 1-2.023-.454a3.5 3.5 0 0 1-.686-.45l-.045-.04l-.016-.015l-.006-.006l-.004-.004v-.001a.75.75 0 0 1-.154-.838L12.178 4.5h-.162c-.305 0-.604-.079-.868-.231l-1.29-.736a.25.25 0 0 0-.124-.033H8.75V13h2.5a.75.75 0 0 1 0 1.5h-6.5a.75.75 0 0 1 0-1.5h2.5V3.5h-.984a.25.25 0 0 0-.124.033l-1.289.737c-.265.15-.564.23-.869.23h-.162l2.112 4.692a.75.75 0 0 1-.154.838l-.53-.53l.529.531l-.001.002l-.002.002l-.006.006l-.016.015l-.045.04q-.317.265-.686.45C4.556 10.78 3.88 11 3 11a4.5 4.5 0 0 1-2.023-.454a3.5 3.5 0 0 1-.686-.45l-.045-.04l-.016-.015l-.006-.006l-.004-.004v-.001a.75.75 0 0 1-.154-.838L2.178 4.5H1.75a.75.75 0 0 1 0-1.5h2.234a.25.25 0 0 0 .125-.033l1.288-.737c.265-.15.564-.23.869-.23h.984V.75a.75.75 0 0 1 1.5 0m2.945 8.477c.285.135.718.273 1.305.273s1.02-.138 1.305-.273L13 6.327Zm-10 0c.285.135.718.273 1.305.273s1.02-.138 1.305-.273L3 6.327Z" />
                    </svg>

                    <h1>Legislação</h1>
                  </Link>
                </div>
              </SecondaryNavigation>
              <div
                onClick={() => {
                  setDocuments(false);
                }}
                className="fixed w-screen h-screen left-0 top-0 z-10"
              />
            </>
          )}
          {/* SuvMenu Contactos */}
          {contacts && (
            <>
              {/* <SecondaryNavigation className="items-center relative z-20"> */}
              <SecondaryNavigation
                isOpen={contacts}
                className="items-center justify-center relative z-20"
                onMouseLeave={() => {
                  ("about");
                  setAbout(false);
                  setCompanyParks(false);
                  setCommunication(false);
                  setDocuments(false);
                  setContacts(false);
                }}
              >
                <div className="flex mx-auto w-[60%] 3xl:w-[47%] 3xl:px-52">
                  <Link
                    onClick={() => setContacts(false)}
                    to="/contacts"
                    className="group hover:text-white  items-center flex px-1 space-x-2 mr-4 pr-20 border border-black text-black rounded-lg shadow-lg hover:bg-black"
                  >
                    {/* <Contact className=" text-white hover:text-green-500 h-6" /> */}
                    <svg
                      className="h-5 m-2 fill-current text-black group-hover:text-white"
                      viewBox="0 0 24 24"
                    >
                      <path d="M21 2H6a2 2 0 0 0-2 2v3H2v2h2v2H2v2h2v2H2v2h2v3a2 2 0 0 0 2 2h15a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1m-8 2.999c1.648 0 3 1.351 3 3A3.01 3.01 0 0 1 13 11c-1.647 0-3-1.353-3-3.001c0-1.649 1.353-3 3-3M19 18H7v-.75c0-2.219 2.705-4.5 6-4.5s6 2.281 6 4.5z" />
                    </svg>

                    <h1>Contactar</h1>
                  </Link>

                  <Link
                    onClick={() => setContacts(false)}
                    to="/agendar"
                    className="group hover:text-white  items-center flex px-1 space-x-2 mr-4 pr-20 border border-black text-black rounded-lg shadow-lg hover:bg-black"
                  >
                    {/* <Agenda className=" text-black hover:text-white h-6" /> */}
                    <svg
                      className="h-5 m-2 fill-current text-black group-hover:text-white"
                      viewBox="0 0 24 24"
                    >
                      <path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-7zm-5-9a1 1 0 0 1 1 1v1h2a2 2 0 0 1 2 2v3H3V7a2 2 0 0 1 2-2h2V4a1 1 0 0 1 2 0v1h6V4a1 1 0 0 1 1-1" />
                    </svg>
                    <h1>Agendar Reunião</h1>
                  </Link>
                  <Link
                    onClick={() => setContacts(false)}
                    to="/mpe-ativa"
                    className="group hover:text-white  items-center flex px-1 space-x-2 mr-4 pr-20 border border-black text-black rounded-lg shadow-lg hover:bg-black"
                  >
                    {/* <StopSign className=" text-black hover:text-white h-6" /> */}
                    <svg
                      className="h-4 m-2 fill-current text-black group-hover:text-white"
                      viewBox="0 0 14 14"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M3.854.44A1.5 1.5 0 0 1 4.914 0h4.172a1.5 1.5 0 0 1 1.06.44l3.415 3.414A1.5 1.5 0 0 1 14 4.914v4.172a1.5 1.5 0 0 1-.44 1.06l-3.414 3.415a1.5 1.5 0 0 1-1.06.439H4.914a1.5 1.5 0 0 1-1.06-.44L.439 10.147A1.5 1.5 0 0 1 0 9.086V4.914c0-.398.158-.78.44-1.06zM7 3.124a.75.75 0 0 1 .75.75v3.25a.75.75 0 0 1-1.5 0v-3.25a.75.75 0 0 1 .75-.75m1 6.75a1 1 0 1 1-2 0a1 1 0 0 1 2 0"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <h1 className="flex flex-row">MPE Ativa</h1>
                  </Link>
                </div>
              </SecondaryNavigation>
              <div
                onClick={() => {
                  setContacts(false);
                }}
                className="fixed w-screen h-screen left-0 top-0 z-10"
              />
            </>
          )}
        </>
      ) : (
        //mobile
        <NavBarWrapper>
          {/* Logos and 3 lines */}
          <div className="w-full relative ">
            <div
              className={`mx-4 my-4 flex h-[80px] items-center justify-between z-10 relative ${
                open && "bg-black transition-all duration-700 delay-100 "
              }`}
            >
              <Logo
                className={`w-20 cursor-pointer ${
                  open ? "opacity-0" : "opacity-100"
                } transition-all`}
                onClick={() => navigate("/")}
              />
              {open ? (
                <MenuClose
                  onClick={() => setOpen(!open)}
                  className="ml-auto cursor-pointer"
                />
              ) : (
                <Menu
                  onClick={() => setOpen(!open)}
                  className="cursor-pointer"
                />
              )}
            </div>
            <div
              className={`mobilenavigation overscroll-contain overflow-auto mb-5 h-screen fixed bg-black w-screen left-0 z-[0] transition-all duration-300 ease-in-out ${
                open ? "top-0" : "top-[-10002px]"
              }`}
            >
              <NavBarWrapper>
                {/* titles bar Mobile */}
                <div className="flex flex-col mt-[100px] gap-5 m-5">
                  <Link
                    onClick={() => setOpen(false)}
                    to="/"
                    className="text-white text-xl font-medium"
                  >
                    Início
                  </Link>

                  {/* Title Sobre Nós */}
                  <div className="flex flex-col">
                    {/* 1 */}
                    <div
                      className={`${
                        about ? "border-b border-white" : "border-b-0"
                      } flex justify-between cursor-pointer transition-all`}
                      onClick={() => setAbout(!about)}
                    >
                      {/* 1.1 */}
                      <p className="text-white text-xl font-medium">
                        Sobre Nós
                      </p>
                      {/*1.2 */}
                      {about ? (
                        <RemoveIcon className=" text-white" />
                      ) : (
                        <AddIcon className=" text-white" />
                      )}
                    </div>
                    {/* 2 */}
                    <div
                      className={`${
                        about ? "h-full mt-5" : "h-0"
                      }  flex flex-col overflow-hidden transition-all gap-5 pl-3`}
                    >
                      {/* 2.1 */}
                      <Link
                        onClick={() => setOpen(false)}
                        to="/about"
                        className=" text-white text-md font-medium"
                      >
                        A MPE
                      </Link>
                      {/* 2.2 */}
                      <Link
                        onClick={() => setOpen(false)}
                        to="/aequipa"
                        className=" text-white text-md font-medium"
                      >
                        A Equipa
                      </Link>
                    </div>
                  </div>

                  {/* Title Parques Empresariais */}
                  <div className="flex flex-col">
                    {/* 1 */}
                    <div
                      className={`${
                        companyParks ? "border-b border-white" : "border-b-0"
                      } flex justify-between cursor-pointer transition-all`}
                      onClick={() => setCompanyParks(!companyParks)}
                    >
                      {/* 1.1 */}
                      <p className="text-white text-xl font-medium">
                        Parques Empresariais
                      </p>
                      {/*1.2 */}
                      {companyParks ? (
                        <RemoveIcon className=" text-white" />
                      ) : (
                        <AddIcon className=" text-white" />
                      )}
                    </div>
                    {/* 2 */}
                    <div
                      className={`${
                        companyParks ? "h-full mt-5" : "h-0"
                      }  flex flex-col overflow-hidden transition-all gap-5 pl-3`}
                    >
                      {/* 2.1 */}
                      <Link
                        onClick={() => setOpen(false)}
                        to="/parks"
                        className=" text-white text-md font-medium"
                      >
                        Os Nossos Parques
                      </Link>
                      {/* 2.2 */}
                      <Link
                        onClick={() => setOpen(false)}
                        to="/inscricao"
                        className=" text-white text-md font-medium"
                      >
                        Inscrição
                      </Link>
                    </div>
                  </div>
                  <Link
                    onClick={() => setOpen(false)}
                    to="/sustenbility"
                    className=" text-white text-xl font-medium"
                  >
                    Sustentabilidade
                  </Link>

                  {/* Title Comunicação */}
                  <div className="flex flex-col">
                    {/* 1 */}
                    <div
                      className={`${
                        communication ? "border-b border-white" : "border-b-0"
                      } flex justify-between cursor-pointer transition-all`}
                      onClick={() => setCommunication(!communication)}
                    >
                      {/* 1.1 */}
                      <p className="text-white text-xl font-medium">
                        Comunicação
                      </p>
                      {/*1.2 */}
                      {communication ? (
                        <RemoveIcon className=" text-white" />
                      ) : (
                        <AddIcon className=" text-white" />
                      )}
                    </div>
                    {/* 2 */}
                    <div
                      className={`${
                        communication ? "h-full mt-5" : "h-0"
                      }  flex flex-col overflow-hidden transition-all gap-5 pl-3`}
                    >
                      {/* 2.1 */}
                      <Link
                        onClick={() => setOpen(false)}
                        to="/production/announcements"
                        className=" text-white text-md font-medium"
                      >
                        Anúncios
                      </Link>
                      {/* 2.2 */}
                      <Link
                        onClick={() => setOpen(false)}
                        to="/events"
                        className=" text-white text-md font-medium"
                      >
                        Eventos
                      </Link>
                      {/* 2.2 */}
                      <Link
                        onClick={() => setOpen(false)}
                        to="/communications"
                        className=" text-white text-md font-medium"
                      >
                        Notícias
                      </Link>
                      {/* 2.2 */}
                      <Link
                        onClick={() => setOpen(false)}
                        to="/forms"
                        className=" text-white text-md font-medium"
                      >
                        Questionários
                      </Link>
                    </div>
                  </div>

                  {/* Title Apoio ao Investimento */}
                  <Link
                    onClick={() => setOpen(false)}
                    to="/support"
                    className=" text-white text-xl font-medium"
                  >
                    Apoio ao Investimento
                  </Link>

                  {/* Title Documentos */}
                  <div className="flex flex-col">
                    {/* 1 */}
                    <div
                      className={`${
                        documents ? "border-b border-white" : "border-b-0"
                      } flex justify-between cursor-pointer transition-all`}
                      onClick={() => setDocuments(!documents)}
                    >
                      {/* 1.1 */}
                      <p className="text-white text-xl font-medium">
                        Documentos
                      </p>
                      {/*1.2 */}
                      {documents ? (
                        <RemoveIcon className=" text-white" />
                      ) : (
                        <AddIcon className=" text-white" />
                      )}
                    </div>
                    {/* 2 */}
                    <div
                      className={`${
                        documents ? "h-full mt-5" : "h-0"
                      }  flex flex-col overflow-hidden transition-all gap-5 pl-3`}
                    >
                      {/* 2.1 */}
                      <Link
                        onClick={() => setOpen(false)}
                        to="/Institucional"
                        className=" text-white text-md font-medium"
                      >
                        Institucional
                      </Link>
                      {/* 2.2 */}
                      <Link
                        onClick={() => setOpen(false)}
                        to="/documents"
                        className=" text-white text-md font-medium"
                      >
                        Legislação
                      </Link>
                    </div>
                  </div>

                  {/* Title Contactos */}
                  <div className="flex flex-col">
                    {/* 1 */}
                    <div
                      className={`${
                        contacts ? "border-b border-white" : "border-b-0"
                      } flex justify-between cursor-pointer transition-all`}
                      onClick={() => setContacts(!contacts)}
                    >
                      {/* 1.1 */}
                      <p className="text-white text-xl font-medium">
                        Contactos
                      </p>
                      {/*1.2 */}
                      {contacts ? (
                        <RemoveIcon className="text-white" />
                      ) : (
                        <AddIcon className=" text-white" />
                      )}
                    </div>
                    {/* 2 */}
                    <div
                      className={`${
                        contacts ? "h-full mt-5" : "h-0"
                        // }  flex flex-col overflow-hidden transition-all gap-5 pl-3`}
                      } flex flex-col overflow-hidden transition-all gap-5`}
                    >
                      {/* 2.1 */}
                      <Link
                        onClick={() => setOpen(false)}
                        to="/contacts"
                        className=" text-white text-md font-medium"
                      >
                        Contactar
                      </Link>
                      {/* 2.2 */}
                      <Link
                        onClick={() => setOpen(false)}
                        to="/agendar"
                        className=" text-white text-md font-medium"
                      >
                        Agendar Reunião
                      </Link>
                      {/* 2.2 */}
                      <Link
                        onClick={() => setOpen(false)}
                        to="/mpe-ativa"
                        className=" text-white text-md font-medium"
                      >
                        MPE Ativa
                      </Link>
                    </div>
                  </div>

                  {/* icons */}
                  <div className="flex gap-3 justify-center items-center text-white">
                    {/* <a
                      href={"https://www.facebook.com/gesba.lda"}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FacebookIcon />
                    </a> */}
                    {/* <a
                      href={"https://www.instagram.com/gesba_lda/"}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <InstagramIcon />
                    </a> */}
                  </div>
                  <div className="flex justify-center mt-10">
                    <Logo
                      onClick={() => {
                        // setOpen(!open);
                        navigate("/");
                      }}
                      className="cursor-pointer h-[7rem]"
                    />
                  </div>
                </div>
              </NavBarWrapper>
            </div>
          </div>
        </NavBarWrapper>
      )}
    </NavigationBar>
  );
}

export default NavBar;
