import emailjs from "@emailjs/browser";
import React, { useRef } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Map from "../../resources/components/contacts/Map";
import Wrapper from "../../resources/components/general/Wrapper";
import "react-toastify/dist/ReactToastify.css";
import FooterHero from "../../resources/images/footer_banner_trees.svg";
import LocVerde from "../../resources/images/contacts/loc_verde.png";
import MpeLogo from "../../resources/images/contacts/mpe_logo.png";
import CallIcon from '@mui/icons-material/Call';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
//import NewsHeroBG from "../../resources/images/aequipa/aequipa.png";
import ContactUs from '../../resources/images/about/contact_us.png'

import {
  Wrapped,
  NewsHero,
} from "./styles/contactos_styles";

function Contacts() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const form = useRef();

  const onSubmit = (data) => {
    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE,
        "template_cptmkqd",
        {
          from_name: data.name,
          from_email: data.email,
          report_subject: data.title,
          report_description: data.description,
        },
        process.env.REACT_APP_EMAILJS_KEY
      )
      .then(
        () => {
          onSubmit1(data);
        },
        () => {
          toast("Erro ao enviar denúncia");
        }
      );
  };
  const onSubmit1 = (data) => {
    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE,
        "template_ur8wxli",
        {
          from_name: data.name,
          from_email: data.email,
          report_subject: data.title,
          report_description: data.description,
        },
        process.env.REACT_APP_EMAILJS_KEY
      )
      .then(
        () => {
          toast("Denúncia enviada com sucesso");
          form.current.reset();
        },
        () => {
          toast("Erro ao enviar denúncia");
        }
      );
  };

  return (
    <>
      <NewsHero className="min-h-[80vh]">
        <div
          className={`absolute top-0 left-0 h-full w-full -z-10 after:z-[-1] after:block after:absolute after:top-0 after:left-0 after:bg-gradient-to-b after:from-[#1A211FE5] after:via-[#1A211F80] after:to-[transparent] after:w-full after:h-full`}
        >
          <img
            src={
              ContactUs
            }
            alt="main card of highlights"
            className="absolute top-10 left-0 -z-[3] w-full h-full object-cover mt-10 "
            style={{ clipPath: "inset(0px 0px 30% 0px)" }}

          />
        </div>
        <Wrapper closeFull>
          <div className="flex h-full">
            <div className="flex flex-col gap-5 pt-[37vh] sm:pt-[35vh] text-white basis-full">
              <h2 className="text-white text-3xl uppercase font-bold mt-10 text-center">
                Fale Connosco
              </h2>
              <p className="text-sm sm:text-md md:text-lg lg:text-xl text-white text-center">
                Estamos aqui para responder às suas dúvidas! Entre em contacto connosco pelos <br />
                nossos canais ou através do formulário.
              </p>
            </div>
          </div>
        </Wrapper>
      </NewsHero>


      <Wrapped className=" relative -top-[8vh] 3xl:-top-[10vh] 2xl:-top-[18vh] lg:-top-[16vh] z-10">
        <div className="flex lg:flex-row md:flex-col sm:flex-col flex-col gap-5 w-full">
          <div className="bg-[#2F5E42] text-white py-10 px-16 lg:w-1/2 md:w-full sm:w-full w-full justify-center text-center">
            <p className="text-2xl pb-3">OS NOSSOS CONTACTOS</p>
            <div className="flex flex-col md:px-10 w-full mt-5 lg:mt-5 md:mt-4 sm:mt-2 items-center">
              <img src={MpeLogo} alt={MpeLogo} className="w-40 h-auto py-10" />
              <div className="flex flex-col sm:flex-row gap-8 sm:gap-16 pt-4 items-center">
                <div className="flex flex-col justify-center items-center text-center gap-5 whitespace-nowrap">
                  <CallIcon htmlColor="#f2f5f5" fontSize="large" />
                  <p>+351 291 214 320</p>
                </div>
                <div className="flex flex-col justify-center items-center text-center gap-5">
                  <MailOutlineIcon htmlColor="#f2f5f5" fontSize="large" />
                  <p>mpe@mpe.pt</p>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-[#2F5E42] text-white p-10 lg:w-1/2 md:w-full sm:w-full w-full justify-center text-center">
            <p className="text-2xl pb-3">A SUA OPINIÃO É IMPORTANTE</p>
            <div className="flex flex-col md:px-10 w-full mt-5 lg:mt-5 md:mt-4 sm:mt-2">
              <form
                ref={form}
                className="flex flex-col gap-5"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="flex flex-col gap-2">
                  <input
                    placeholder="Nome"
                    className="h-14 py-2 px-5 text-black rounded-[4px] focus-visible:drop-shadow-lg focus-visible:outline-none border-[1px] focus-visible:border-[#07146380]"
                    {...register("name", { required: "Nome obrigatório" })}
                  />
                  {errors.name && (
                    <span className="text-[#ED1D24]">
                      {errors.name.message}
                    </span>
                  )}
                </div>
                <div className="flex flex-col gap-2">
                  <input
                    placeholder="Email"
                    className="h-14 py-2 px-5 text-black rounded-[4px] focus-visible:drop-shadow-lg focus-visible:outline-none border-[1px] focus-visible:border-[#07146380]"
                    {...register("email", {
                      required: "Email obrigatório",
                      pattern: {
                        // eslint-disable-next-line
                        value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                        message: "Email inválido",
                      },
                    })}
                  />
                  {errors.email && (
                    <span className="text-[#ED1D24]">
                      {errors.email.message}
                    </span>
                  )}
                </div>
                <div className="flex flex-col gap-2">
                  <input
                    placeholder="Assunto"
                    className="h-14 py-2 px-5 text-black rounded-[4px] focus-visible:drop-shadow-lg focus-visible:outline-none border-[1px] focus-visible:border-[#07146380]"
                    {...register("title", {
                      required: "Título é obrigatório",
                    })}
                  />
                  {errors.title && (
                    <span className="text-[#ED1D24]">
                      {errors.title.message}
                    </span>
                  )}
                </div>
                <div className="flex flex-col gap-2">
                  <textarea
                    rows={4}
                    placeholder="Mensagem..."
                    className="resize-none py-2 px-5 text-black rounded-[4px] focus-visible:drop-shadow-lg focus-visible:outline-none border-[1px] focus-visible:border-[#07146380]"
                    {...register("description", {
                      required: "Obrigatório",
                    })}
                  />
                  {errors.description && (
                    <span className="text-[#ED1D24]">
                      {errors.description.message}
                    </span>
                  )}
                </div>
                <div className="flex items-center justify-center">
                  <button
                    type="submit"
                    className="flex bg-[#121E1A] text-white justify-center py-3 rounded w-[50%]"
                  >
                    Enviar Mensagem
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

      </Wrapped>

      <Wrapped >
        <div className="flex flex-col basis-full md:basis-2/5 gap-5 items-center lg:items-start px-5 pb-5 rounded">
          <h2 className="text-black text-2xl lg:text-2xl font-medium">
            Onde estamos
          </h2>
          <div className="flex flex-col w-full text-black lg:flex-row md:flex-col sm:flex-col  h-[450px]">
            <div className="lg:w-[70%] md:w-full sm:w-full w-full">
              <Map />
            </div>
            <div className="flex flex-col justify-center items-center h-full lg:w-[30%] md:w-full sm:w-full w-full">
              <img
                src={LocVerde}
                alt={LocVerde}
                className="w-10 h-auto lg:w-12 md:w-8 sm:w-4"
              />
              <br />
              <p className="hover:pointer text-base text-center md:text-xl lg:text-xl">
                <a
                  href={"https://maps.app.goo.gl/ofULT9BU8eFAYeVH7"}
                  target="_blank"
                  rel="noreferrer"
                >
                  Rua do Bispo, 16 - 2.º andar <br />
                  Sala 24 | 9000-073 Funchal
                </a>
              </p>
            </div>
          </div>
        </div>
      </Wrapped>

      <div className="relative w-full mt-20 md:mt-32 ">
        <img src={FooterHero} alt="Footer" className="w-full h-auto" />
        <div className="absolute inset-0 bg-gradient-to-b from-transparent from-80% via-transparent to-[#121E1A] h-1/2 top-1/2"></div>
      </div>

    </>
  );
}

export default Contacts;
