import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import moment from "moment";
//import axios from "axios";
import "moment/locale/pt";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import BackButton from "../../resources/components/general/BackButton";
import EventCard from "../../resources/components/general/EventCard";
import Wrapper from "../../resources/components/general/Wrapper";
import FooterHero from "../../resources/images/footer_banner_trees.svg";
import { TextureBackground } from "./styles/news_style";
import axiosConfig from "../../providers/axiosConfig";


function EventDetails() {
  const { state } = useLocation();
  const item = state?.item;


  const [events, setEvents] = useState();
  const [tempEvents, setTempEvents] = useState();



  useEffect(() => {
    axiosConfig.get(`/events`).then((res) => {
      setEvents(res.data);
    });

  }, []);

  useEffect(() => {
    if (events) {
      let aux = events
        .filter((event) => event.id !== item.id)
        .sort(() => Math.random() - 0.5)
        .slice(0, 4);

      setTempEvents(aux);
    }
  }, [events, item]);

  return (
    <>
      <div className="min-h-[70vh] w-full relative overflow-hidden z-[1]">
        <img
          src={item?.photo_url}
          alt=""
          className="absolute object-cover w-full h-full"
          
        />
        <div className="bg-gradient-to-t from-[transparent] to-[transparent] z-[0] absolute w-full h-full" />
        <Wrapper closeFull>
          <div className="flex flex-col h-full justify-between mt-[100px]">
            <BackButton className="text-black top-40" />
            <div className="flex flex-col gap-3 absolute bottom-40 text-black">
              <h2 className="font-semibold uppercase text-3xl md:text-3xl lg:text-4xl w-10/16">
                {item?.name}
              </h2>
            </div>
          </div>
        </Wrapper>
      </div>
      <div className="bg-[#1C6C5E] text-white py-5 relative z-[2]">
    <Wrapper closeFull>
          <div className="flex flex-col w-full">
            <div className="flex flex-col md:flex-row items-start mt-10">
              {/* Data e Hora */}
              {item?.["event-dates"]?.length > 0 && (
                <div className="flex flex-col md:border-r border-white md:px-5">
                  <div className="flex gap-5 items-center">
                    <span className="bg-[#C5DBA4] rounded w-10 h-10 flex items-center justify-center">
                      <CalendarTodayOutlinedIcon htmlColor="#1C6C5E" />
                    </span>
                    <h3 className="uppercase text-2xl font-semibold">Data</h3>
                  </div>
                  <div className="ml-[60px] mt-5">
                    {item["event-dates"].map((eventDate, index) => {
                      let date_time = moment(eventDate.date_time);
                      date_time.locale("pt");
                      let date = date_time.format("dddd, DD [de] MMMM YYYY");
                      return (
                        <div key={index}>
                          <span className="text-white uppercase font-prompt text-base">
                            {date}
                          </span>
                        </div>
                      );
                    })}
                  </div>

                  {/* Hora do evento */}
                  <div className="flex flex-col md:flex-row items-start mt-5">
                    <div className="flex gap-5 items-center">
                      <span className="bg-[#C5DBA4] rounded w-10 h-10 flex items-center justify-center">
                        <AccessTimeIcon htmlColor="#1C6C5E" />
                      </span>
                      <h3 className="uppercase text-2xl font-semibold">Hora</h3>
                    </div>
                  </div>
                  <p className="text-white font-prompt text-base ml-[60px]">
                    {item?.start_time}
                  </p>
                </div>
              )}

              {/* Localização */}
              <div className={`flex flex-col mt-5 md:mt-0 ${item?.["event-dates"]?.length > 0 ? "md:pl-40" : ""}`}>
                <div className="flex gap-5 items-center">
                  <span className="bg-[#C5DBA4] rounded w-10 h-10 flex items-center justify-center">
                    <LocationOnOutlinedIcon htmlColor="#1C6C5E" />
                  </span>
                  <h3 className="uppercase text-2xl font-semibold">Localização</h3>
                </div>
              </div>
            </div>

            {/* Anexo */}
            {item?.annex_url && item?.annex_name && !item?.annex_url.includes("api.gesba.pt") && (
              <a
                href={item.annex_url}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-3 self-end"
              >
                <FileCopyIcon /> {item.annex_name}
              </a>
            )}
          </div>
        </Wrapper>
      </div>
      <TextureBackground className="relative z-[1]">
        <Wrapper closeFull className={"relative z-[1]"}>
          <div className="flex flex-col lg:flex-row gap-10 py-20">
            <div className="basis-3/4">
              <h3 className="text-3xl font-semibold uppercase mb-5">
                Sobre este evento
              </h3>

              <p className="text-lg text-[#707070] text-justify whitespace-pre-line">
                {item?.annex_about ??
                  "Sem dados relativos a este evento de momento"}
              </p>
            </div>
            {/* Limite de 4 eventos */}
            <div className="flex flex-row md:flex-col basis-3/6 md:max-h-full flex-wrap md:flex-nowrap gap-6">
              <h2 className="uppercase font-semibold mb-5">
                Próximos Eventos
              </h2>
              {tempEvents &&
                tempEvents.map((item, index) => {
                  return (
                    <EventCard
                      secondary
                      data={item}
                      events={events}
                      related
                      key={index}
                    />
                  );
                })}
            </div>
          </div>
        </Wrapper>
      </TextureBackground>


      <div className="relative w-full mt-20 md:mt-32 lg:mt-58">
        <img src={FooterHero} alt="Footer" className="w-full h-auto" />
        <div className="absolute inset-0 bg-gradient-to-b from-transparent from-80% via-transparent to-[#121E1A] h-1/2 top-1/2"></div>
      </div>
    </>
  );
}

export default EventDetails;
