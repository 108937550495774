
import React from "react";
import NewsImg from "../../images/news/news_img.png";
import "moment/locale/pt";

function NewsCard({ grid, data }) {
  const stringLimit = 100;
  const truncated = isJsonString(data?.body)
    ? JSON.parse(data?.body).slice(0, stringLimit) + "..."
    : data?.body.slice(0, stringLimit) + "...";


  function isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  return (
    <div
      className={`${grid
        ? " md:w-[calc(100%_/_3_-_20px)] sm:basis-auto sm:w-[calc(100%_/_2_-_20px)]"
        : "md:basis-1/2"
        } bg-white box-border z-[1] p-3 basis-full rounded flex flex-col w-full items-start gap-2 h-full`}
    >
      <div className="flex flex-col  flex-grow max-w-full">
        <div className="w-full min-h-[200px] h-[200px] overflow-hidden relative z-[0] after:block after:z-[-1] after:absolute after:bg-gradient-to-t after:from-[#000] after:to-[transparent] after:w-full after:h-1/2 after:bottom-0 after:left-0">
          <img
            src={data?.photo_url ?? NewsImg}
            alt=""
            className="w-full h-[200px] object-contain relative z-[-1]"
          />
          <h3 className="text-white text-lg absolute w-full bottom-4 left-0 px-4 break-words">
            {data?.title}
          </h3>
        </div>
        <div className="flex flex-col gap-1 mt-4 justify-start">
          <p className="text-[#172327] break-words text-base overflow-hidden text-ellipsis line-clamp-3">
            {new Date(data?.published_date).toLocaleDateString('en-GB')}
          </p>
          <p className="text-[#172327] break-words overflow-hidden text-ellipsis line-clamp-3">
            {truncated}
          </p>
        </div>
      </div>
    </div>
  );
}
export default NewsCard;
