import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation, Link, useParams } from "react-router-dom";
import Wrapper from "../../resources/components/general/Wrapper";
import NewsHero from "../../resources/images/news/news_hero.png";
import { TextureBackground } from "./styles/news_style";
import BackButton from "../../resources/components/general/BackButton";
import "moment/locale/pt";
import axiosConfig from "../../providers/axiosConfig";
import HeroBanner from "../../resources/components/general/HeroBanner";
import FooterHero from "../../resources/images/footer_banner_trees.svg";
import NewsCard from "../../resources/components/general/NewsCard";

function NewsDetails() {
  const [randomItems, setRandomItems] = useState();
  const { id } = useParams();
  const { state } = useLocation();
  const [item, setItem] = useState(state?.item || null);
  const [allNews, setAllNews] = useState(state?.news || []);

  useEffect(() => {
    if (!item) {
      axiosConfig.get(`/news/${id}`).then((res) => {
        setItem(res.data);
      }).catch((err) => {
        console.error("Erro ao buscar notícia:", err);
      });
    }
  }, [id, item]);

  useEffect(() => {
    axiosConfig.get(`/news`).then((res) => {
      console.log("TODAS AS NOTÍCIAS:", res.data);
      setAllNews(res.data);
    });
  }, []);

  useEffect(() => {
    if (item && allNews && allNews.length > 0) {
      const filteredNews = allNews.filter(n => n.id !== item.id);
      const sorted = filteredNews.sort(
        (a, b) =>
          new Date(b.published_date || b.created_at) -
          new Date(a.published_date || a.created_at)
      );
      const selected = sorted.slice(0, 3);
  
      setRandomItems(selected);
    }
  }, [allNews, item]);
  
  console.log("ITEM:", item);
  console.log("ÚLTIMAS NOTÍCIAS (mais recentes):", randomItems);
  
  if (!item) return <p className="text-white">Carregando notícia...</p>;
  


  function getParsedDate(dateString) {
    const date = moment(dateString);
    date.locale("pt");
    const formattedDate = date.format("DD MMM YYYY");
    return formattedDate;
  }


  function isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }



  return (
    <>
      <HeroBanner title="" />

      <Wrapper closeFull className="relative">
        <div className="absolute top-[-200px] left-5 z-50">
          <BackButton className="text-white" />
        </div>
        <div className="flex flex-col h-full justify-between mt-[-50px] mb-10">

        </div>
      </Wrapper>



      <TextureBackground className="relative z-[1] pb-10">
        <Wrapper closeFull>
          <div className="flex flex-col gap-10 z-30 py-0 md:py-10">
            <h3 className="uppercase font-medium text-black mt-10">
              {item?.news_category}
            </h3>
            <h2 className="font-semibold uppercase text-3xl w-10/12 text-black">
              {item?.title}
            </h2>

            {item?.photo_url ? (
              <img
                src={item?.photo_url}
                alt={item?.title}
                className="w-full max-h-[500px] aspect-video object-contain mb-8"
              />
            ) : (
              <img
                src={NewsHero}
                alt="Imagem padrão"
                className="w-full h-full object-cover"
              />
            )}
          </div>

          <div className="flex flex-col gap-10">
            <div className="basis-full">
              <div className="border-b border-b-[#121111] mb-5">
                <p>{item?.author}</p>
                <p className="mb-3">
                  {item?.published_date
                    ? getParsedDate(item?.published_date)
                    : getParsedDate(item?.created_at)}
                </p>
              </div>
              <p className="text-xl font-medium mb-10 text-justify">
                {isJsonString(item?.lead)
                  ? JSON.parse(item?.lead)
                    .split(/[\r\n]+/)
                    .map((paragraph, index) => (
                      <p key={index} className="mb-4">
                        {paragraph}
                      </p>
                    ))
                  : item?.lead}
              </p>

              <p className="text-lg text-[#707070] text-justify whitespace-pre-line">
                {isJsonString(item?.body)
                  ? JSON.parse(item?.body)
                    .split(/[\r\n]+/)
                    .map((paragraph, index) => (
                      <p key={index} className="mb-4">
                        {paragraph}
                      </p>
                    ))
                  : item?.body}
              </p>
            </div>


            {randomItems && randomItems.length > 0 && (
              <>
                <hr className="border-t-2 border-black my-3 w-full" />
                <h2 className="uppercase font-semibold mb-10 w-full">
                  Últimas Notícias
                </h2>
                <div className="flex flex-wrap gap-10 justify-around mt-10">
                  {randomItems.map((newsItem, index) => (
                    <div key={index} className="w-full sm:w-1/2 lg:w-1/3 xl:w-1/4">
                      <Link
                        to={`/news/show/${newsItem.id}`}
                        state={{ item: newsItem, news: allNews }}
                        className="cursor-pointer"
                      >
                        <NewsCard data={newsItem} />
                      </Link>
                    </div>
                  ))}
                </div>
              </>
            )}


          </div>
        </Wrapper>
      </TextureBackground>

      <div className="relative w-full mt-20 md:mt-32 lg:mt-58">
        <img src={FooterHero} alt="Footer" className="w-full h-auto" />
        <div className="absolute inset-0 bg-gradient-to-b from-transparent from-80% via-transparent to-[#121E1A] h-1/2 top-1/2"></div>
      </div>
    </>
  );
}

export default NewsDetails;
