import React, { useState } from "react";
import Wrapper from "../../resources/components/general/Wrapper";
import FooterHero from "../../resources/images/footer_banner_trees.svg";
import { TextureBackground } from "./styles/sustenbility_style";
import SustainabilityImage from "../../resources/images/news/sustentabilidade.png";
import EsgImage from "../../resources/images/news/sustentibility2.png";
import AmbientalImage from "../../resources/images/sustenbility/ambiental.png";
import GovernancaImage from "../../resources/images/sustenbility/govern.png";
import SocialImage from "../../resources/images/sustenbility/social.png";
import Social1 from "../../resources/images/news/social1.png";
import Icon1 from "../../resources/images/sustenbility/iconAmbiental.png";
import Icon2 from "../../resources/images/sustenbility/iconGovernaça.png";
import Icon3 from "../../resources/images/sustenbility/iconSocial.png";
import graphic from "../../resources/images/sustenbility/painel.png";
import car from "../../resources/images/sustenbility/carr.png";
import recovery from "../../resources/images/sustenbility/recovery.png";
import Social2 from "../../resources/images/news/social2.png";
import { RotatingCircle } from "./styles/sustenbility_style";
import HeroBanner from "../../resources/components/general/HeroBanner";
import { Popover, Typography } from "@mui/material";


function ListEvents() {
  const [selected, setSelected] = useState("Ambiental");
  const [selectedOption, setSelectedOption] = useState("Ambiental");
  const [rotateAngle, setRotateAngle] = useState(0);
  const [isFading, setIsFading] = useState(false);

  const [anchorElGovernanca, setAnchorElGovernanca] = useState(null);
  const [anchorElSocial, setAnchorElSocial] = useState(null);
  const [anchorElAmbiental, setAnchorElAmbiental] = useState(null);

  const handlePopoverOpen = (setAnchor) => (event) => {
    setAnchor(event.currentTarget);
  };

  const handlePopoverClose = (setAnchor) => () => {
    setAnchor(null);
  };

  const openGovernanca = Boolean(anchorElGovernanca);
  const openSocial = Boolean(anchorElSocial);
  const openAmbiental = Boolean(anchorElAmbiental);

  const rotationAngles = {
    Ambiental: 0,
    Social: -120,
    Governança: 120,
  };

  const handleSelection = (option) => {
    if (option === selectedOption) return;

    setIsFading(true);

    setTimeout(() => {
      setSelected(option);
      setSelectedOption(option);
      setRotateAngle(rotationAngles[option]);
      setIsFading(false);
    }, 500);
  };
  const content = {
    Ambiental: (
      <>
        <p>
          A Madeira Parques Empresariais S.A tem promovido um conjunto de
          medidas na área ambiental, em parceria com algumas empresas privadas,
          que visa alcançarmos um compromisso comum, no sentido de apostarmos
          numa diminuição na emissão de gases poluentes intensificando o efeito
          estufa, levando ao aquecimento global do nosso planeta.
        </p>


        <div className="relative w-full mt-16">

          <div className="p-6 border rounded-lg bg-gray-50 flex flex-col md:flex-row items-center md:items-start gap-10">


            <div className="w-full md:w-[60%]">
              <h3 className="font-bold text-2xl mb-4">
                Pontos de Carregamento Elétrico
              </h3>

              <p>
                Colocação de pontos de carregamento elétrico em alguns parques empresariais para permitir
                que os utentes tenham a possibilidade de carregar os seus veículos em pontos identificados
                nos parques empresariais.
              </p>

              <h4 className="mt-6 mb-1 font-semibold"><strong>Vantagens</strong></h4>
              <ul className="list-disc ml-5">
                <li>
                  Gerar poupanças às empresas, ao permitir a redução dos custos de investimentos
                  associados à instalação desse tipo de equipamentos nas próprias empresas.
                </li>
                <li>
                  Contribuir para uma redução de emissão de CO2 nos parques empresariais geridos pela MPE.
                </li>
              </ul>

              <p className="mt-6">
                <strong>Localização do ponto de carregamento elétrico:</strong>{" "}
                Parque empresarial da Cancela
              </p>

              <p className="mt-4">
                Nº de postos de carregamento: 2
              </p>
            </div>


            <div className="w-full md:w-[40%] flex justify-center md:justify-end relative">
              <img
                src={car}
                alt="Ponto de Carregamento"
                className="rounded-lg w-[80%] max-w-[300px] md:w-[120%] md:max-w-[250px] object-contain scale-100 md:scale-125 md:mt-24 md:mx-auto"
              />
            </div>

          </div>


          <div className="relative w-full mt-16">

            <div className="p-6 border rounded-lg bg-gray-50 flex flex-col md:flex-row items-center md:items-start gap-6">


              <div className="flex-1">
                <h3 className="font-bold text-2xl mb-4">
                  Comunidade de Energia Renovável e Autoconsumo Coletivo
                </h3>

                <p>
                  Criação da primeira comunidade de Energia Renovável e de autoconsumo
                  coletivo, com o objetivo de promover um sistema de autoconsumo
                  coletivo para a produção, consumo, e partilha de energia renovável
                  entre as empresas instaladas no parque empresarial da Ribeira Brava.
                </p>

                <h4 className="mt-6 mb-1 font-semibold">Vantagens</h4>
                <ul className="list-disc ml-5">
                  <li>Redução de custos económicos com a conta da energia.</li>
                  <li>Menores investimentos iniciais para as empresas.</li>
                  <li>Descarbonização da matriz energética.</li>
                </ul>

                <p className="mt-6">
                  <strong>Localização da comunidade de energia renovável:</strong>{" "}
                  Parque empresarial da Ribeira Brava
                </p>
                <p className="mt-4">
                  Nº de painéis solares instalados: 80<br />
                  Potência de cada painel: 655 wp<br />
                  Potência instalada: 52,4 Kwp<br />
                  Potência do inversor: 50 Kw<br />
                </p>
              </div>


              <div className="w-full md:w-[45%] flex justify-center md:justify-end mt-[-40px] md:mt-[130px] relative">
                <img
                  src={graphic}
                  alt="Painéis solares"
                  className="rounded-lg w-[95%] sm:w-[90%] max-w-[450px] md:w-[70%] md:max-w-[90%] object-contain mx-auto md:mx-auto md:mt-8]"
                />
              </div>

            </div>
          </div>

        </div>



        <div className="relative w-full mt-16">
          <div className="p-6 border rounded-lg bg-gray-50 flex flex-col md:flex-row items-center md:items-start gap-6">


            <div className="flex-1">
              <h3 className="font-bold text-2xl mb-4">
                Recuperação da Área Florestal do Parque Empresarial de Câmara de Lobos
              </h3>

              <p>
                A MPE, S.A. realizou, no Parque Empresarial de Câmara de Lobos, numa área de 20.7 ha,
                ações de limpeza de vegetação espontânea e ações de arborização como forma de recuperação
                da área florestal com espécies ecologicamente adequadas ao local.
              </p><br></br>


              <p>
                Criação da primeira comunidade de Energia Renovável e de autoconsumo coletivo,
                com o objetivo de promover um sistema de autoconsumo coletivo para a produção, consumo,
                e partilha de energia renovável entre as empresas instaladas no parque empresarial da Ribeira Brava.
              </p>


              <p className="mt-6">

                <strong>
                  <a
                    href="/files/UE-PE-CDL.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-black hover:underline"
                  >
                    Documento do projeto
                  </a>
                </strong>
              </p>


            </div>


            <div className="w-full md:w-[50%] flex justify-center md:justify-end mt-4 md:mt-[80px] relative">
              <img
                src={recovery}
                alt="Recuperação Florestal"
                className="rounded-lg max-w-[90%] md:max-w-[600px] object-contain md:mt-15 md:mx-auto"
              />
            </div>

          </div>
        </div>


      </>
    ),
    Social: (
      <>
        <p>
          A Madeira Parques estabeleceu parcerias com algumas câmaras
          municipais, nomeadamente com a autarquia de Câmara de Lobos, e com o
          Instituto das Florestas e Conservação da Natureza da RAM, no sentido
          de promovermos ações de educação ambiental no parque empresarial de
          Câmara de Lobos, para um “Público – Alvo” direcionado para as
          crianças, crianças essas matriculadas nas escolas do 1.º ciclo do
          Concelho de Câmara de Lobos.
        </p>

        <div className="mt-4 flex justify-center gap-4">
          <img src={Social1} alt="" className="w-1/2 rounded-lg object-cover" />
          <img src={Social2} alt="" className="w-1/2 rounded-lg object-cover" />
        </div>
      </>
    ),
    Governança: (
      <div>
        <p>
          Na Madeira Parques Empresariais, S.A., a governança é um pilar
          essencial da nossa atuação. Priorizamos uma gestão transparente e
          responsável, implementando diretrizes claras para a gestão financeira
          e políticas que valorizam os nossos colaboradores. O Conselho de
          Administração atua em conformidade com os regulamentos internos,
          garantindo boas práticas na organização e no desenvolvimento
          profissional da nossa equipa.
        </p>
        <br></br>
        <p>
          <strong>
            <a
              href="/files/Cdigo_de_tica_Conduta_Igualdade__e_Sustentabilidade_2025.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="text-black hover:underline"
            >
              Código de Conduta e Ética
            </a>
          </strong>
        </p>

      </div>
    ),
  };

  return (
    <>
      <HeroBanner title="Sustentabilidade" />

      <TextureBackground className="relative z-[1]">
        <Wrapper closeFull>
          <div className="flex flex-col md:flex-row items-center justify-between gap-10 py-20 mt-12">
            <div className="flex-1 text-left self-start">
              <h2 className="font-semibold text-[40px] text-gray-800 ">
                Compromisso com a Sustentabilidade
              </h2>
              <p className="mt-6 text-[18px] text-gray-600">
                A Sustentabilidade é um tema prioritário para a Madeira Parques
                Empresariais, pois devemo-nos focar em termos uma gestão
                sustentável na nossa organização, isto é, a estratégia deve ser
                definida com foco no crescimento ao longo prazo e com o objetivo
                de dar resposta a problemas ambientais e sociais. Somos
                defensores da segurança e proteção das comunidades onde os
                parques empresariais estão inseridos.
              </p>
            </div>
            <div className="flex-1">
              <img
                src={SustainabilityImage}
                alt=""
                className="w-full h-auto object-cover rounded-lg"
              />
            </div>
          </div>
        </Wrapper>
      </TextureBackground>

      <TextureBackground className="relative z-[1]">
        <Wrapper closeFull>
          <div className="flex flex-col md:flex-row-reverse items-center justify-between gap-10 py-20">
            <div className="flex-1 text-left self-start">
              <h2 className="font-semibold text-[42px] text-gray-800">
                O que é ESG?
              </h2>
              <ul className="mt-3 text-[18px] text-gray-600 list-disc pl-5 space-y-2">
                A sigla ESG significa{" "}
                <strong>“Environmental, Social and Governance”</strong>, e
                refere-se aos critérios que uma empresa deve respeitar para
                assegurar a sustentabilidade a longo prazo.
                <li>
                  <strong>“E” de Environmental</strong> representa o critério
                  ambiental, onde estão representadas as políticas e medidas da
                  empresa para preservar o planeta.
                </li>
                <li>
                  <strong>“S” de Social</strong> representa a relação da empresa
                  com os diversos stakeholders, desde colaboradores, a
                  fornecedores, a clientes e à comunidade em geral.
                </li>
                <li>
                  <strong>“G” de Governance</strong> representa medidas também
                  diversas, como por exemplo a garantia e a transparência
                  financeira da Madeira Parques Empresariais, S.A, e a política
                  de remunerações justa e adequada aos colaboradores da empresa,
                  agindo o Conselho de Administração, em conformidade com o
                  Regulamento de Organização, Funcionamento e Carreiras
                  Profissionais da Madeira Parques Empresariais, S.A.
                </li>
              </ul>
            </div>
            <div className="flex-1">
              <img
                src={EsgImage}
                alt=""
                className="w-full h-auto object-cover"
              />
            </div>
          </div>
        </Wrapper>
      </TextureBackground>

      <TextureBackground className="relative z-[1] bg-gray-100 py-20">
        <Wrapper closeFull>
          <div className="flex flex-col items-center justify-center white">
            <h2 className="lg:text-[40px] text-[20px] font-semibold text-gray-800 mb-6 whitespace-nowrap">
              A Nossa Caminhada Sustentável
            </h2>

            <RotatingCircle>
              <svg width="400" height="850" viewBox="70 0 200 280">
                <text
                  x="170"
                  y="110"
                  textAnchor="middle"
                  dy="6"
                  className="center-text"
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    fill: "#20432E",
                  }}
                >
                  ESG
                </text>

                <g
                  style={{
                    transform: `rotate(${rotateAngle}deg)`,
                    transformOrigin: "170px 110px",
                    transition: "transform 0.5s linear",
                  }}
                >
                  <g>
                    <image
                      href={GovernancaImage}
                      x="150"
                      y="50"
                      width="105"
                      height="84"
                      onClick={() => handleSelection("Governança")}
                      className="cursor-pointer hover:opacity-80 transition"
                      style={{
                        filter:
                          selectedOption === "Governança"
                            ? "brightness(0.7)"
                            : "brightness(1)",
                      }}
                    />
                    <image
                      href={Icon2}
                      x="196"
                      y="85"
                      width="42"
                      height="12"
                      onClick={() => handleSelection("Governança")}
                      className="cursor-pointer"
                      style={{
                        transform: `rotate(${-rotateAngle}deg)`,
                        transformOrigin: "217px 90px",
                        transition: "transform 0.5s linear",
                      }}
                      onMouseEnter={handlePopoverOpen(setAnchorElGovernanca)}
                      onMouseLeave={handlePopoverClose(setAnchorElGovernanca)}
                    />
                    <Popover
                      id="governanca"
                      sx={{
                        pointerEvents: "none",
                        "& .MuiPaper-root": {
                          backgroundColor: "black",
                          color: "white",
                        },
                      }}
                      open={openGovernanca}
                      anchorEl={anchorElGovernanca}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      onClose={handlePopoverClose(setAnchorElGovernanca)}
                      disableRestoreFocus
                    >
                      <Typography sx={{ p: 1 }}>Governança</Typography>
                    </Popover>
                  </g>

                  <g>
                    <image
                      href={SocialImage}
                      x="89"
                      y="50"
                      width="95"
                      height="85"
                      onClick={() => handleSelection("Social")}
                      className="cursor-pointer hover:opacity-80 transition"
                      onMouseEnter={handlePopoverOpen(setAnchorElSocial)}
                      onMouseLeave={handlePopoverClose(setAnchorElSocial)}
                      style={{
                        filter:
                          selectedOption === "Social"
                            ? "brightness(0.7)"
                            : "brightness(1)",
                      }}
                    />
                    <image
                      href={Icon3}
                      x="104"
                      y="85"
                      width="43"
                      height="12"
                      onClick={() => handleSelection("Social")}
                      className="cursor-pointer"
                      style={{
                        transform: `rotate(${-rotateAngle}deg)`,
                        transformOrigin: "125px 90px",
                        transition: "transform 0.5s linear",
                      }}
                      onMouseEnter={handlePopoverOpen(setAnchorElSocial)}
                      onMouseLeave={handlePopoverClose(setAnchorElSocial)}
                    />
                    <Popover
                      id="social"
                      sx={{
                        pointerEvents: "none",
                        "& .MuiPaper-root": {
                          backgroundColor: "black",
                          color: "white",
                        },
                      }}
                      open={openSocial}
                      anchorEl={anchorElSocial}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      onClose={handlePopoverClose(setAnchorElSocial)}
                      disableRestoreFocus
                    >
                      <Typography sx={{ p: 1 }}>Social</Typography>
                    </Popover>
                  </g>

                  <g>
                    <image
                      href={AmbientalImage}
                      x="120"
                      y="105"
                      width="100"
                      height="96"
                      onClick={() => handleSelection("Ambiental")}
                      className="cursor-pointer hover:opacity-80 transition"
                      style={{
                        filter:
                          selectedOption === "Ambiental"
                            ? "brightness(0.7)"
                            : "brightness(1)",
                      }}
                    />
                    <image
                      href={Icon1}
                      x="145"
                      y="154"
                      width="50"
                      height="15"
                      onClick={() => handleSelection("Ambiental")}
                      className="cursor-pointer"
                      style={{
                        transform: `rotate(${-rotateAngle}deg)`,
                        transformOrigin: "170px 160px",
                        transition: "transform 0.5s linear",
                      }}
                      onMouseEnter={handlePopoverOpen(setAnchorElAmbiental)}
                      onMouseLeave={handlePopoverClose(setAnchorElAmbiental)}
                    />
                  </g>
                  <Popover
                    id="ambiental"
                    sx={{
                      pointerEvents: "none",
                      "& .MuiPaper-root": {
                        backgroundColor: "black",
                        color: "white",
                      },
                    }}
                    open={openAmbiental}
                    anchorEl={anchorElAmbiental}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    onClose={handlePopoverClose(setAnchorElAmbiental)}
                    disableRestoreFocus
                  >
                    <Typography sx={{ p: 1 }}>Ambiental</Typography>
                  </Popover>
                </g>
              </svg>
            </RotatingCircle>

            <h3 className="text-[32px] font-bold mt-1 text-left w-full">
              {selected}
            </h3>

            <div
              style={{
                opacity: isFading ? 0 : 2,
                transition: "opacity 0.5s ease-in-out",
                transform: isFading ? "translateY(10px)" : "translateY(10px)",
                transitionProperty: "opacity, transform",
                fontSize: "18px",
              }}
              className="mt-2 text-gray-700"
            >
              {content[selectedOption]}
            </div>
          </div>
        </Wrapper>
      </TextureBackground>

      <div className="relative w-full mt-20 md:mt-32 lg:mt-58">
        <img src={FooterHero} alt="Footer" className="w-full h-auto" />
        <div className="absolute inset-0 bg-gradient-to-b from-transparent from-80% via-transparent to-[#121E1A] h-1/2 top-1/2"></div>
      </div>
    </>
  );
}

export default ListEvents;
