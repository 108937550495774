import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import axiosConfig from "../../../providers/axiosConfig";
import AnnouncementCard from "../../../resources/components/general/AnnouncementCard";
import Wrapper from "../../../resources/components/general/Wrapper.js";
import FooterHero from "../../../resources/images/footer_banner_trees.svg";
import emptyImage from "../../../resources/images/announcements/Warehouse.svg";

import styled from "@emotion/styled";

import { AnnounceSection } from "../styles/production_styles";
import { CircularProgress } from "@mui/material";
import HeroBanner from "../../../resources/components/general/HeroBanner.js";

const SectionTitle = styled("div")`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 200;
  font-size: 20px;
  line-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: "#000000";
  text-align: center;
`;

function Announcements() {
  const [announcements, setAnnouncements] = useState([]);
  const [loading, setLoading] = useState(false);

  const { ref: ref1, inView: inView1 } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  useEffect(() => {
    setLoading(true);
    axiosConfig
      .get(`/announcements`)
      .then((res) => {
        setLoading(false);
        setAnnouncements(res.data);
      })
      .catch(() => setLoading(false));
  }, []);
  console.log(announcements);

  return (
    <>
      <HeroBanner title="Anúncios" />
      <AnnounceSection className="mt-20 md:mt-32">
        <Wrapper className="overflow-hidden my-8">
          {announcements.length > 0 && (
            <SectionTitle>Contratação Pública</SectionTitle>
          )}

          {loading ? (
            <div className="flex justify-center w-full">
              <CircularProgress className="mx-auto" sx={{ color: "#1C6C5E" }} />
            </div>
          ) : announcements.length === 0 ? (
            <div className="mt-32 flex flex-col">
              <p className="text-center text-black text-xl font-medium mt-[-100px]">
                Sem anúncios de momento!
                <br />
                Novidades em breve
              </p>
              <img
                src={emptyImage}
                alt="Sem anúncios"
                className="h-[50vh] object-contain mb-4 py-10"
              />
            </div>
          ) : (
            <div
              className="grid grid-cols-1 md:grid-cols-2 my-5 gap-5"
              ref={ref1}
            >
              {announcements.map((item, index) => (
                <div
                  key={index}
                  className={`${
                    inView1
                      ? index % 2 !== 0
                        ? "slide-in-right visible"
                        : "slide-in-left visible"
                      : "invisible"
                  }`}
                >
                  <AnnouncementCard contract data={item} />
                </div>
              ))}
            </div>
          )}
        </Wrapper>
      </AnnounceSection>
      <div className="relative w-full mt-20 md:mt-32">
        <img src={FooterHero} alt="Footer" className="w-full h-auto" />
        <div className="absolute inset-0 bg-gradient-to-b from-transparent from-80% via-transparent to-[#121E1A] h-1/2 top-1/2"></div>
      </div>
    </>
  );
}

export default Announcements;
