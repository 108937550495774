import { CircularProgress, Typography } from "@mui/material";
import "moment/locale/pt";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosConfig from "../../providers/axiosConfig";
import NewsCard from "../../resources/components/general/NewsCard";
import Wrapper from "../../resources/components/general/Wrapper";
import NewsHeroBG from "../../resources/images/news/news_heroo.svg";
import FooterHero from "../../resources/images/footer_banner_trees.svg";
import { NewsHero, TextureBackground } from "./styles/news_style";

function News() {
  const navigate = useNavigate();
  const [mainNews, setMainNews] = useState();
  const [remainNews, setRemainNews] = useState();
  const [allNews, setAllNews] = useState();
  const [loading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;

  useEffect(() => {
    setLoading(true);
    axiosConfig
      .get(`/news`)
      .then((res) => {
        const data = res.data || [];
        setAllNews(data);
        setMainNews(data[0]);
        setRemainNews(data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, []);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentNews = remainNews?.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages =
    remainNews && Array.isArray(remainNews)
      ? Math.ceil(remainNews.length / itemsPerPage)
      : 0;
  const pageNumbers =
    totalPages > 0 ? [...Array(totalPages).keys()].map((i) => i + 1) : [];

  return (
    <>
      <NewsHero className={`min-h-[50vh]`}>
        <div
          className={`absolute top-0 left-0 h-full w-full after:z-[-1] after:block after:absolute after:top-0 after:left-0 after:bg-gradient-to-r  after:to-[transparent] after:w-full after:h-full`}
        >
          <img
            src={NewsHeroBG}
            alt="main card of highlights"
            className="absolute top-0 left-0 z-[-2] w-full h-full object-cover"
          />
        </div>
        <Wrapper closeFull>
          <div className="flex h-full">
            <div className="flex flex-col gap-5 pt-[100px] text-white basis-full">
              <h2 className="text-white text-3xl  font-bold mt-10">Notícias</h2>
            </div>
          </div>
        </Wrapper>
      </NewsHero>

      <TextureBackground className="mt-20 md:mt-32">
        <Wrapper closeFull>
          <div className="flex h-full">
            <div className="flex flex-col gap-5 text-black basis-full">
              <h2 className="text-black text-3xl uppercase font-bold border-b-2 border-black">
                Notícias em destaque
              </h2>
              {loading ? (
                <div className="flex justify-center w-full">
                  <CircularProgress
                    className="mx-auto"
                    sx={{ color: "#1C6C5E" }}
                  />
                </div>
              ) : mainNews ? (
                <div className="flex flex-col lg:flex-row gap-10 items-center">
                  <div className="flex-shrink-0 w-full max-w-[500px] max-h-[400px] overflow-hidden">
                    <div
                      onClick={() => {
                        navigate(`/news/show/${mainNews.id}`, {
                          state: { item: mainNews, news: allNews },
                        });
                      }}
                      className="cursor-pointer"
                    >
                      <img
                        src={mainNews?.photo_url}
                        alt=""
                        className="w-full h-auto object-cover transition-transform duration-300 hover:scale-105"
                      />
                    </div>
                  </div>

                  <div className="flex flex-col justify-between gap-4 w-full lg:w-[50%]">
                    <h3
                      onClick={() => {
                        navigate(`/news/show/${mainNews.id}`, {
                          state: { item: mainNews, news: allNews },
                        });
                      }}
                      className="font-bold uppercase text-2xl cursor-pointer"
                    >
                      {mainNews?.title}
                    </h3>
                    <div className="flex flex-col md:flex-row gap-5 items-start justify-between">
                      <p className="mt-3">
                        <Typography className="break-words whitespace-pre-line overflow-hidden text-ellipsis">
                          {mainNews?.lead}
                        </Typography>
                      </p>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </Wrapper>

        {remainNews && remainNews.length > 0 && (
          <Wrapper closeFull className={"relative z-[1] mt-16 "}>
            <div>
              <hr className="border-t-2 border-black my-3" />
              <p className="text-lg uppercase font-semibold whitespace-nowrap flex my-3">
                Todas as Notícias
              </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5">
              {currentNews &&
                currentNews.map((item, index) => {
                  let filtered = allNews.filter(
                    (info) => info.news_category === item.news_category
                  );
                  let randomItems = filtered
                    .sort(() => 0.1 - Math.random())
                    .slice(0, 3);

                  return (
                    <div
                      onClick={() => {
                        navigate(`/news/show/${item.id}`, {
                          state: {
                            item: item,
                            related: randomItems,
                            news: allNews,
                          },
                        });
                      }}
                      className="cursor-pointer"
                      key={index}
                    >
                      <NewsCard data={item} />
                    </div>
                  );
                })}
            </div>
          </Wrapper>
        )}

        {totalPages > 1 && (
          <div className="flex justify-center mt-5 mb-14">
            {currentPage > 1 && (
              <button
                onClick={() =>
                  setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
                }
                className="py-2 px-4 rounded mx-1 bg-opacity-0 bg-white text-black mb-3 text-lg"
                disabled={currentPage === 1}
              >
                &#60;
              </button>
            )}

            {pageNumbers.length > 0 &&
              pageNumbers
                .filter(
                  (number) =>
                    number >= currentPage - 1 && number <= currentPage + 1
                )
                .map((number) => (
                  <button
                    key={number}
                    onClick={() => setCurrentPage(number)}
                    className={`py-2 px-4 rounded mx-1 ${currentPage === number
                        ? "bg-[#1C6C5E] text-white"
                        : "bg-opacity-0 bg-white text-black "
                      } mb-3 text-lg`}
                  >
                    {number}
                  </button>
                ))}

            {currentPage < totalPages && (
              <button
                onClick={() =>
                  setCurrentPage((prevPage) =>
                    Math.min(prevPage + 1, totalPages)
                  )
                }
                className="py-2 px-4 rounded mx-1 bg-opacity-0 bg-white text-black mb-3 text-lg"
                disabled={currentPage === totalPages}
              >
                &#62;
              </button>
            )}
          </div>
        )}
      </TextureBackground>

      <div className="relative w-full mt-20 md:mt-32">
        <img src={FooterHero} alt="Footer" className="w-full h-auto" />
        <div className="absolute inset-0 bg-gradient-to-b from-transparent from-80% via-transparent to-[#121E1A] h-1/2 top-1/2"></div>
      </div>
    </>
  );
}

export default News;
