import React from "react";
import { ReactComponent as Dev } from "../../logo/Logo_Vipa.svg";
import { ReactComponent as LogoMpe } from "../../logo/mpe.svg";
import { ReactComponent as LogoAppe } from "../../../resources/logo/appe.svg";
import { ReactComponent as LogoSrde } from "../../../resources/logo/secretaria_regional_de_economia.svg";
import { ReactComponent as Pin } from "../../logo/pin.svg";
import { ReactComponent as Phone } from "../../logo/phone.svg";
import { ReactComponent as Mail } from "../../logo/mail.svg";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";

function Footer() {
  const navigate = useNavigate();
  return (
    <div className="bg-[#121E1A] py-10 z-[10001]"> {/** z so go back to top button stays behind footer and doesn't hide text */}
      <div className="container mx-auto px-4">
        {/* Logos */}
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 justify-items-center mb-10">
          <div className="w-full flex justify-center lg:justify-start">
            <LogoMpe
              className="cursor-pointer max-h-[90px]"
              onClick={() => navigate("/")}
            />
          </div>
          <div className="w-full flex justify-center">
            <LogoAppe
              className="cursor-pointer max-h-[90px]"
              onClick={() => window.open("https://appeportugal.pt/", "_blank")}
            />
          </div>
          <div className="w-full flex justify-center lg:justify-end">
            <LogoSrde
              className="cursor-pointer max-h-[90px]"
              onClick={() =>
                window.open("https://www.madeira.gov.pt/sretc", "_blank")
              }
            />
          </div>
        </div>

        {/* Address, Phone, Email */}
        <div className="grid grid-cols-1 lg:grid-cols-[19%,4%,7%,11%,10%,14%,8%,8%,10%] gap-4 mb-10">
          <div className="flex flex-col items-center lg:items-start text-white text-opacity-75 space-y-4">
            <div className="flex items-center space-x-2">
              <Pin className="h-[20px]" />
              <p className="text-opacity-60 text-base">
                Rua do Bispo, 16 <br />
                2.º andar - Sala 24
                <br />
                9000-073 Funchal
              </p>
            </div>
            <div className="flex items-center space-x-2">
              <Phone className="h-[15px]" />
              <p className="text-base">+351 291 214 320</p>
            </div>
            <div className="flex items-center space-x-2">
              <Mail className="h-[15px]" />
              <span>mpe@mpe.pt</span>
            </div>
          </div>

          

          {/* Links */}
          <div className=" flex-col text-white text-opacity-75 space-y-6 hidden lg:flex">
            <div className="flex flex-col">
              <Link to="/index">
                Inicio
              </Link>
            </div>
          </div>
          <div className=" flex-col text-white text-opacity-75 space-y-6 hidden lg:flex">
            <div className="flex flex-col">
              <p className="text-base">Sobre Nós</p>
              <Link className="mt-2" to="/about">
                MPE
              </Link>
              <Link className="mt-2" to="/aequipa">
                A Equipa
              </Link>
            </div>
          </div>
          <div className=" flex-col text-white text-opacity-75 space-y-6 hidden lg:flex">
            <div className="flex flex-col">
              <p className="text-base">Parques Empresariais</p>
              <Link className="mt-2" to="/parks">
                Os Nossos Parques
              </Link>
              <Link className="mt-2" to="/inscricao">
                Inscrição
              </Link>
            </div>
          </div>
          <div className=" flex-col text-white text-opacity-75 space-y-6 hidden lg:flex">
            <div className="flex flex-col">
              <Link to="/sustenbility">
                Sustentabilidade
              </Link>
            </div>
          </div>
          <div className=" flex-col text-white text-opacity-75 space-y-6 hidden lg:flex">
            <div className="flex flex-col">
              <Link  to="/support">
                Apoio ao Investimento
              </Link>
            </div>
          </div>
          <div className=" flex-col text-white text-opacity-75 space-y-6 hidden lg:flex">
            <div className="flex flex-col">
              <p className="text-base">Comunicação</p>
              <Link className="mt-2" to="/production/announcements">
                Anúncios
              </Link>
              <Link className="mt-2" to="/events">
                Eventos
              </Link>
              <Link className="mt-2" to="/communications">
                Notícias
              </Link>
              <Link className="mt-2" to="/forms">
                Questionários
              </Link>
            </div>
          </div>

          {/* Other Links */}
          <div className=" flex-col text-white text-opacity-75 space-y-6 hidden lg:flex">
            <div className="flex flex-col">
              <p className="text-base">Documentos</p>
              <Link to="/documents">Legislação</Link>
              <Link className="mt-2" to="/Institucional">
                Institucional
              </Link>
            </div>
          </div>
          <div className=" flex-col text-white text-opacity-75 space-y-6 hidden lg:flex">
            <div className="flex flex-col">
              <p className="text-base">Contactos</p>
              <Link className="mt-2" to="/contacts">
                Contactar
              </Link>
              <Link className="mt-2" to="/agendar">
                Agendar Reunião
              </Link>
              <Link className="mt-2" to="/mpe-ativa">
                MPE Ativa
              </Link>
            </div>
          </div>
        </div>


        {/* Terms & Privacy Links */}
        <div className="flex flex-col text-center items-center lg:flex-row lg:justify-center gap-4 text-white text-opacity-75 mb-42">
          <div className="flex flex-row gap-4">
            <Link className="text-base" to="/terms">
              Termos e Condições
            </Link>
            <p className="hidden lg:block">|</p>
          </div>
          <div className="flex flex-row gap-4">
            <Link className="text-base" to="/privacy">
              Política de Privacidade
            </Link>
            <p className="hidden lg:block">|</p>
          </div>
          <div className="flex flex-row gap-4">
            <a
              href="https://www.livroreclamacoes.pt/Inicio/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-base"
            >
              Elogios, Sugestões e Reclamações
            </a>
            <p className="hidden lg:block">|</p>
          </div>
          <div className="flex flex-row gap-4 mt-1">
            <Link className="text-base" to="/reporting-channel">
              Canal de Denúncias
            </Link>
          </div>
        </div>
        <br></br>
        <hr></hr>
        {/* Copyrights and Developed By */}
        <div className="flex items-center justify-center text-white text-opacity-75 mt-2">
          <div className="flex justify-center md:justify-start">
            <p className="text-base">© {moment().format("YYYY")} Madeira Parques Empresariais</p>
          </div>
        </div>
        <div className="flex items-center justify-end text-white text-opacity-75 mt-2">
          <div className="flex justify-center md:justify-end">
            <span className="flex items-center text-[#FFFFFF] text-opacity-75 text-base gap-3">
              Desenvolvido por
              <a
                href="https://www.vipa.pt"
                target="_blank"
                rel="noopener noreferrer"
                className="cursor-pointer"
              >
                <Dev className="w-full max-w-[100px]" />
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;