import styled from "styled-components";
import HeroSection from "../../../resources/images/parks/hero_section_parks.png";

export const AboutHero = styled.div`




  @media (min-width: 100px) and (max-width: 600px){
    width: 100vw;
    min-height: 40vh;
    background-image: url(${HeroSection});
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
  }

  @media only screen and (min-width:601px) and (max-width: 1020px) {
    width: 100vw;
    min-height: 40vh;
    background-image: url(${HeroSection});
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
  }

  @media only screen and (min-width: 1021px) and (max-width: 1440px) {
    width: 100vw;
    min-height: 70vh;
    background-image: url(${HeroSection});
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
  }

  @media only screen and (min-width: 1441px) and (max-width: 2550px) { 
    width: 100vw;
    min-height: 70vh;
    background-image: url(${HeroSection});
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
  }

`;

export const InsButton = styled.button`
    background: #004E3C;
    color: white;
    cursor: pointer;
    width: auto;
    border-radius: 6px;
    margin-top: 15px;
    height: 6vh;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.3s, color 0.3s;
    

    &:hover {
        background: #121E1A;
        color: white;
    }
`;


export const Wrapped = styled.div`
  max-width: 100vw;
  padding-left: 5%;
  padding-right: 5%;
  overflow-x: hidden;


  @media (min-width: 100px) and (max-width: 600px){
    margin-left: 16px;
    margin-right: 16px;
  }

  @media  (min-width:601px) and (max-width: 1020px) {
    margin-left: 80px;
    margin-right: 80px;
  }

  @media  (min-width: 1021px) and (max-width: 1440px) { 
    margin-left: 100px;
    margin-right: 100px;
  }
  
  @media only screen and (min-width: 1441px) and (max-width: 1900px) {
    margin-left: 90px;
    margin-right: 90px;
  }

  @media only screen and (min-width: 1900px) and (max-width: 2550px) { 
    margin-left: 200px;
    margin-right: 200px;
  }
`;

export const WrappedNoOverflow = styled.div`
  max-width: 100vw;
  padding-left: 5%;
  padding-right: 5%;

  @media (min-width: 100px) and (max-width: 600px){
    margin-left: 16px;
    margin-right: 16px;
  }

  @media  (min-width:601px) and (max-width: 1020px) {
    margin-left: 80px;
    margin-right: 80px;
  }

  @media  (min-width: 1021px) and (max-width: 1440px) { 
    margin-left: 100px;
    margin-right: 100px;
  }
  
  @media only screen and (min-width: 1441px) and (max-width: 1900px) {
    margin-left: 90px;
    margin-right: 90px;
  }

  @media only screen and (min-width: 1900px) and (max-width: 2550px) { 
    margin-left: 200px;
    margin-right: 200px;
  }
`;

export const PlaceButton = styled.button`
    border: 2px solid #121E1A;
    border-radius: 7px;
    cursor: pointer;
`;

export const PlaceDiv = styled.div`
    @media (min-width: 100px) and (max-width: 600px){
      font-size: 16px;
      line-height:20px;
      font-weight:400;
      font-family: 'Open Sans', sans-serif;
      padding: 6px;
    }

    @media  (min-width:601px) and (max-width: 1020px) {
      font-size: 16px;
      line-height:20px;
      font-weight:400;
      font-family: 'Open Sans', sans-serif;
      padding: 6px;
    }

    @media  (min-width: 1021px) and (max-width: 1440px) { 
      font-size: 18px;
      line-height:24px;
      font-weight:400;
      font-family: 'Open Sans', sans-serif;
      padding: 7px;
    }

    @media only screen and (min-width: 1441px) and (max-width: 2550px) { 
      font-size: 20px;
      line-height:28px;
      font-weight:400;
      font-family: 'Open Sans', sans-serif;
      padding: 8px;
    }

    @media only screen and (min-width: 2551px) and (max-width: 2700px) { 
      font-size: 24px;
      line-height:28px;
      font-weight:400;
      font-family: 'Open Sans', sans-serif;
      padding: 10px;
    }

`;

export const SearchFilterContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
`;

export const SearchBar = styled.input`
    padding: 10px;
    width: 35%;
    border: 2px solid #ccc;
    border-radius: 8px;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;

    @media (min-width: 100px) and (max-width: 2550px){
      font-size: 16px;
    }

    @media only screen and (min-width: 2551px) and (max-width: 2700px) { 
      font-size: 20px;
    }
`;

export const FilterDropdown = styled.select`
    padding: 10px;
    border: 2px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;

    @media (min-width: 100px) and (max-width: 2550px){
      font-size: 16px;
    }

    @media only screen and (min-width: 2551px) and (max-width: 2700px) { 
      font-size: 20px;
    }
`;

export const GridContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: center;

  @media (min-width: 100px) and (max-width: 600px){
    gap: 6px;
  }

  @media  (min-width:601px) and (max-width: 1020px) {
    gap: 8px;
  }

  @media  (min-width: 1021px) and (max-width: 1440px) { 
    gap: 16px;
    justify-content: left;
  }

  @media only screen and (min-width: 1441px) and (max-width: 2550px) { 
    gap: 20px;
    justify-content: left;
  }
  
  @media only screen and (min-width: 2551px) and (max-width: 2700px) { 
    gap: 32px;
    justify-content: left;
  }
`;

export const GridItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 120px;
`;

export const Image = styled.img`

    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #ccc;
`;

export const Title = styled.p`
    margin-top: 10px;
    font-size: 15px;
    font-weight: semibold;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    display: block;
    width: 100%;
`;


export const Header2div = styled.div`
  @media (min-width: 100px) and (max-width: 600px){
      text-align:center;
    margin-top:40px;
    margin-bottom-40px;
    font-family: 'Open Sans', sans-serif;
    font-size: 26px;
    line-height:36px;
    font-weight: semibold;
    color: #193441;
  }

  @media  (min-width:601px) and (max-width: 1020px) {
      text-align:center;
    margin-top:40px;
    margin-bottom:40px;
    font-family: 'Open Sans', sans-serif;
    font-size: 28px;
    line-height:36px;
    font-weight: semibold;
    color: #193441;
  }

  @media  (min-width: 1021px) and (max-width: 2550px) { 
    text-align:center;
    margin-top:40px;
    margin-bottom:40px;
    font-family: 'Open Sans', sans-serif;
    font-size: 30px;
    line-height:36px;
    font-weight: semibold;
    color: #193441;
  }

  @media only screen and (min-width: 2551px) and (max-width: 4000px) { 
    text-align:center;
    margin-top:40px;
    margin-bottom:40px;
    font-family: 'Open Sans', sans-serif;
    font-size: 36px;
    line-height:36px;
    font-weight: semibold;
    color: #193441;
  }

`;

export const CandDiv = styled.div`
  font-size: 24px;
  line-height:32px;
  font-weight:500;
  font-family: 'Open Sans', sans-serif;
  padding: 10px 60px;

  @media (max-width: 768px) { /* Tablets & small screens */
    font-size: 20px;
    line-height: 28px;
    padding: 10px 40px;
  }

  @media (max-width: 480px) { /* Mobile devices */
    font-size: 18px;
    line-height: 24px;
    padding: 8px 20px;
  }
`;

export const CandP = styled.p`

  @media (min-width: 100px) and (max-width: 600px){
    font-family: 'Open Sans', sans-serif;
    font-size: 26px;
    line-height: 36px;
    text-color: #1D2224;
  }

  @media  (min-width:601px) and (max-width: 1020px) {
    font-family: 'Open Sans', sans-serif;
    font-size: 28px;
    line-height: 36px;
    text-color: #1D2224;
  }

  @media  (min-width: 1021px) and (max-width: 1440px) { 
    font-family: 'Open Sans', sans-serif;
    font-size: 30px;
    line-height: 36px;
    text-color: #1D2224;
  }

  @media only screen and (min-width: 1441px) and (max-width: 2550px) { 
    font-family: 'Open Sans', sans-serif;
    font-size: 30px;
    line-height: 36px;
    text-color: #1D2224;
  }
`;

export const ShowMoreBtn = styled.button`


  padding: 10px;
  background-color: white;
  color: #121E1A;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background: #121E1A;
    color: white;
  }
`;

export const GridInfoP = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  line-height: 24px;
  text-color: #ffffff;
  font-weight: 400;
  text-align: center;
  padding-bottom: 10px;
`;

export const GridInfodiv = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
  line-height: 20px;
  text-color: #ffffff;
  font-weight: 400;
  padding-bottom: 10px;
`;

export const InfoSquareDiv = styled.div`
  position: absolute;
  top: -120%;
  left: 50%;
  transform: translateX(-50%);
  width: 18rem;
  height: auto;
  background-color: #527d5c;
  color: white;
  padding: 16px;
  opacity: 0;
  scale: 0.95;
  transition: opacity 0.5s ease, scale 0.3s ease;
  border-radius: 8px;
  z-index: 50;
  pointer-events: none;

  .group:hover & {
    opacity: 1;
    scale: 1;
    pointer-events: auto;
  }
`;

export const AddressP = styled.p`
  @media (min-width: 100px) and (max-width: 600px){
    font-family: 'Open Sans', sans-serif;
    font-size: 17px;
    line-height: 26px;
    font-weight: 400;f
  }

  @media  (min-width:601px) and (max-width: 1020px) {
    font-family: 'Open Sans', sans-serif;
    font-size: 17px;
    line-height: 26px;
    font-weight: 400;
  }

  @media  (min-width: 1021px) and (max-width: 1440px) { 
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
  }

  @media only screen and (min-width: 1441px) and (max-width: 2550px) { 
    font-family: 'Open Sans', sans-serif;
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
  }
`;

export const FontDiv = styled.div`
  font-family: 'Open Sans', sans-serif;
`;

export const ScrollDiv = styled.div`
  overflow-y: auto;
`;



