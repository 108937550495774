import React, { useEffect, useState, useRef } from "react";
import { Alert, Snackbar, useMediaQuery, IconButton } from "@mui/material";
import "@splidejs/react-splide/css/core";
import { useInView } from "react-intersection-observer";
import { Link, useLocation } from "react-router-dom";
import axiosConfig from "../../providers/axiosConfig";
import ParksIcon from "../../resources/images/home/home_parques_icon.png";
import EmpresasIcon from "../../resources/images/home/home_empresas_icon.png";
import ParceirosIcon from "../../resources/images/home/home_parceiros_icon.png";
import TaxaIcon from "../../resources/images/home/home_taxa_icon.png";
import HexaGreen from "../../resources/images/home/hexa_green.png";
import HexaBlue from "../../resources/images/home/hexa_blue.png";
import MadeiraMap from "../../resources/images/home/madeira_map.png";
import RedMarker from "../../resources/images/home/map_marker_red.png";
import YellowMarker from "../../resources/images/home/map_marker_yellow.png";
import LotesIcon from "../../resources/images/home/lotes_icon.png";
import GrillLeft from "../../resources/images/home/grill_left.png";
import GrillRight from "../../resources/images/home/grill_right.png";
import CrescaHome from "../../resources/images/home/cresca_home.png";
import HomeCresca from "../../resources/images/home/home_cresca.png";
import SustHome from "../../resources/images/home/sustentabilidades_home2.png";
import FooterHero from "../../resources/images/footer_banner_trees.svg";
import MoreNews from "../../resources/images/home/more_news_icon.png";
import NewsLetter from "../../resources/images/home/newsletter_home.png";
import CloseIcon from "@mui/icons-material/Close";
import Flower from "../../resources/images/home/flower_marker.png";
import NewsTop from "../../resources/images/news/news_trees.svg";
import NewsBottom from "../../resources/images/news/news_bottom_divider.svg";

import {
  HeroArea,
  HeroCaption,
  Wrapped,
  HexaImage,
  IconsImage,
  PlaceButton,
  PlaceDiv,
  FontDiv,
  ScrollDiv,
  Slider,
  SlideTrack,
  Slide,
  WordContainer,
  AnimatedWord,
  FallingDivLeft,
  FallingDivRight,
} from "./styles/home_styles";
import OccupationRate from "./partials/OccupationRate";
import apiUrl from "../../providers/apiUrl";

function Home() {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const messageParam = queryParams.get("message");
    if (messageParam) {
      //console.log(messageParam)
      setSnackbarOpen(true);
      setSnackbarMessage(messageParam);
      setSnackbarSeverity(queryParams.get("status"));
    }
  }, [location.search]);

  const { ref: ref1, inView: inView1 } = useInView({
    threshold: window.innerWidth < 768 ? 0.4 : 1,
    triggerOnce: true,
  });

  const { ref: ref2, inView: inView2 } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  const markers = [
    {
      //Calheta
      id: 1,
      top: "73%",
      left: "21%",
      targetPage: "Calheta",
    },
    {
      //Camacha
      id: 2,
      top: "55%",
      left: "68%",
      targetPage: "Camacha",
    },
    {
      //Camara de Lobos
      id: 3,
      top: "73%",
      left: "53%",
      targetPage: "Câmara de Lobos",
    },
    {
      //Cancela
      id: 4,
      top: "64%",
      left: "64%",
      targetPage: "Cancela",
    },
    {
      //Machico
      id: 5,
      top: "40%",
      left: "70%",
      targetPage: "Machico",
    },
    {
      //Porto Moniz
      id: 6,
      top: "50%",
      left: "11%",
      targetPage: "Porto Moniz",
    },
    {
      //Porto Santo
      id: 7,
      top: "9%",
      left: "92%",
      targetPage: "Porto Santo",
    },
    {
      //Ribeira Brava
      id: 8,
      top: "73%",
      left: "45%",
      targetPage: "Ribeira Brava",
    },
    {
      //Santana
      id: 9,
      top: "35%",
      left: "55%",
      targetPage: "Santana",
    },
    {
      //São Vicente
      id: 10,
      top: "50%",
      left: "33%",
      targetPage: "São Vicente",
    },
    {
      //Zona Oeste
      id: 11,
      top: "73%",
      left: "59%",
      targetPage: "Zona Oeste",
    },
  ];

  const addresses = [
    {
      //Calheta
      id: 1,
      title: "Parque Empresarial da Calheta",
      name: "Estreito da Calheta",
      postal: "370-250 Estreito Da Calheta",
      cords: `32°44'55.9"N 17°11'30.4"W`,
    },
    {
      //Camacha
      id: 2,
      title: "Parque Empresarial da Camacha",
      name: "Parque Empresarial da Camacha",
      postal: "9135-401 Camacha",
      cords: `32°40'04.8"N 16°51'20.9"W`,
    },
    {
      //Camara de Lobos
      id: 3,
      title: "Parque Empresarial de Câmara de Lobos",
      name: "Parque Empresarial de Câmara de Lobos Estrada da Ribeira Garcia ",
      postal: "9300-324 Câmara de Lobos",
      cords: `32°40'03.9"N 16°59'50.6"W`,
    },
    {
      //Cancela
      id: 4,
      title: "Parque Empresarial da Cancela",
      name: "Parque Empresarial da Cancela ",
      postal: "9125-042 Caniço",
      cords: `32°38'53.7"N 16°51'37.8"W`,
    },
    {
      //Machico
      id: 5,
      title: "Parque Empresarial de Machico",
      name: "Parque Empresarial de Machico ",
      postal: "9200-405 Machico",
      cords: `32°43'50.3"N 16°48'14.4"W`,
    },
    {
      //Porto Moniz
      id: 6,
      title: "Parque Empresarial do Porto Moniz",
      name: "Parque Empresarial do Porto Moniz ",
      postal: "9270 Porto Moniz",
      cords: `32°50'58.6"N 17°11'44.0"W`,
    },
    {
      //Porto Santo
      id: 7,
      title: "Parque Empresarial do Porto Santo",
      name: "Parque Empresarial do Porto Santo Tanque ",
      postal: "9400-100 Porto Santo",
      cords: `33°04'20.7"N 16°20'36.0"W`,
    },
    {
      //Ribeira Brava
      id: 8,
      title: "Parque Empresarial da Ribeira Brava",
      name: "Sitio Da Boa Morte ",
      postal: "9350-104 Ribeira Brava",
      cords: `32°40'59.4"N 17°02'24.2"W`,
    },
    {
      //Santana
      id: 9,
      title: "Parque Empresarial de Santana",
      name: "Estrada do Pico ",
      postal: "9230-096 Redondo Santana",
      cords: `32°47'10.3"N 16°52'49.0"W`,
    },
    {
      //São Vicente
      id: 10,
      title: "Parque Empresarial de São Vicente",
      name: "Parque Empresarial de São Vicente ",
      postal: "9240 São Vicente",
      cords: `32°46'48.5"N 17°02'48.1"W`,
    },
    {
      //Zona Oeste
      id: 11,
      title: "Parque Empresarial da Zona Oeste",
      name: "Parque Empresarial Zona Oeste ",
      postal: "9300-020 Câmara de Lobos",
      cords: `32°38'43.5"N 16°58'11.8"W`,
    },
  ];

  //console.log('rendering home page...')

  const [places, setPlaces] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activePlace, setActivePlace] = useState(null);
  const [activeButton, setActiveButton] = useState(null);
  const [selectedPlaceInfo, setSelectedPlaceInfo] = useState(null);
  const [partners, setPartners] = useState(null);
  const [partnerscount, setPartnersCount] = useState(null);

  {
    /*GET business park info*/
  }
  useEffect(() => {
    setLoading(true);
    console.log("fecthing parks data...");
    axiosConfig
      .get(`/business-parks`)
      .then((res) => {
        setPlaces(res.data || []);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, []);

  useEffect(() => {
    setLoading(true);
    console.log("fecthing partners data...");
    axiosConfig
      .get(`/partners`)
      .then((res) => {
        setPartners(res.data || []);
        setLoading(false);
        setPartnersCount(res.data.length || 0);
      })
      .catch(() => {
        setPartners([]);
        setLoading(false);
      });
  }, []);

  const [news, setNews] = useState([]);

  {
    /*GET news info*/
  }
  useEffect(() => {
    setLoading(true);
    console.log("fecthing news data...");
    axiosConfig
      .get(`/news`)
      .then((res) => {
        const sortedNews = res.data?.sort((a, b) => {
          return new Date(b.publishDate) - new Date(a.publishDate);
        });

        setNews(sortedNews || []);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, []);

  {
    /*GET news categories info*/
  }
  const [categories, setCategories] = useState({});
  useEffect(() => {
    axiosConfig.get("/news-categories").then((res) => {
      const categoryObj = res.data.reduce((acc, cat) => {
        acc[cat.id] = cat.name;
        return acc;
      }, {});
      setCategories(categoryObj);
    });
  }, []);

  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  {
    /*POST email*/
  }
  const handleSubscribe = async () => {
    setError("");
    setSuccess("");

    if (!/\S+@\S+\.\S+/.test(email)) {
      setError("Por favor, insira um e-mail válido.");
      return;
    }

    try {
      const response = await axiosConfig.post("/newsletter-subscribers", {
        email,
      });
      setSuccess("E-mail enviado com sucesso!");
      setEmail("");
    } catch (err) {
      setError("Erro ao enviar. Tente novamente.");
    }
  };

  const handleButtonClick = (selected) => {
    let place = places.find((p) => p.name === selected.targetPage) || selected;

    setActivePlace(place);
    setActiveButton(place.id);
    setSelectedPlaceInfo(place);
  };

  const [isVisible, setIsVisible] = useState(false);
  const [isReadyForAnimation, setIsReadyForAnimation] = useState(false);

  useEffect(() => {
    if (selectedPlaceInfo) {
      setIsVisible(false);
      setIsReadyForAnimation(false); // Reset animation state

      setTimeout(() => setIsVisible(true), 800);
      setTimeout(() => setIsReadyForAnimation(true), 2000); // Start animation after 2s
    }
  }, [selectedPlaceInfo]);

  {
    /*------- Grill Section -------*/
  }
  const [startAnimation, setStartAnimation] = useState(false);
  const elementRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (elementRef.current) {
        const rect = elementRef.current.getBoundingClientRect();
        const visibleHeight = window.innerHeight - rect.top;

        if (visibleHeight >= rect.height / 4) {
          setStartAnimation(true);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  {
    /*------- Grill Section -------*/
  }

  {
    /*------- Header Page Section -------*/
  }
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const words = ["PROXIMIDADE", "CONFIANÇA", "INOVAÇÃO", "SUCESSO"];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);
  {
    /*------- Header Page Section -------*/
  }

  {
    /*------- Hexagon Section -------*/
  }
  const [isVisibleHexa, setIsVisibleHexa] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisibleHexa(true);
        }
      },
      { threshold: 0.5 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);
  {
    /*------- Hexagon Section -------*/
  }
  {
    /*------- Info Hexagon -------*/
  }

  const totalParks = 11;
  const totalCompanies = places.reduce(
    (sum, item) => sum + (item.number_of_companies || 0),
    0
  );
  const totalPartners = partnerscount;
  const total = places.reduce((sum, item) => {
    const occupationRate = parseFloat(item.occupation_rate) || 0;
    const numberOfSpaces = parseFloat(item.number_of_spaces) || 0;
    return sum + (numberOfSpaces * occupationRate) / 100;
  }, 0);

  const totalSpaces = places.reduce((sum, item) => {
    return sum + (parseFloat(item.number_of_spaces) || 0);
  }, 0);

  const weightedOccupationRate = totalSpaces > 0 ? total / totalSpaces : 0;

  const totalOcupacao = Math.floor(weightedOccupationRate * 100);

  const [hasFallingDivsCompleted, setHasFallingDivsCompleted] = useState(false);
  const [hasStartedNumberAnimation, setHasStartedNumberAnimation] =
    useState(false);
  const [animatedTotalParks, setAnimatedTotalParks] = useState(0);
  const [animatedTotalCompanies, setAnimatedTotalCompanies] = useState(0);
  const [animatedTotalPartners, setAnimatedTotalPartners] = useState(0);
  const [animatedTotalOcupacao, setAnimatedTotalOcupacao] = useState(0);
  const [hoveredMarker, setHoveredMarker] = useState(null);

  useEffect(() => {
    if (isVisibleHexa) {
      setHasFallingDivsCompleted(false);
      setTimeout(() => {
        setHasFallingDivsCompleted(true);
      }, 1000);
    }
  }, [isVisibleHexa]);

  useEffect(() => {
    if (hasFallingDivsCompleted && !hasStartedNumberAnimation) {
      setHasStartedNumberAnimation(true);

      const animateNumber = (setter, targetValue, duration = 500) => {
        let startTime = null;

        const step = (timestamp) => {
          if (!startTime) startTime = timestamp;
          const elapsed = timestamp - startTime;
          const progress = Math.min(elapsed / duration, 1);
          setter(Math.floor(progress * targetValue));

          if (progress < 1) {
            requestAnimationFrame(step);
          }
        };

        requestAnimationFrame(step);
      };

      animateNumber(setAnimatedTotalParks, totalParks);
      animateNumber(setAnimatedTotalCompanies, totalCompanies);
      animateNumber(setAnimatedTotalPartners, totalPartners);
      animateNumber(setAnimatedTotalOcupacao, totalOcupacao);
    }
  }, [
    hasFallingDivsCompleted,
    hasStartedNumberAnimation,
    totalParks,
    totalCompanies,
    totalPartners,
    totalOcupacao,
  ]);
  {
    /*------- Info Hexagon -------*/
  }

  const handleImageClick = (documentPath) => {
    if (documentPath) {
      window.open(documentPath, "_blank");
    }
  };

  return (
    <>
      <Snackbar
        open={snackbarOpen}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={(event, reason) => {
          if (reason === "clickaway") {
            return;
          }
          setSnackbarOpen(false);
        }}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setSnackbarOpen(false)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
        disableBackdropClick
      >
        <Alert
          variant="filled"
          severity={snackbarSeverity}
          onClose={() => setSnackbarOpen(false)}
          sx={{
            padding: "16px 24px",
            fontSize: "16px",
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <HeroArea className="min-h-[900px] md:min-h-max pb-20">
        <Wrapped>
          <FontDiv className="flex w-full xl:py-[60px] ">
            <HeroCaption className="gap-3 mt-72">
              <p className="text-4xl 3xl:text-6xl text-white text-left">
                Conectando empresas e criando
                <br />
                valor, com{" "}
                <WordContainer>
                  <AnimatedWord delay="0s">
                    {words[currentWordIndex]}
                  </AnimatedWord>
                </WordContainer>
              </p>
              <Link
                to="/parks"
                className="mt-3 py-2 px-8 text-base bg-[#FFFFFF] text-[#FFFFFF] bg-opacity-[12%] border-2 rounded-lg transition-all duration-300 group hover:bg-[#FFFFFF] hover:text-[#2F5E42] hover:bg-opacity-100"
              >
                Os Nossos Parques
              </Link>
            </HeroCaption>
          </FontDiv>
        </Wrapped>
      </HeroArea>

      {/*Hexagonos*/}
      <Wrapped className="mt-20 md:mt-32">
        <div ref={sectionRef}>
          <div className="text-xl text-center justify-center 3xl:text-5xl lg:text-3xl md:text-2xl sm:text-xl hidden sm:flex w-full">
            <h2 className="hidden sm:block">
              A Madeira Parques Empresariais é o parceiro ideal <br />
              para o sucesso e crescimento das empresas
            </h2>
          </div>
          <div className="text-xl text-center lg:text-3xl md:text-2xl sm:text-xl sm:hidden ">
            <h2>
              A Madeira Parques Empresariais é o parceiro ideal para o sucesso e
              crescimento das empresas
            </h2>
          </div>

          <div className="flex flex-col sm:flex-row xs:flex-col gap-2 sm:gap-10 justify-center lg:my-36 md:my-24 sm:my-14 my-36 w-auto h-[100px] lg:h-[250px] md:h-[125px] sm:h-[100px] xs:h-[100px]">
            <div className="flex flex-row gap-10 justify-start sm:gap-10 sm:justify-start">
              <FallingDivLeft isVisible={isVisibleHexa} delay="0s">
                <HexaImage src={HexaGreen} />
                <IconsImage
                  src={ParksIcon}
                  className="absolute top-[15%] left-[31%]"
                />
                <p className="absolute text-2xl lg:text-4xl md:text-2xl sm:text-xl top-[55%] lg:top-[55%] md:top-[65%] sm:top-[80%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 text-white">
                  {animatedTotalParks}
                </p>
                <p className="absolute text-base 3xl:text-lg lg:text-base md:text-sm sm:text-xs top-[70%] lg:top-[75%] md:top-[90%] sm:top-[105%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 text-white">
                  Parques
                </p>
              </FallingDivLeft>
              <FallingDivLeft isVisible={isVisibleHexa} delay="0s">
                <HexaImage src={HexaBlue} />
                <IconsImage
                  src={EmpresasIcon}
                  className="absolute top-[15%] left-[33%]"
                />
                <p className="absolute text-2xl lg:text-4xl md:text-2xl sm:text-xl top-[55%] lg:top-[55%] md:top-[65%] sm:top-[80%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 text-white">
                  {animatedTotalCompanies}
                </p>
                <p className="absolute text-base 3xl:text-lg lg:text-base md:text-sm sm:text-xs top-[70%] lg:top-[75%] md:top-[90%] sm:top-[105%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 text-white">
                  Empresas
                </p>
              </FallingDivLeft>
            </div>
            <div className="flex flex-row gap-10 sm:gap-10 justify-end sm:justify-start">
              <FallingDivRight isVisible={isVisibleHexa} delay="0s">
                <HexaImage src={HexaGreen} />
                <IconsImage
                  src={ParceirosIcon}
                  className="absolute top-[15%] left-[35%]"
                />
                <p className="absolute text-2xl lg:text-4xl md:text-2xl sm:text-xl top-[55%] lg:top-[55%] md:top-[65%] sm:top-[80%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 text-white">
                  {animatedTotalPartners}
                </p>
                <p className="absolute text-base 3xl:text-lg lg:text-base md:text-sm sm:text-xs top-[70%] lg:top-[75%] md:top-[90%] sm:top-[105%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 text-white">
                  Parceiros
                </p>
              </FallingDivRight>
              <FallingDivRight isVisible={isVisibleHexa} delay="0s">
                <HexaImage src={HexaBlue} />
                <IconsImage
                  src={TaxaIcon}
                  className="absolute top-[15%] left-[35%]"
                />
                <p className="absolute text-2xl lg:text-4xl md:text-2xl sm:text-xl top-[50%] lg:top-[55%] md:top-[65%] sm:top-[80%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 text-white">
                  {animatedTotalOcupacao}%
                </p>
                <p className="absolute text-base 3xl:text-lg lg:text-base md:text-sm sm:text-xs top-[70%] lg:top-[75%] md:top-[90%] sm:top-[105%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 text-white text-center leading-none">
                  Taxa de Ocupação
                </p>
              </FallingDivRight>
            </div>
          </div>
          <div className=" text-start justify-center pt-5 flex w-[100%] lg:pt-0 sm:pt-5">
            <div className="md:w-[50vw] w-full ">
              <p className="3xl:text-3xl">
                A Madeira Parques Empresariais disponibiliza um ambiente
                propício ao crescimento e sucesso das empresas, garantindo
                condições adequadas para o seu desenvolvimento.
              </p>
            </div>
          </div>
        </div>
      </Wrapped>

      <Wrapped className="mt-20 md:mt-32">
        <div className="text-[#121E1A] text-4xl mb-14 font-medium text-center lg:text-left">
          <h2>Os Nossos Parques</h2>
        </div>
      </Wrapped>

      {/* Buttons Map */}
      <Wrapped>
        <div className="mt-6 flex gap-2 justify-left flex-wrap lg:gap-8 md:gap-4 sm:gap-2">
          {places.map((place) => (
            <PlaceButton
              key={place.id}
              onClick={() => handleButtonClick(place)}
              style={{
                backgroundColor: activeButton === place.id ? "#2F5D42" : "",
                color: activeButton === place.id ? "white" : "",
                borderColor: activeButton === place.id ? "#2F5D42" : "",
                transition: "background-color 0.3s ease",
              }}
            >
              <PlaceDiv>{place.name}</PlaceDiv>
            </PlaceButton>
          ))}
        </div>
      </Wrapped>

      {/* Madeira Map*/}
      <Wrapped>
        <div className="flex flex-col lg:flex-row items-center justify-center">
          <div
            className="relative w-full lg:w-[60%] md:w-[80%] transition-all duration-200"
            style={{
              width: selectedPlaceInfo
                ? window.innerWidth >= 1024
                  ? "60%"
                  : "80%"
                : "70%",
              transition: "width 2s ease",
              position: "relative",
            }}
          >
            <img src={MadeiraMap} alt="MadeiraMap" className="w-full h-auto" />

            {markers.map((marker) => (
              <button
                key={marker.id}
                onClick={() => handleButtonClick(marker)}
                onMouseEnter={() => setHoveredMarker(marker.id)}
                onMouseLeave={() => setHoveredMarker(null)}
                className="absolute"
                style={{
                  top: marker.top,
                  left: marker.left,
                  transform: "translate(-50%, -50%)",
                }}
              >
                <img
                  src={activeButton === marker.id ? YellowMarker : RedMarker}
                  alt={`${marker.id}`}
                  className={`h-auto w-[15px] ${
                    !selectedPlaceInfo
                      ? "lg:w-[40px] md:w-[30px] sm:w-[20px]"
                      : "lg:w-[30px] md:w-[25px] sm:w-[15px]"
                  }`}
                />
                {hoveredMarker === marker.id && (
                  <div className="absolute top-1/2 left-1/2 z-50 flex transform -translate-x-1/2 mb-2 px-2 py-1 text-base text-white bg-black rounded">
                    {marker.targetPage}
                  </div>
                )}
              </button>
            ))}
          </div>
          {selectedPlaceInfo && (
            <div
              className="w-full flex flex-col lg:w-[40%] relative transition-all linear"
              style={{
                position: "relative",
                transform: isVisible ? "translateX(0)" : "translateX(-200%)",
                opacity: isVisible ? 1 : 0,
                transitionDuration: isVisible ? "1s" : "0s",
                transitionTimingFunction: "linear",
              }}
            >
              {selectedPlaceInfo.address && (
                <>
                  {addresses
                    .filter((address) => address.id === selectedPlaceInfo.id)
                    .map((filteredAddress) => (
                      <div key={filteredAddress.id}>
                        <h2 className="text-xl lg:text-2xl text-[#3A4448] my-3">
                          {filteredAddress.title}
                        </h2>
                      </div>
                    ))}
                </>
              )}
              {selectedPlaceInfo?.id &&
                places?.length > 0 &&
                places
                  .filter((place) => place.id === selectedPlaceInfo.id)
                  .map((filteredPlace) => (
                    <FontDiv className="flex gap-2 mb-3" key={filteredPlace.id}>
                      {/* lots and companies */}
                      <div className="bg-[#537D5C] text-white h-[248px] w-full md:w-[60%] text-center py-2 px-4 rounded">
                        <div className="flex justify-evenly pt-5 gap-5 flex-col md:gap-0 md:pt-11 md:flex-row">
                          <div className="flex flex-col items-center gap-2 md:gap-5">
                            <img
                              src={LotesIcon}
                              alt="LotesIcon"
                              className="w-auto h-8"
                            />
                            <div className="flex flex-row gap-5 md:flex-col ">
                              <p className="text-2xl sm:text-4xl">
                                {filteredPlace.number_of_spaces}
                              </p>
                              <p>Número de espaços</p>
                            </div>
                          </div>
                          <div className="flex flex-col items-center gap-2 md:gap-5">
                            <img
                              src={EmpresasIcon}
                              alt="EmpresasIcon"
                              className="w-auto h-8"
                            />
                            <div className="flex flex-row gap-5 md:flex-col ">
                              <p className="text-2xl sm:text-4xl">
                                {filteredPlace.number_of_companies}
                              </p>
                              <p>Empresas</p>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Occupation rate */}
                      <OccupationRate
                        occupationRate={filteredPlace.occupation_rate}
                      />
                    </FontDiv>
                  ))}
              {selectedPlaceInfo?.id &&
                places?.length > 0 &&
                places
                  .filter((place) => place.id === selectedPlaceInfo.id)
                  .map((filteredPlace) => (
                    <div className="flex gap-2 mb-1">
                      {/* number of workers */}
                      <FontDiv className="bg-[#2F5E42] text-white h-[248px] w-[40%] text-center py-14 px-4 rounded">
                        <p className="pt-5 text-2xl md:text-5xl">
                          {filteredPlace.number_of_job_positions}
                        </p>
                        <p className="pt-1 text-base md:text-lg">
                          N.º estimado de trabalhadores
                        </p>
                      </FontDiv>

                      {/* Description */}
                      <ScrollDiv className="bg-[#20432E] text-white h-[248px] w-[60%] text-start p-5 rounded">
                        <FontDiv>
                          <p className=" text-sm md:text-lg">
                            {filteredPlace.description}
                          </p>
                        </FontDiv>
                      </ScrollDiv>
                    </div>
                  ))}
            </div>
          )}
        </div>
      </Wrapped>

      {/*Grills */}
      <div className="flex flex-col items-center mt-20 md:mt-32">
        {/* Animation Section */}
        <div
          ref={elementRef}
          className="relative flex w-full h-[400px] overflow-hidden"
        >
          {/* Left Grill  */}
          <div
            className={`h-full transition-all duration-[2s] linear ${
              startAnimation ? "w-[20%]" : "w-1/2"
            } lg:h-full md:h-[85%] sm:h-[70%]`}
            style={{
              backgroundImage: `url(${GrillLeft})`,
              backgroundSize: "auto 100%",
              position: "absolute",
              left: 0,
              top: 0,
            }}
          />

          <div
            className={`px-4 text-center text-[#121E1A] transition-all duration-[2s] ${
              startAnimation ? "opacity-100" : "opacity-0"
            }`}
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <p className="text-xl font-semibold lg:text-5xl md:text-4xl sm:text-3xl ">
              Madeira Parques é uma porta
            </p>
            <p className="text-xl font-semibold lg:text-5xl md:text-4xl sm:text-3xl sm:pb-24 md:pb-10">
              para o sucesso da sua empresa.
            </p>
          </div>

          {/* Right Grill */}
          <div
            className={`h-full transition-all duration-[2s] linear ${
              startAnimation ? "w-[20%]" : "w-1/2"
            } lg:h-full md:h-[85%] sm:h-[70%]`}
            style={{
              backgroundImage: `url(${GrillRight})`,
              backgroundSize: "auto 100%",
              position: "absolute",
              right: 0,
              top: 0,
            }}
          />
        </div>
      </div>

      {/*Cresca com a MPE */}
      <Wrapped className="mt-20 md:mt-32">
        <div ref={ref1}></div>
        <div className="flex flex-col justify-between w-full lg:flex-row">
          <div
            className={`flex-row w-full ${
              inView1 ? "slide-in-left visible" : "invisible"
            } lg:w-1/2`}
          >
            {/* <div className="mt-24 flex flex-col" ref={ref1} > */}
            <div className="mb-10">
              <h2
                className={`text-[#121E1A] text-3xl font-medium text-center lg:text-left ${
                  inView1 ? "slide-in-left visible" : "invisible"
                }`}
              >
                Cresça com a MPE
              </h2>
              <p
                className={`font-medium text-xl text-center lg:text-left ${
                  inView1 ? "slide-in-left visible" : "invisible"
                }`}
              >
                Vantagens para a sua empresa
              </p>
            </div>
            <ul className="text-[#1D2224] text-base 2xl:text-xl sm:text-base space-y-9">
              <li className="flex gap-2">
                <img src={Flower} alt="flower" className="w-4 h-4" />
                Possibilidade de usufruir de condições vantajosas em parcerias
                celebradas pela MPE, S.A., entre outras, com instituições
                financeiras, garantindo melhores condições no apoio ao
                financiamento de empresas e à tesouraria das mesmas.
              </li>
              <li className="flex gap-2">
                <img src={Flower} alt="flower" className="w-4 h-4" />
                Poderá ser concedido um período de carência, até 12 meses,
                durante a construção.
              </li>
              <li className="flex gap-2">
                <img src={Flower} alt="flower" className="w-4 h-4" />
                Majoração nos apoios concedidos pelo organismo coordenador aos
                sectores secundários e terciários (IDE-RAM), a projetos
                localizados em parques empresariais.
              </li>
              <li className="flex gap-2">
                <img src={Flower} alt="flower" className="w-4 h-4" />
                Apoio nos processos de licenciamento das atividades junto das
                atividades licenciadoras.
              </li>
              <li className="flex gap-2">
                <img src={Flower} alt="flower" className="w-4 h-4" />
                Redução ou isenção de pagamento de taxas camarárias.
              </li>
            </ul>
          </div>
          <div className="flex w-full lg:w-1/2">
            <div className="container grid grid-cols-12 lg:grid-rows-6">
              {/* Main Image */}
              <img
                src={CrescaHome}
                alt="CrescaHome"
                className={`grid col-span-10 lg:row-span-4 col-start-2 lg:col-start-3 lg:row-start-1
                  mt-10
                  transform transition-all duration-500
                  ${inView1 ? "slide-in-down visible" : "invisible"}`}
              />

              {/* Overlay Image */}
              <img
                src={HomeCresca}
                alt="HomeCresca"
                className={`lg:grid lg:col-span-10 lg:row-span-4 lg:col-start-2 lg:row-start-4
                  transform translate-y-[-50%] transition-all duration-500
                  lg:static lg:translate-y-0 hidden
                  ${inView1 ? "slide-in-up visible" : "invisible"}`}
              />
            </div>
          </div>
        </div>

        {/*Sustentabilidade e ESG */}
        <div className="mt-20 md:mt-32">
          <div
            className="w-full mt-16 bg-[#CAD4D8] bg-opacity-[22%] sm:bg-opacity-[0%]"
            style={{
              backgroundImage: `url(${SustHome})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              minHeight: "600px",
              position: "relative",
            }}
          >
            <p className="text-white font-bold drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)] text-xl ml-0 p-4 sm:text-3xl md:ml-28 pt-8">
              Sustentabilidade e ESG
            </p>

            <div className=" bottom-[10%] w-full flex items-end ">
              <div className="sm:border-4 p-2 sm:mx-7 sm:rounded-lg relative overflow-hidden flex-row lg:p-12 md:p-8 sm:p-4 3xl:mt-60 lg:mt-58 md:mt-24 sm:mt-16 lg:mx-24 md:mx-14">
                <div className="absolute inset-0 bg-[#CAD4D8] bg-opacity-[22%] backdrop-blur-sm"></div>
                <div className="flex justify-between w-full text-white relative z-10 flex-col p-4 lg:flex-row md:flex-col sm:flex-col">
                  <div className="w-full lg:w-[70%] md:w-[90%] sm:w-[90%]">
                    <p>
                      A sustentabilidade é um tema prioritário para a Madeira
                      Parques Empresariais, pois devemo-nos focar em ter uma
                      gestão sustentável na nossa organização, isto é, a
                      estratégia deve ser definida com foco no crescimento a
                      longo prazo e com o objetivo de dar resposta a problemas
                      ambientais e sociais. Somos defensores da segurança e
                      proteção das comunidades onde os parques empresariais
                      estão inseridos.
                    </p>
                  </div>
                  <div className="flex flex-col justify-center items-center mt-24 sm:mt-4 lg:mt-0">
                    <Link to="/sustenbility" className="py-3 px-3 bg-[#121E1A] w-56 md:w-32 xl:w-56 rounded-md text-center">
                      Saber Mais
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Wrapped>

      {/*Ultimas Noticias*/}
      <div className="mt-20 md:mt-32">
        <img src={NewsTop} alt="News Hero" className="w-full" />
        <div className="bg-[#2F5E42] flex" ref={ref2}>
          <Wrapped>
            <div className="flex flex-col gap-14 lg:flex-row md:flex-row sm:flex-col">
              <div className="flex flex-col w-full lg:w-[40%] md:w-[50%] sm:w-[100%] ">
                <div className="mb-4 md:mb-6 lg:mb-8">
                  <p
                    className={`text-white text-4xl ${
                      inView2 ? "slide-in-right visible" : "invisible"
                    }`}
                  >
                    Últimas
                  </p>
                  <p
                    className={`text-white text-4xl ${
                      inView2 ? "slide-in-right visible" : "invisible"
                    }`}
                  >
                    Notícias
                  </p>
                </div>
                <p
                  className={`text-white mt-3 ${
                    inView2 ? "slide-in-right visible" : "invisible"
                  }`}
                >
                  Acompanhe as últimas novidades e mantenha-se informado sobre
                  os nossos projetos e iniciativas
                </p>
                <Link
                  to="/communications"
                  className={`w-58 mt-10 p-[8px] justify-center border-[#F6F6F6] text-white flex border-2 rounded-md gap-2 items-center ${
                    inView2 ? "slide-in-up visible" : "invisible"
                  }`}
                >
                  <img src={MoreNews} alt="MoreNews" className="w-auto h-6" />
                  Todas as Notícias
                </Link>
              </div>
              <div
                className={`flex flex-col w-full lg:w-[55%] md:w-[70%] sm:w-[100%] gap-3 ${
                  inView2 ? "slide-in-up visible" : "invisible"
                }`}
              >
                {news.slice(0, 3).map((recentNews) => (
                  <Link to={`news/show/${recentNews.id}`} key={recentNews.id}>
                    <div
                      key={recentNews.id}
                      className="bg-[#121E1A] py-3 px-6 rounded-lg"
                    >
                      <div className="flex flex-row">
                        <p className="text-white">
                          {categories[recentNews.news_category_id] ||
                            "Sem categoria"}
                        </p>
                        <p className="text-white ml-8">
                          {new Date(
                            recentNews.published_date
                          ).toLocaleDateString("pt-PT", {
                            day: "2-digit",
                            month: "long",
                            year: "numeric",
                          })}
                        </p>
                      </div>
                      <div>
                        <p className="text-white my-4">{recentNews.title}</p>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </Wrapped>
        </div>
        <img src={NewsBottom} alt="News Bottom Divider" className="w-full" />
      </div>

      {/*Carousel*/}
      <Wrapped className="mt-20 md:mt-32">
        {partners?.length > 0 && (
          <>
            <div className="text-center">
              <p className="text-4xl font-medium">Parceiros</p>
            </div>
            <div className="mt-10 md:mt-16 lg:mt-32">
              <Slider>
                <SlideTrack>
                  {[...Array(20)]
                    .flatMap(() => partners)
                    .map((partner, index) => {
                      // Check if there's a valid document after trimming
                      const isValidDocument =
                        partner.document_path && partner.document_path !== "";
                      return (
                        <Slide key={index}>
                          <img
                            src={partner.image_path}
                            alt={`Slide ${index + 1}`}
                            onClick={() => {
                              if (isValidDocument) {
                                handleImageClick(partner.document_path);
                              }
                            }}
                            style={{
                              cursor: isValidDocument ? "pointer" : "default",
                            }}
                          />
                        </Slide>
                      );
                    })}
                </SlideTrack>
              </Slider>
            </div>
          </>
        )}
      </Wrapped>

      {/*NewsLetter*/}
      <Wrapped className="mt-20 md:mt-32">
        <div className="flex flex-col gap-14 lg:flex-row md:flex-row sm:flex-col justify-between">
          <div className="flex flex-col w-full xl:w-1/3 lg:w-1/2 md:w-1/2 3xl:pr-20 xl:pr-5">
            <p className="text-4xl font-medium mb-8 text-center lg:text-left">
              Newsletter
            </p>
            <p className="text-lg mb-4">
              Fique por dentro de todas as novidades da Madeira Parques
              Empresariais! Subscreva à nossa newsletter e acompanhe o nosso
              compromisso com a inovação e desenvolvimento.
            </p>
            <div className="flex flex-col mt-10">
              <div className="flex w-full sm:w-full">
                <input
                  type="email"
                  className="p-2 border-2 border-[#121E1A] rounded-l-md w-full"
                  placeholder="Digite seu e-mail"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <button
                  onClick={handleSubscribe}
                  className="px-5 p-1 flex justify-center bg-[#121E1A] text-white border-2 border-[#121E1A] rounded-r items-center"
                >
                  Subscrever
                </button>
              </div>

              {error && <p className="text-red-500 mt-2">{error}</p>}

              {success && <p className="text-[#2F5E42] mt-2">{success}</p>}
            </div>
          </div>

          <div className="flex flex-col w-full md:w-1/2">
            <img
              src={NewsLetter}
              alt="NewsLetter"
              className="w-full h-auto object-cover rounded-lg"
            />
          </div>
        </div>
      </Wrapped>

      <div className="relative w-full mt-20 md:mt-32">
        <img src={FooterHero} alt="Footer" className="w-full h-auto" />
        <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent from-80% to-[#121E1A]"></div>
      </div>
    </>
  );
}

export default Home;
