import React, { useEffect, useState } from 'react'

const OccupationRate = ({ occupationRate }) => {
	const [animatedValue, setAnimatedValue] = useState(0);
	const [isReadyForAnimation, setIsReadyForAnimation] = useState(false);

	const radius = 70;
	const circumference = 2 * Math.PI * radius;
	const maxDashArray = (240 / 360) * circumference;
	const dashOffset = (1 - animatedValue / 100) * maxDashArray;


	useEffect(() => {

			const timer = setTimeout(() => {
					setIsReadyForAnimation(true);
			}, 2000);

			return () => clearTimeout(timer);
	}, []);

	useEffect(() => {
			if (isReadyForAnimation) {
					setAnimatedValue(0);

					const duration = 800;

					const startTime = performance.now();

					const animate = (timestamp) => {
							const elapsed = timestamp - startTime;
							const progress = Math.min(elapsed / duration, 1);

							setAnimatedValue(Math.floor(progress * occupationRate));

							if (progress < 1) {
									requestAnimationFrame(animate);
							}
					};

					requestAnimationFrame(animate);
			}
	}, [isReadyForAnimation, occupationRate]);

	return (
			<div className="bg-[#2F5E42] text-white h-[248px] w-[40%] text-center py-4 px-4 rounded">
					<h2 className="text-lg mb-11">Taxa de Ocupação (%) </h2>

					<div className="relative flex justify-center items-center">
							<svg width="170" height="auto" viewBox="0 0 160 110" className="transform md:h-[100px]">
									<circle cx="50%" cy="80" r={radius} stroke="#ffffff" strokeWidth="15" fill="none" />

									<circle
											cx="50%"
											cy="80"
											r={radius}
											stroke="#344B57"
											strokeWidth="17"
											fill="none"
											strokeDasharray={maxDashArray}
											strokeDashoffset={dashOffset}
											transform="rotate(150 80 80)"
											style={{
													transition: "stroke-dashoffset 2s ease-out",
											}}
									/>

									<text x="50%" y="70%" textAnchor="middle" dominantBaseline="middle" className="text-5xl" fill="#ffffff">
											{Math.floor(animatedValue)}
									</text>
							</svg>
					</div>
			</div>
	);
};


export default OccupationRate