import styled from "styled-components";
import GreenBottom from "../../../resources/images/home/divider_green_bottom.svg";
import TextureBG from "../../../resources/images/home/bg_texture.png";
import DarkTextureBG from "../../../resources/images/home/bg_dark_texture.png";
import TextureTop from "../../../resources/images/about/TextureTop.png";
import TextureBottom from "../../../resources/images/about/TextureBottom.png";
import ContactosHero from "../../../resources/images/contacts/contactos_hero.png";
import BottomDivider from "../../../resources/images/news/divider_bottom.svg";

export const ContactsHero = styled("div")`
  width: 100vw;
  min-height:30vh;
  position: relative;
  background-image: url(${ContactosHero});
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
`;

export const Wrapped = styled.div`
    margin-left: 200px;
    margin-right: 200px;
    font-family: 'Open Sans', sans-serif;

  @media (min-width: 100px) and (max-width: 600px){
    margin-left: 16px;
    margin-right: 16px;
    font-family: 'Open Sans', sans-serif;
  }

  @media  (min-width:601px) and (max-width: 1020px) {
    margin-left: 80px;
    margin-right: 80px;
    font-family: 'Open Sans', sans-serif;
  }

  @media  (min-width: 1021px) and (max-width: 1440px) { 
    margin-left: 100px;
    margin-right: 100px;
    font-family: 'Open Sans', sans-serif;
  }

  @media only screen and (min-width: 1441px) and (max-width: 2550px) { 
    margin-left: 200px;
    margin-right: 200px;
    font-family: 'Open Sans', sans-serif;
  }
`;

export const HeroCaption = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const FontDiv = styled.div`
  font-family: 'Open Sans', sans-serif;
`;

export const NewsHero = styled("div")`
  width: 100%;
  /* min-height: 80vh; */
  position: relative;
  z-index: 1;

  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: -40px;
    background-image: url(${BottomDivider});
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1;
    width: 100%;
    transform: rotate(180deg);
  }
  & .newscontainer {
    position: relative;
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: -40px;
      background-image: url(${BottomDivider});
      background-size: cover;
      background-repeat: no-repeat;
      z-index: -1;
      width: 100%;
      height: 100px;
      transform: rotate(0deg);
    }
  }
  
`;