import styled, { keyframes, css } from "styled-components";
import TextureBG from "../../../resources/images/home/bg_texture.png";
import PaintBottom from "../../../resources/images/home/divider_green_bottom.svg";
import PaintTop from "../../../resources/images/home/divider_green_top.svg";
import HeroBg from "../../../resources/images/home/home_hero.png";
import Divider from "../../../resources/images/home/page-divider.png";
export const HeroArea = styled("div")`
  width: 100%;
  height: 100vh; 
  max-height: 1080px;
  background-image: url(${HeroBg});
  background-size: cover;
`;
export const HeroCaption = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  text-align: center;
  /* position: relative; */
  /* translate: 0 -50%; */
`;
export const AlertCarousel = styled("div")`
  display: flex;
  scroll-snap-type: x mandatory;
  scroll-padding: 50%;
  max-width: calc(100% - 1.5rem);
  overflow-x: auto;

  @media (min-width: 1024px) {
    overflow-x: hidden;
  }
`;
export const StatsSection = styled("div")`
  position: relative;
  z-index: 1;
  width: 100%;
  background-image: url(${TextureBG});

  &::before {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -1;
    top: -50px;
    content: "";
    display: block;
    background-image: url(${Divider});
    background-size: auto;
    @media (min-width: 1480px) {
      background-size: cover;
    }
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
    height: 50px;
    z-index: 2;
    background-image: url(${PaintBottom});
    background-size: cover;
    background-repeat: no-repeat;
  }
`;
export const TextureBackground = styled("div")`
  width: 100%;
  background-image: url(${TextureBG});
  position: relative;

  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
    height: 50px;
    z-index: 2;
    background-image: url(${PaintBottom});
    background-size: cover;
    background-repeat: no-repeat;
  }
  &::before {
    content: "";
    display: block;
    position: absolute;
    top: -20px;
    left: 0;
    width: 100%;
    height: 50px;
    z-index: 2;
    transform: rotate(180deg);
    background-image: url(${PaintTop});
    background-size: cover;
    background-repeat: no-repeat;
  }
`;

export const SimpleTextureBackground = styled("div")`
  width: 100%;
  position: relative;
`;

export const PartnersAction = styled("div")`
  background-color: #fafafa;
  position: relative;
  &::before {
    content: "";
    display: block;
    position: absolute;
    top: -20px;
    left: 0;
    width: 100%;
    height: 50px;
    z-index: 2;
    transform: rotate(180deg);
    background-image: url(${PaintTop});
    background-size: cover;
    background-repeat: no-repeat;
  }
`;

export const CalltoAction = styled("div")`
  width: 100%;
  background-color: #1c6c5e;
  position: relative;
  /* height: 30vh; */
  max-height: 100%;
  display: flex;
  align-items: center;
  z-index: 1;
  overflow-x: hidden;
  /* & img {
    position: absolute;
    width: 50%;
    right: 0;
  } */
  @media (min-width: 1024px) {
    height: 70vh;
  }
  /* @media (min-width: 1280px) {
    & img {
      width: unset;
    }
  } */

  /* &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
    height: 50px;
    z-index: -1;
    transform: rotate(180deg);
    background-image: url(${PaintBottom});
    background-size: cover;
    background-repeat: no-repeat;
  } */
`;

export const Wrapped = styled.div`
    margin-left: 200px;
    margin-right: 200px;
    font-family: 'Open Sans', sans-serif;

  @media (min-width: 100px) and (max-width: 600px){
    margin-left: 16px;
    margin-right: 16px;
    font-family: 'Open Sans', sans-serif;
  }

  @media  (min-width:601px) and (max-width: 1020px) {
    margin-left: 80px;
    margin-right: 80px;
    font-family: 'Open Sans', sans-serif;
  }

  @media  (min-width: 1021px) and (max-width: 1440px) { 
    margin-left: 100px;
    margin-right: 100px;
    font-family: 'Open Sans', sans-serif;
  }

  @media only screen and (min-width: 1441px) and (max-width: 2550px) { 
    margin-left: 200px;
    margin-right: 200px;
    font-family: 'Open Sans', sans-serif;
  }
`;

export const HexaImage = styled.img`
  width: auto;
  height: 250px;

  @media (min-width: 100px) and (max-width: 600px){
    width: auto;
    height: 150px;
  }

  @media  (min-width:601px) and (max-width: 1020px) {
    width: auto;
    height: 150px;
  }

  @media  (min-width: 1021px) and (max-width: 1440px) { 
    width: auto;
    height: 200px;
  }

  @media only screen and (min-width: 1024px) and (max-width: 2550px) { 
   width: auto;
    height: 250px;
  }
`;

export const IconsImage = styled.img`
  width: auto;
  height: 60px;

  @media (min-width: 100px) and (max-width: 600px){
    width: auto;
    height: 30px;
  }

  @media  (min-width:601px) and (max-width: 1020px) {
     width: auto;
    height: 40px;
  }

  @media  (min-width: 1021px) and (max-width: 1440px) { 
    width: auto;
    height: 50px;
  }

  @media only screen and (min-width: 1441px) and (max-width: 2550px) { 
    width: auto;
    height: 60px;
  }
`;

export const PlaceButton = styled.button`
    border: 2px solid #121E1A;
    border-radius: 7px;
    cursor: pointer;
`;

export const PlaceDiv = styled.div`
    @media (min-width: 100px) and (max-width: 600px){
      font-size: 16px;
      line-height:20px;
      font-weight:400;
      font-family: 'Open Sans', sans-serif;
      padding: 6px;
    }

    @media  (min-width:601px) and (max-width: 1020px) {
      font-size: 16px;
      line-height:20px;
      font-weight:400;
      font-family: 'Open Sans', sans-serif;
      padding: 6px;
    }

    @media  (min-width: 1021px) and (max-width: 1440px) { 
      font-size: 18px;
      line-height:24px;
      font-weight:400;
      font-family: 'Open Sans', sans-serif;
      padding: 7px;
    }

    @media only screen and (min-width: 1441px) and (max-width: 2550px) { 
      font-size: 20px;
      line-height:28px;
      font-weight:400;
      font-family: 'Open Sans', sans-serif;
      padding: 8px;
    }

    @media only screen and (min-width: 2551px) and (max-width: 2700px) { 
      font-size: 24px;
      line-height:28px;
      font-weight:400;
      font-family: 'Open Sans', sans-serif;
      padding: 10px;
    }

`;

export const FontDiv = styled.div`
  font-family: 'Open Sans', sans-serif;
`;

export const ScrollDiv = styled.div`
  overflow-y: auto;

  /* Hides the scrollbar */
  &::-webkit-scrollbar {
    display: none; /* Hide the scrollbar in Webkit browsers */
  }

  /* For Firefox */
  scrollbar-width: thin;
`;

export const Slider = styled.div`
  height: 200px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-width: 100%;

  &::before,
  &::after {
    content: "";
    height: 200px;
    position: absolute;
    width: 200px;
    z-index: 2;
    background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  }

  &::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  &::before {
    left: 0;
    top: 0;
  }
`;

const scroll = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(-50%); }
`;

export const SlideTrack = styled.div`
  display: flex;
  gap: 80px;
  animation: ${scroll} 160s linear infinite;
  width: auto;
  align-items: center; 
`;

export const Slide = styled.div`
  height: 100%;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;

  & img {
    background-size: cover;
    background-repeat: no-repeat;
    max-height: 150px;
    width: auto;
  }
`;

const fadeInOut = keyframes`
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
`;

export const WordContainer = styled.span`
  display: inline;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
`;

export const AnimatedWord = styled.span`
  color: #7DEB4A;
  display: inline;
  opacity: 0;
  animation: ${fadeInOut} 3s ease infinite;
  animation-delay: ${(props) => props.delay || "0s"};
`;


const fallLeft = keyframes`
  0% { transform: translate(-50px, -200px) rotate(-25deg); opacity: 0; }
  20% { opacity: 1; } /* Opacity reaches 1 early */
  50% { transform: translate(-20px, -100px) rotate(-10deg); }
  100% { transform: translate(0, 0) rotate(0deg); }
`;

const fallRight = keyframes`
  0% { transform: translate(50px, -200px) rotate(25deg); opacity: 0; }
  20% { opacity: 1; } /* Opacity reaches 1 early */
  50% { transform: translate(20px, -100px) rotate(10deg); }
  100% { transform: translate(0, 0) rotate(0deg); }
`;

export const FallingDivLeft = styled.div`
  position: relative;
  ${(props) =>
    props.isVisible &&
    css`
      animation: ${fallLeft} 0.5s ease-out forwards;
      animation-delay: ${props.delay || "0s"};
      opacity: 1; /* Ensures opacity remains 1 after animation */
    `}
`;

export const FallingDivRight = styled.div`
  position: relative;
  ${(props) =>
    props.isVisible &&
    css`
      animation: ${fallRight} 0.5s ease-out forwards;
      animation-delay: ${props.delay || "0s"};
      opacity: 1; /* Ensures opacity remains 1 after animation */
    `}
`;

const slideTop = keyframes`
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const slideBottom = keyframes`
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const AnimatedSlideTop = styled.img`
  height: 24rem;
  width: auto;
  position: absolute;
  top: -6rem;
  right: 12rem;
  transition: all 1s;
  animation: ${props => props.isVisible ? `${slideTop} 1s forwards` : ''};
`;

export const AnimatedSlideBottom = styled.img`
  height: 20rem;
  width: auto;
  position: absolute;
  top: 16rem;
  right: 20rem;
  transition: all 1s;
  animation: ${props => props.isVisible ? `${slideBottom} 1s forwards` : ''};
`;