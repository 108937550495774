import { useMediaQuery } from "@mui/material";
import React, { useEffect, useRef } from "react";
import "./styles/Loading.css";

const Loading = ({ onVideoEnd }) => {
  const mobile = useMediaQuery("(max-width:768px)");
  const videoRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
    const container = containerRef.current;

    const handleVideoEnd = () => {
      if (container) {
        container.classList.add('fade-out');
      }

      setTimeout(() => {
        if (onVideoEnd) onVideoEnd();
      }, 1000);
    };

    if (video) {
      video.addEventListener('ended', handleVideoEnd);
    }

    return () => {
      if (video) {
        video.removeEventListener('ended', handleVideoEnd);
      }
    };
  }, [onVideoEnd]);

  return (
    <div ref={containerRef} className="loading-container fixed flex items-center justify-center w-screen h-screen bg-gradient-to-t from-[#111E1A] to-[#233D35] z-[9999]">
      <video
        ref={videoRef}
        autoPlay
        muted
        className="h-full"
        id="loadingVideo"
        controls={false}
        playsInline
      >
        <source
          src={
            mobile
              ? "/files/video/MadeiraParks_animation_mobile.mp4"
              : "/files/video/MadeiraParks_animation.mp4"
          }
          type="video/mp4"
          style={{ pointerEvents: "none" }}
        />
      </video>
    </div>
  );
};

export default Loading;
