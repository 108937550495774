import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axiosConfig from "../../providers/axiosConfig";
import BackButton from "../../resources/components/general/BackButton";
import Wrapper from "../../resources/components/general/Wrapper";
import FooterHero from "../../resources/images/footer_banner_trees.svg";
import { TextureBackground } from "./styles/news_style";
import { CircularProgress } from "@mui/material";
import { SupportBackground } from "./styles/news_style";
import HeroBanner from "../../resources/components/general/HeroBanner";
import emptyImage from "../../resources/images/announcements/Warehouse.svg";

function Support() {
	const navigate = useNavigate();

	const [selectedSupport, setSelectedSupport] = useState(null);
	const [activeTab, setActiveTab] = useState("all_info");
	const [allSupports, setAllSupports] = useState([]);
	const [loading, setLoading] = useState(false);
	const [filteredSupports, setFilteredSupports] = useState([]);
	const [investmentDescription, setInvestmentDescription] = useState("");
	const [showSupportDescription, setShowSupportDescription] = useState(false);
	const [typology, setTypology] = useState(null);
	const [status, setStatus] = useState(null);

	function handleTabChange(newTab) {
		setActiveTab(newTab);
		if (newTab === "all_info") {
			setSelectedSupport(null);
			setShowSupportDescription(false);
			setTypology(null);
			setStatus(null);
		}
	}

	useEffect(() => {
		setLoading(true);
		axiosConfig
			.get("/investment-supports-visible")
			.then((res) => {
				console.log("Apoios recebidos:", res.data);
				if (Array.isArray(res.data) && res.data.length > 0) {
					setAllSupports(res.data);
					setFilteredSupports(res.data);
				} else {
					setAllSupports([]);
					setFilteredSupports([]);
				}
			})
			.catch((err) => {
				console.error("Erro ao buscar os incentivos:", err);
				setAllSupports([]);
				setFilteredSupports([]);
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	useEffect(() => {
		if (activeTab === "all_info") {
			let filtered = allSupports;
			if (typology !== null) {
				filtered = filtered.filter(
					(support) => support.typology_id === typology
				);
			}
			if (status !== null) {
				filtered = filtered.filter((support) => support.status === status);
			}
			setFilteredSupports(filtered);
		}
	}, [typology, status, allSupports, activeTab]);

	useEffect(() => {
		if (
			(activeTab === "incentives" ||
				activeTab === "finance" ||
				activeTab === "support") &&
			!selectedSupport &&
			allSupports.length > 0
		) {
			let defaultSupport = null;

			if (activeTab === "incentives") {
				defaultSupport = allSupports.find((s) =>
					s.items?.some((item) => item.category_id === 1)
				);
			} else if (activeTab === "finance") {
				defaultSupport = allSupports.find((s) =>
					s.items?.some((item) => item.category_id === 2)
				);
			} else if (activeTab === "support") {
				defaultSupport = allSupports.find((s) =>
					s.items?.some((item) => item.category_id === 3)
				);
			}

			if (defaultSupport) {
				setSelectedSupport(defaultSupport);
				const firstItem = defaultSupport.items?.[0];
				if (firstItem) {
					setInvestmentDescription(
						firstItem.description || "Nenhuma informação disponível."
					);
				}
			}
		}
	}, [activeTab, selectedSupport, allSupports]);

	return (
		<>
			<HeroBanner title="Apoio ao Investimento" />

			<TextureBackground className="relative z-[1] mt-20 md:mt-32">
				<Wrapper closeFull>
					{/* desktop */}
					{activeTab !== "all_info" && (
						<div className="hidden md:flex space-x-6">
							<button
								className={`px-6 py-2 font-semibold rounded-lg ${activeTab === "info"
									? "bg-[#2F5E42] text-white"
									: "text-gray-700 border border-gray-400"
									}`}
								onClick={() => handleTabChange("info")}
							>
								Informação de Apoio
							</button>
							<button
								className={`px-6 py-2 font-semibold rounded-lg ${activeTab === "incentives"
									? "bg-[#2F5E42] text-white"
									: "text-gray-700 border border-gray-400"
									}`}
								onClick={() => handleTabChange("incentives")}
							>
								Sistemas de Incentivos
							</button>
							<button
								className={`px-6 py-2 font-semibold rounded-lg ${activeTab === "finance"
									? "bg-[#2F5E42] text-white"
									: "text-gray-700 border border-gray-400"
									}`}
								onClick={() => handleTabChange("finance")}
							>
								Instrumentos Financeiros
							</button>
							<button
								className={`px-6 py-2 font-semibold rounded-lg ${activeTab === "support"
									? "bg-[#2F5E42] text-white"
									: "text-gray-700 border border-gray-400"
									}`}
								onClick={() => handleTabChange("support")}
							>
								Apoios Complementares
							</button>
						</div>
					)}
					{/* mobile */}
					{activeTab !== "all_info" && (
						<div className="grid grid-cols-2 gap-3 md:hidden text-sm ">
							<button
								className={`px-4 py-2 font-semibold rounded-lg ${activeTab === "info"
									? "bg-[#2F5E42] text-white"
									: "text-gray-700 border border-gray-400"
									}`}
								onClick={() => handleTabChange("info")}
							>
								Informação de Apoio
							</button>
							<button
								className={`px-4 py-2 font-semibold rounded-lg ${activeTab === "incentives"
									? "bg-[#2F5E42] text-white"
									: "text-gray-700 border border-gray-400"
									}`}
								onClick={() => handleTabChange("incentives")}
							>
								Sistemas de Incentivos
							</button>
							<button
								className={`px-4 py-2 font-semibold rounded-lg ${activeTab === "finance"
									? "bg-[#2F5E42] text-white"
									: "text-gray-700 border border-gray-400"
									}`}
								onClick={() => handleTabChange("finance")}
							>
								Instrumentos Financeiros
							</button>
							<button
								className={`px-4 py-2 font-semibold rounded-lg ${activeTab === "support"
									? "bg-[#2F5E42] text-white"
									: "text-gray-700 border border-gray-400"
									}`}
								onClick={() => handleTabChange("support")}
							>
								Apoios Complementares
							</button>
						</div>
					)}
				</Wrapper>
			</TextureBackground>

			{activeTab === "all_info" && (
				<>
					{filteredSupports.length > 0 && (
						<SupportBackground className="mb-8 relative z-[1] transition-opacity duration-500 flex flex-col mx-auto justify-items-center rounded-[12px] max-w-full">
							<p className="text-2xl mb-2">
								Conheça os apoios disponíveis para fazer o seu negócio crescer!
							</p>
							<p>
								Explore oportunidades para começar ou expandir a sua atividade
								nos Parques Empresariais da Madeira.
							</p>
						</SupportBackground>
					)}

					{loading ? (
						<div className="flex justify-center">
							<CircularProgress sx={{ color: "#2F5E42" }} />
						</div>
					) : (
						filteredSupports.length > 0 ? (
							<SupportBackground className="relative z-[1] transition-opacity duration-500 bg-[#546E63] flex flex-col mx-auto items-center justify-items-center rounded-[12px] max-w-full">
								<div className="w-10/12 h-full mx-auto">
									<div className="my-20">
										<div className="flex flex-col gap-3 mb-8 mx-auto 2xl:w-full">
											{filteredSupports.map((support, index) => (
												<div
													key={index}
													className="border border-white p-3 text-lg font-bold text-white rounded-lg bg-[#546E63] cursor-pointer hover:bg-[#2F5E42] hover:border-[#2F5E42] transition"
													onClick={() => {
														setSelectedSupport(support);
														const firstItem = support.items?.[0] || null;
														if (firstItem) {
															setInvestmentDescription(
																firstItem.description ||
																"Nenhuma informação disponível."
															);
														} else {
															setInvestmentDescription(
																"Nenhuma informação disponível."
															);
															setTypology(null);
															setShowSupportDescription(false);
														}
														setActiveTab("info");
													}}
												>
													{support.name}
												</div>
											))}
										</div>

										{selectedSupport && (
											<div className="bg-[#E0E4DC] p-6 border rounded-lg transition-all duration-500">
												<h2 className="text-3xl font-bold text-black mb-4">
													{selectedSupport.name}
												</h2>
												<h3 className="text-2xl font-semibold text-gray-800 mb-2">
													O que é?
												</h3>
												<p className="text-gray-800 leading-relaxed">
													{selectedSupport.description ||
														"Nenhuma informação disponível."}
												</p>
											</div>
										)}
									</div>
								</div>
							</SupportBackground>
						) : (
							<div className="mt-8 sm:mt-16">
								<p className="text-center text-black text-xl font-medium">
									Nenhum apoio disponível
								</p>
								<img
									src={emptyImage}
									alt="Sem anúncios"
									className="h-auto object-contain mb-4 py-10 mx-auto"
								/>
							</div>
						)
					)}
				</>
			)}

			<div
				className={`flex-row transition-all duration-700 ease-linear overflow-hidden ${activeTab === "info" ? "w-full opacity-100" : "w-0 opacity-0"
					}`}
			>
				{activeTab === "info" && selectedSupport && (
					<TextureBackground className="relative z-[1] transition-opacity duration-500 linear animate-fade-in">
						<Wrapper closeFull key="info">
							<div className="flex justify-center md:justify-start mt-7">
								<BackButton
									className="text-black relative"
									onClick={() => handleTabChange("all_info")}
								/>
							</div>

							{selectedSupport.name && (
								<h2 className="text-3xl font-bold text-[black] text-left my-6">
									{selectedSupport.name}
								</h2>
							)}

							<div className="bg-[#546E63] p-6 md:p-20 border rounded-lg my-10 mt-10 transition-all duration-500 linear">
								<h2 className="text-2xl md:text-3xl font-semibold text-white mb-4">
									O que é?
								</h2>
								<p className="text-white leading-relaxed">
									{selectedSupport.description ||
										"Nenhuma informação disponível."}
								</p>
							</div>
						</Wrapper>

						<button
							onClick={() => {
								window.open(
									selectedSupport.website_url,
									"_blank"
								);
							}}
							className="block mx-auto mt-6 my-10 px-4 py-2 border border-gray-700 hover:bg-[#546E63] hover:text-white transition-colors font-semibold rounded-lg"
						>
							Mais informações
						</button>
					</TextureBackground>
				)}
			</div>
			{(activeTab === "incentives" ||
				activeTab === "finance" ||
				activeTab === "support") && (
					<TextureBackground className="relative z-[1] transition-opacity duration-500 linear animate-fade-in my-6 mb-10">
						<Wrapper closeFull>
							<div className="flex justify-center md:justify-start mt-7">
								<BackButton
									className="text-black relative"
									onClick={() => handleTabChange("all_info")}
								/>
							</div>
							{selectedSupport?.name && (
								<h2 className="text-3xl font-bold text-black text-left md:text-center my-6">
									{selectedSupport.name}
								</h2>
							)}

							<div
								className={`bg-[#546E63] w-full md:p-6 border rounded-lg ml-0 md:ml-10 flex flex-col items-center justify-center md:flex-row transition-all duration-700
                ${showSupportDescription
										? "max-w-[900px] md:max-w-[1200px] min-h-[250px] md:flex-row"
										: "md:max-w-[350px] min-h-[200px] md:w-[320px] flex-col items-center justify-center"
									}`}
							>
								<div className=" md:w-[500px] p-6 md:p-10 bg-[#546E63] rounded-lg">
									{activeTab === "incentives" && (
										<>
											<h2 className="text-xl md:text-2xl font-semibold text-white mb-2 text-center">
												Tipologia
											</h2>
											<div className="flex flex-col space-y-3">
												<button
													className={`w-full px-4 py-3 border font-semibold rounded-full whitespace-nowrap transition-all
                          ${typology === 2
															? "bg-[#2F5E42] text-white border-[#2F5E42]"
															: "bg-white text-gray-700"
														}`}
													onClick={() => {
														setTypology(typology === 2 ? null : 2);
														setShowSupportDescription(true);
													}}
												>
													Apoios ao Investimento
												</button>
												<button
													className={`w-full px-4 py-3 border font-semibold rounded-full whitespace-nowrap transition-all
                          ${typology === 1
															? "bg-[#2F5E42] text-white border-[#2F5E42]"
															: "bg-white text-gray-700"
														}`}
													onClick={() => {
														setTypology(typology === 1 ? null : 1);
														setShowSupportDescription(true);
													}}
												>
													Apoios ao Funcionamento
												</button>
											</div>
										</>
									)}
									<h2 className="text-xl md:text-2xl font-semibold text-white mt-4 mb-2 text-center">
										Estado
									</h2>
									<div className="flex flex-col space-y-3">
										<button
											className={`w-full px-4 py-3 border font-semibold rounded-full transition-all
                      ${status === "open"
													? "bg-[#2F5E42] text-white border-[#2F5E42]"
													: "bg-white text-gray-700"
												}`}
											onClick={() => {
												setStatus(status === "open" ? null : "open");
												setShowSupportDescription(true);
											}}
										>
											Aberto
										</button>

										<button
											className={`w-full px-4 py-3 border font-semibold rounded-full transition-all
                      ${status === "closed"
													? "bg-[#2F5E42] text-white border-[#2F5E42]"
													: "bg-white text-gray-700"
												}`}
											onClick={() => {
												setStatus(status === "closed" ? null : "closed");
												setShowSupportDescription(true);
											}}
										>
											Fechado
										</button>
									</div>
								</div>
								<div
									className={`relative min-h-[300px] overflow-hidden transition-[width] duration-700 linear ${showSupportDescription
										? "w-full md:w-2/3 ml-6 md:ml-12"
										: "hidden"
										}`}
									style={{ height: showSupportDescription ? "auto" : "100px" }}
								>
									{showSupportDescription && (
										<div className="relative w-full md:w-2/3 min-h-[200px] overflow-hidden animate-fadeIn">
											<div className="w-full text-center">
												<h2 className="text-2xl font-bold text-white mb-4 relative -left-4">
													Apoio
												</h2>
											</div>

											{selectedSupport?.items
												?.filter((item) => {
													if (activeTab === "incentives") return item.category_id === 1;
													if (activeTab === "finance") return item.category_id === 2;
													if (activeTab === "support") return item.category_id === 3;
													return true;
												})
												?.filter((item) => {

													const matchesTypology =
														activeTab === "incentives"
															? typology === null || item.typology_id === typology
															: true;


													const matchesStatus = status === null || item.status === status;

													return matchesTypology && matchesStatus;
												}).length > 0 ? (
												selectedSupport?.items
													?.filter((item) => {
														if (activeTab === "incentives") return item.category_id === 1;
														if (activeTab === "finance") return item.category_id === 2;
														if (activeTab === "support") return item.category_id === 3;
														return true;
													})
													?.filter((item) => {
														const matchesTypology =
															activeTab === "incentives"
																? typology === null || item.typology_id === typology
																: true;

														const matchesStatus = status === null || item.status === status;

														return matchesTypology && matchesStatus;
													})
													.map((item, index) => {
														const truncatedDescription =
															item.description.length > 100
																? `${item.description.substring(0, 100)}...`
																: item.description;

														return (
															<div key={index} className="w-full text-left px-4 md:px-0">
																<h2 className="text-xl font-bold mb-0 text-white">{item.name}</h2>
																<p
																	className="text-white leading-relaxed text-left mb-5 md:text-left cursor-pointer"
																	onClick={() => {
																		navigate("/support/info", {
																			state: { selectedSupport: { ...item } },
																		});
																	}}
																>
																	{truncatedDescription}
																</p>
															</div>
														);
													})
											) : (
												<p className="text-white text-center text-lg relative -left-4">
													Nenhum apoio disponível.
												</p>
											)}

										</div>
									)}
								</div>
							</div>
						</Wrapper>
					</TextureBackground>
				)}

			<div className="relative w-full mt-20 md:mt-32 ">
				<img src={FooterHero} alt="Footer" className="w-full h-auto" />
				<div className="absolute inset-0 bg-gradient-to-b from-transparent from-80% via-transparent to-[#121E1A] h-1/2 top-1/2"></div>
			</div>
		</>
	);
}

export default Support;
