import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import "moment/locale/pt";

function EventCard({ secondary, big, data, events, related, hideImage }) {
  const stringLimit = 150;
  const navigate = useNavigate();

  let aboutText =
    data?.annex_about?.length > stringLimit
      ? data?.annex_about.slice(0, stringLimit) + "..."
      : data?.annex_about;

  function getParsedDate(dateString) {
    const date = moment(dateString);
    date.locale("pt");
    const formattedDate = date.format("DD.MM.YYYY");

    return formattedDate;
  }

  return (
    <div
      onClick={() => {
        navigate(`/events/show/${data.id}`, {
          state: { item: data, events_data: events },
        });
      }}
      className={`${big
        ? "md:max-h-[210px] lg:basis-[calc(50%_-_20px)] mt-5"
        : "basis-full my-2"
        } flex flex-col w-full cursor-pointer`}
    >
      <div className="flex flex-col w-full">
        <div className={`flex flex-col md:flex-row items-center md:items-start text-left md:text-left gap-3`}>
          <div className={`${big ? "w-[340px] h-[250px]" : "w-[200px] h-[200px]"} cursor-pointer `}>
            {!hideImage && (
              <img
                src={data?.photo_url}
                alt=""
                className="w-auto h-full object-cover"
                onClick={() =>
                  navigate(`/events/show/${data.id}`, {
                    state: { item: data, events_data: events },
                  })
                }
              />
            )}
          </div>
          <div className="flex flex-col justify-between w-full">
            <div>

              <p className="text-[#B1B1B1] text-sm font-medium mb-1">
                {data && data["event-dates"].length > 0
                  ? getParsedDate(data["event-dates"][0].date_time)
                  : getParsedDate(data?.created_at)}
              </p>
              <p
                className={` ${secondary ? "text-black" : "text-black"
                  } text-lg font-bold mt-2`}
              >
                {data?.name}
              </p>
              <p
                className={`${secondary ? "text-black" : "text-black"} 
                  font-normal leading-relaxed break-words max-w-sm`}
              >
                {aboutText}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventCard;
