import React from "react";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import moment from "moment";

import styled from "styled-components";

const ClampedText = styled.p`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #ffffff;
  border-top: 2px solid #ffffff;
  width: 100%;
  text-align: left;
`;

function AnnouncementCard({ additionalInfo, contract, data }) {
  let date = moment(data?.end_date);
  let finished_date_day = date.format("DD");
  let finished_date_rest = date.format("MMMM YYYY");

  return (
    <div className="h-full bg-[#2F5D42] rounded p-5 box-border flex flex-col justify-between w-full mx-auto">
      <div className="flex flex-col items-start gap-2 w-full">
        <h3 className="text-[#FFFFFF] text-xl">{data?.name}</h3>
        <ClampedText>{data?.title}</ClampedText>
      </div>
      <p className="text-lg text-[#363950] text-left">{additionalInfo}</p>
      {contract ? (
        <div className="flex flex-col md:flex-row justify-between mt-3">
          <div className="flex flex-col">
            <div className="flex">
              <p className="text-[#FFFFFF]">
                <span className="text-2xl whitespace-nowrap">
                  {finished_date_day + " " + finished_date_rest}
                </span>
              </p>
            </div>
            <p className="text-[#FFFFFF] text-left">
              {data?.country} , {data?.district}
            </p>
          </div>
          {data?.website_url && (
            <a
              className="text-right text-[#0267B6] text-md mt-5 self-end"
              href={data?.website_url}
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              clicar para mais informações <KeyboardArrowRightOutlinedIcon />
            </a>
          )}
        </div>
      ) : (
        <>
          {data?.website_url && (
            <a
              className="text-right text-[#0267B6] text-md mt-5 self-end"
              href={data?.website_url}
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              clicar para mais informações <KeyboardArrowRightOutlinedIcon />
            </a>
          )}
        </>
      )}
    </div>
  );
}

export default AnnouncementCard;
