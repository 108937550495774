import React, { useState, useEffect } from "react";
import {
  Wrapped,
  GridContainer,
  GridItems,
  DescriptionContainer,
} from "./styles/quest_styles";
import axiosConfig from "../../providers/axiosConfig";
import FooterHero from "../../resources/images/footer_banner_trees.svg";
import { useLocation } from "react-router-dom";
import HeroBanner from "../../resources/components/general/HeroBanner";
import { Alert, Snackbar, CircularProgress } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import emptyImage from "../../resources/images/announcements/Warehouse.svg";

function Quest() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [code, setCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [formVisible, setFormVisible] = useState(false);
  const [tokeninfo, settokeninfo] = useState("");
  const [formValues, setFormValues] = useState({});
  const location = useLocation();
  const [token, setToken] = useState("");
  // Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tokenParam = queryParams.get("token");
    if (tokenParam) {
      setToken(tokenParam);
      tokenUrl(tokenParam);
    }
  }, [location.search]);

  const openModal = () => {
    setIsModalOpen(true);
    setErrorMessage("");
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleInputChange = (e) => {
    setCode(e.target.value);
  };

  const tokenUrl = async (token) => {
    try {
      const res = await axiosConfig.get(`/forms/get-shared/${token}`);

      console.log("Response Data:", res.data);

      if (res.data) {
        console.log("Token is valid!");
        setFormVisible(true);
        setIsModalOpen(false);
        setErrorMessage("");
        settokeninfo(res.data);
        setSnackbarMessage("O Token está válido!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        setSnackbarMessage("O Token está válido!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      } else {
        console.log("Token is invalid", res.data);
        setErrorMessage("Código inválido!");
        setSnackbarMessage("Código inválido!");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        setSnackbarMessage("Código inválido!");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error validating the token:", error.message);
      setErrorMessage(error.message);
      setSnackbarMessage("Erro ao validar o token");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      setSnackbarMessage("Erro ao validar o token");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleButtonClick = async () => {
    try {
      const res = await axiosConfig.get(`/forms/get-shared/${code}`);

      console.log("Response Data:", res.data);

      if (res.data) {
        console.log("Token is valid!");
        setFormVisible(true);
        setIsModalOpen(false);
        setErrorMessage("");
        settokeninfo(res.data);
        setSnackbarMessage("O Token está válido!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      } else {
        console.log("Token is invalid", res.data);
        setErrorMessage("Código inválido!");
        setSnackbarMessage("Erro ao validar o token");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error validating the token:", error.message);
      setErrorMessage(error.message);
      setSnackbarMessage("Erro ao validar o token");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleformsubmit = (e, questionId) => {
    const value = e.target.value;
    setFormValues((prevState) => ({
      ...prevState,
      [questionId]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formattedAnswers = Object.entries(formValues).map(
        ([questionId, answer]) => ({
          form_question_id: questionId,
          answer: Array.isArray(answer) ? answer.join(", ") : answer,
        })
      );

      const requestData = {
        answers_data: formattedAnswers,
        token: code,
        dynamic_form_id: tokeninfo.id,
      };

      const response = await axiosConfig.post("/form-submit", requestData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200 || response.status === 201) {
        console.log("Form submitted successfully", response.data);
        setSnackbarMessage("Formulário submetido com sucesso");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        setFormValues({});
        setFormVisible(false);
      } else {
        console.error("Form submission failed");
        setSnackbarMessage("Erro ao submeter o formulário");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
      alert(error.message);
      setSnackbarMessage("Erro ao submeter o formulário");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const [loading, setLoading] = useState(false);
  const [forms, setForms] = useState([]);

  useEffect(() => {
    setLoading(true);
    axiosConfig
      .get(`/forms/results`)
      .then((res) => {
        setForms(res.data || []);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, []);

  return (
    <>
      <Snackbar
        open={snackbarOpen}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        message={snackbarMessage}
        autoHideDuration={5000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert variant="filled" severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <HeroBanner title="Questionários" />

			{/* Feedback View */}
			{!formVisible && (
				<Wrapped className="mt-20 md:mt-32">
					<div className="flex justify-center sm:justify-end">
						<div className="text-center bg-[#2F5E42] text-white rounded-md py-2 px-6 sm:mb-0 mb-10">
							<button onClick={openModal}>Responder a Questionário</button>
						</div>
					</div>
					{forms.filter(forms => forms.validated === true).length > 0 && (
						<>
							<div>
								<p className="text-3xl text-[#193441]">Feedback dos clientes</p>
							</div>
						</>
					)}




					{forms.filter(forms => forms.validated === true).length === 0 ? (
						<div className="mt-3 flex flex-col justify-center items-center py-12 text-lg text-gray-700">
							<div className="mt-32 flex flex-col">
								<p className="text-center text-black text-xl font-medium mt-[-100px]">
									Sem questionários de momento!<br />Novidades em breve
								</p>
								<img src={emptyImage} alt="Sem questionários" className="h-[50vh] object-contain mb-4 py-10" />
							</div>
						</div>
					) : (
						<GridContainer className="grid-container">
							{forms
								.filter(forms => forms.validated === true)
								.map(formsresult => (
									<GridItems className="mt-10" key={formsresult.id}>
										<p className="text-base text-[#3E606F]">{formsresult.open_date} / {formsresult.close_date}</p>
										<p className="text-xl text-[#193441]">Questionário {formsresult.id}</p>
										<DescriptionContainer>
											<p className="text-lg text-[#1D2224]">{formsresult.description}</p>
										</DescriptionContainer>
										<button className="border-2 border-[#121E1A] text-[#121E1A] rounded"
											onClick={() => window.open(formsresult.results_document_url, "_blank")}>
											<p className="text-semibold">Resultados</p>
										</button>
									</GridItems>
								))
							}
						</GridContainer>
					)}
				</Wrapped>
			)}

      {/* Popup Modal for Code Input */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-20">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-lg font-medium mb-6">
              Insira o código para responder ao questionário
            </h2>
            <input
              type="text"
              value={code}
              onChange={handleInputChange}
              className="border p-2 w-full rounded-md bg-[#D9D9D9]"
              placeholder="Digite o código"
              required={true}
            />
            {errorMessage && (
              <p className="text-red-500 mb-3 text-base">{errorMessage}</p>
            )}
            <div className="flex justify-between mt-6">
              <button
                className="bg-gray-500 text-white px-4 py-2 rounded"
                onClick={closeModal}
              >
                Cancelar
              </button>
              <button
                className="bg-[#2F5E42] text-white px-4 py-2 rounded"
                onClick={handleButtonClick}
                disabled={!code}
                style={{
                  backgroundColor: !code ? "grey" : "#2F5E42",
                }}
              >
                Iniciar Questionário
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Form Section - Only visible when token is validated */}
      {formVisible && (
        <Wrapped>
          <div className="mt-5 ">
            <h2 className="text-3xl text-center mt-16 mb-10 font-bold">
              {tokeninfo.name}
            </h2>
            {tokeninfo.image_path && (
              <div className=" w-full h-[400px] justify-center flex ">
                <img
                  src={tokeninfo.image_path}
                  alt="Token Image"
                  className="max-h-[400px]"
                />
              </div>
            )}
            <div className="text-base mt-10">
              <p style={{ display: "inline" }}>
                {new Date(tokeninfo.open_date).toLocaleDateString("pt-PT")}
              </p>
              <span style={{ display: "inline" }}> - </span>
              <p style={{ display: "inline" }}>
                {new Date(tokeninfo.close_date).toLocaleDateString("pt-PT")}
              </p>
            </div>
            <div>
              <p className="text-bold text-red-500 text-md">
                {tokeninfo.is_anonymous
                  ? "Este formulário é anónimo. As suas respostas serão mantidas em sigilo."
                  : "Este formulário não é anónimo. As suas respostas serão associadas à sua identidade."}
              </p>
            </div>
            <div className="text-xl mt-10">
              <p>{tokeninfo.description}</p>
            </div>
            <form onSubmit={handleSubmit}>
              {tokeninfo.questions.map((question) => (
                <div key={question.id} className="mt-9">
                  <label htmlFor={`question-${question.id}`} className="block">
                    {question.question_number}.{question.question}
                    {question.mandatory && (
                      <span className="text-red-500 ml-1">*</span>
                    )}
                  </label>

                  {question.type === "open" && (
                    <textarea
                      id={`question-${question.id}`}
                      className="border p-2 rounded w-full bg-[#D9D9D9] h-60 text-black"
                      value={formValues[question.id] || ""}
                      onChange={(e) => handleformsubmit(e, question.id)}
                      required={question.mandatory}
                      onInvalid={(e) =>
                        e.target.setCustomValidity(
                          "Questão de resposta obrigatória."
                        )
                      }
                      onInput={(e) => e.target.setCustomValidity("")}
                    />
                  )}

                  {question.type === "multiple_choice_one" && (
                    <div>
                      {question.options.map((option, index) => (
                        <label key={index} className="block">
                          <input
                            type="radio"
                            name={`question-${question.id}`}
                            value={option}
                            checked={formValues[question.id] === option}
                            onChange={(e) => handleformsubmit(e, question.id)}
                            className="mr-2"
                            required={question.mandatory}
                            onInvalid={(e) =>
                              e.target.setCustomValidity(
                                "Questão de resposta obrigatória."
                              )
                            }
                            onInput={(e) => e.target.setCustomValidity("")}
                          />
                          {option}
                        </label>
                      ))}
                    </div>
                  )}

                  {question.type === "multiple_choice_multiple" && (
                    <div>
                      {question.options.map((option, index) => (
                        <label key={index} className="block">
                          <input
                            type="checkbox"
                            value={option}
                            checked={formValues[question.id]?.includes(option)}
                            onChange={(e) => {
                              const updatedValues = [
                                ...(formValues[question.id] || []),
                              ];
                              if (e.target.checked) {
                                updatedValues.push(option);
                              } else {
                                const optionIndex =
                                  updatedValues.indexOf(option);
                                updatedValues.splice(optionIndex, 1);
                              }
                              setFormValues((prevState) => ({
                                ...prevState,
                                [question.id]: updatedValues,
                              }));
                            }}
                            className="mr-2"
                          />
                          {option}
                        </label>
                      ))}
                    </div>
                  )}

                  {question.type === "date_time" && (
                    <input
                      type="datetime-local"
                      id={`question-${question.id}`}
                      className="border p-2 rounded w-[20%] bg-[#D9D9D9]"
                      value={formValues[question.id] || ""}
                      onChange={(e) => handleformsubmit(e, question.id)}
                      required={question.mandatory}
                      onInvalid={(e) =>
                        e.target.setCustomValidity(
                          "Questão de resposta obrigatória."
                        )
                      }
                      onInput={(e) => e.target.setCustomValidity("")}
                    />
                  )}
                </div>
              ))}
              <div className="flex justify-end items-end mb-16">
                <button
                  type="submit"
                  className="mt-4 bg-[#2F5E42] text-white px-6 py-3 rounded-[10px] flex items-center text-lg"
                >
                  Submeter
                  <ArrowForwardIosIcon className="ml-2 text-white text-xl" />
                </button>
              </div>
            </form>
          </div>
        </Wrapped>
      )}

      <div className="relative w-full mt-20 md:mt-32">
        <img src={FooterHero} alt="Footer" className="w-full h-auto" />
        <div className="absolute inset-0 bg-gradient-to-b from-transparent from-80% via-transparent to-[#121E1A] h-1/2 top-1/2"></div>
      </div>
    </>
  );
}

export default Quest;
