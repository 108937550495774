import React from "react";
import Wrapper from "../../resources/components/general/Wrapper";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import styled from "@emotion/styled";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";

const CustomAccordionDetails = styled(AccordionDetails)`
  > p {
    margin-bottom: 20px;
    font-size: 16px;
    color: #3b3d4b;
    line-height: 27px;
  }
`;

const CustomSummary = styled(AccordionSummary)`
  color: #1c6c5e;
  font-size: 18px;
  font-weight: 600;
`;

const CustomSubTitle = styled("div")`
  font-weight: 400;
  h3 {
    color: #1c6c5e;
    font-size: 24px;
  }
  p {
    color: #3b3d4b;
    font-size: 18px;
    line-height: 30px;
  }
`;
const CustomAccordion = styled(Accordion)`
  box-shadow: none;
  border-radius: 8px;
  background-color : #F5F5F5;
  &::before {
    background-color: transparent;
    
  }
`;

function Privacy() {
  const navigate = useNavigate();

  return (
    <>
      <Wrapper closeFull>
        <div className="flex flex-col md:flex-row h-full gap-10 md:gap-20 justify-between mt-[100px] md:mt-[150px]">
          <div className="w-full flex flex-col gap-3 md:gap-10 pt-5">
            <p
              className="font-semibold uppercase text-sm w-10/12 text-[#20452E] md:whitespace-nowrap cursor-pointer"
              onClick={() => navigate("/privacy")}
            >
              Política de Privacidade
            </p>
            <p
              className="font-semibold uppercase text-sm w-10/12 text-[#20452E]  md:whitespace-nowrap opacity-50 cursor-pointer"
              onClick={() => navigate("/terms")}
            >
              Termos de serviços
            </p>
          </div>
          <div className="flex flex-col gap-3 pt-5 pb-10">
            <h2 className="font-semibold uppercase text-4xl w-10/12 text-[#20452E]">
              Política de Privacidade
            </h2>
            <p className="font-semibold uppercase text-sm w-10/12 text-[#20452E]">
              Atualizado a 19 de março de 2025
            </p>

            <CustomSubTitle className="mt-5 flex flex-col gap-5">
              <h3 className="font-normal text-2xl w-10/12">
                MPE - Declaração de compromisso com a privacidade e proteção
                de dados pessoais
              </h3>
              <p className="text-lg font-medium mb-10">
                A presente Declaração de Privacidade faz parte integrante dos Termos de Utilização
                do website WWW.MPE.PT e regula a eventual recolha e tratamento, designadamente o
                consentimento, relativamente a dados pessoais recolhidos ou fornecidos pelos
                utilizadores em qualquer parte do website, assim como o exercício dos seus direitos
                relativamente a estes dados, nos termos do Regulamento Geral da Proteção de Dados
                (Regulamento 2016/679 do Parlamento Europeu e do Conselho, de 27 de abril de
                2016).
              </p>
            </CustomSubTitle>
            <CustomAccordion className="z-[1]">
              <CustomSummary
                expandIcon={<ExpandMoreIcon style={{ fill: "#20452E" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <h3>Política Geral de Segurança e Privacidade</h3>
              </CustomSummary>
              <CustomAccordionDetails>
                <p>
                  A proteção da privacidade e dos dados pessoais é uma
                  prioridade para a MPE – Madeira Parques Empresariais Sociedade Gestora, S.A,
                  e um claro compromisso assumido por nós.
                </p>
                <p>
                  Esta política diz respeito às práticas de privacidade no
                  âmbito do exercício da atividade da MPE incluindo os
                  portais, sítios online e outros serviços geridos e
                  utilizados pela mesma. Os dados pessoais que nos forneça
                  durante a interação com os vários serviços serão tratados
                  com as garantias de segurança e confidencialidade exigidas
                  pelo enquadramento legal relativo à proteção de dados
                  pessoais.
                </p>
                <p>
                  Os portais, sítios e aplicações sob a responsabilidade e
                  utilizados pela MPE, no âmbito dos serviços
                  disponibilizados, contêm links para outros sítios sob a
                  responsabilidade de entidades terceiras. A política de
                  privacidade da MPE aplica-se exclusivamente aos sítios e
                  serviços sob sua responsabilidade. Quando acede a sítios de
                  outras entidades, ainda que a partir dos sítios sob
                  responsabilidade da MPE, deverá ler as respetivas
                  políticas de privacidade.
                </p>
                <p>
                  O acesso e a utilização dos serviços, portais e sítios, sob
                  a responsabilidade da MPE, devem ser livres por parte do
                  utilizador presumindo a MPE que este leu, compreendeu e
                  aceitou os Termos e Condições da sua utilização
                </p>
                <p>
                  Quaisquer tentativas de alterar a informação, ou qualquer
                  outra ação que possa causar dano e pôr em risco a
                  integridade do sistema, são estritamente proibidas de acordo
                  com a legislação em vigor. O utilizador obriga-se a cumprir
                  escrupulosamente a legislação aplicável, nomeadamente, em
                  matéria de criminalidade informática e de direitos de
                  propriedade intelectual, sendo exclusivamente responsável
                  pela infração destes normativos
                </p>
              </CustomAccordionDetails>
            </CustomAccordion>

            {/*             <CustomAccordion className="z-[1]">
              <CustomSummary
                expandIcon={<ExpandMoreIcon style={{ fill: "#20452E" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <h3>Encarregado de Proteção de Dados</h3>
              </CustomSummary>
              <CustomAccordionDetails>
                <p>
                  A MPE designou um Encarregado de Proteção de Dados que
                  poderá ser contactado através do endereço eletrónico{" "}
                  <a
                    href="mailto:rgpd@mpe.pt"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    rgpd@mpe.pt
                  </a>{" "}
                  ou por carta registada com aviso de receção para  Rua do Bispo, 16 – 2.º andar. Sala 24 | 9000-073 Funchal .
                </p>
              </CustomAccordionDetails>
            </CustomAccordion> */}

            <CustomAccordion className="z-[1]">
              <CustomSummary
                expandIcon={<ExpandMoreIcon style={{ fill: "#20452E" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <h3>Privacidade</h3>
              </CustomSummary>
              <CustomAccordionDetails>
                <p>
                  A MPE respeita o seu direito à privacidade. Os dados
                  solicitados destinam-se à prestação do serviço por si
                  solicitado, não sendo recolhidos dados pessoais que não
                  sejam necessários à prestação do serviço ou sem o seu
                  consentimento.
                </p>
                <p>
                  A interação com os serviços, portais e sítios da MPE não
                  exige informação do utilizador, salvo quando, para
                  propósitos expressamente identificados, o registo seja
                  declarado necessário e seja por aquele autorizado de acordo
                  com os termos seguintes
                </p>
              </CustomAccordionDetails>
            </CustomAccordion>

            <CustomAccordion className="z-[1]">
              <CustomSummary
                expandIcon={<ExpandMoreIcon style={{ fill: "#20452E" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <h3>Responsável pelo Tratamento</h3>
              </CustomSummary>
              <CustomAccordionDetails>
                <p>
                  A entidade responsável pela recolha e tratamento dos dados pessoais é a MPE que,
                  no contexto da sua atividade, decide quais os dados recolhidos, os meios de
                  tratamento dos dados e para que finalidades são utilizados.
                </p>
              </CustomAccordionDetails>
            </CustomAccordion>

            <CustomAccordion className="z-[1]">
              <CustomSummary
                expandIcon={<ExpandMoreIcon style={{ fill: "#20452E" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <h3>Tipologia de Dados Pessoais que recolhemos</h3>
              </CustomSummary>
              <CustomAccordionDetails>
                <p>
                  A MPE, no âmbito da sua atividade, procede à recolha e ao tratamento dos dados
                  pessoais necessários ao preenchimento do formulário de inscrição para futuras
                  instalações nos parques empresariais, tratando nesse âmbito dados como o nome, o
                  número de telefone e o endereço de correio eletrónico.

                </p>
                <p>
                  Os dados pessoais recolhidos são tratados informaticamente e no estrito cumprimento
                  da legislação de proteção de dados pessoais, sendo armazenados em base de dados
                  específicas, criadas para o efeito e que garantem a sua segurança e confidencialidade
                  de acordo com os de tecnologia e informação aceites, por forma a proteger da perda,
                  alteração, destruição, uso indevido em geral ou que os dados recolhidos serão
                  utilizados para outra finalidade que não seja aquela para a qual foi dado o
                  consentimento por parte do titular dos dados.
                </p>

              </CustomAccordionDetails>
            </CustomAccordion>

            <CustomAccordion className="z-[1]">
              <CustomSummary
                expandIcon={<ExpandMoreIcon style={{ fill: "#20452E" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <h3>Segurança de Dados Pessoais</h3>
              </CustomSummary>
              <CustomAccordionDetails>
                <p>
                  A MPE, na prossecução das suas atividades, utiliza um
                  conjunto de tecnologias e procedimentos de segurança
                  adequados à proteção dos seus dados pessoais, protegendo o
                  acesso ou divulgação não autorizados, nomeadamente:
                  <ul className="list-disc ml-5 my-5 flex flex-col gap-5">
                    <li>
                      Medidas de segurança física, como seja, o controlo de
                      acessos de funcionários, colaboradores e visitantes às
                      instalações da sede e, sobre as instalações do Data
                      center, mecanismos muito restritos de combate à
                      intrusão, extinção de incêndios, monitorização de
                      equipamentos 24×7 e alojamento de equipamentos em
                      bastidores dedicados;
                    </li>
                    <li>
                      Medidas de segurança lógica, na componente de acessos a
                      sistemas e postos de trabalho através de mecanismos de
                      gestão de identidades, autenticação e privilégios; na
                      componente de rede o uso de firewalls e sistemas de
                      deteção de intrusão, segregando redes e ambientes
                      aplicacionais, bem como, cifragem de informação através
                      de canais de comunicação seguros
                    </li>
                  </ul>
                </p>
              </CustomAccordionDetails>
            </CustomAccordion>

            <CustomAccordion className="z-[1]">
              <CustomSummary
                expandIcon={<ExpandMoreIcon style={{ fill: "#20452E" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <h3>Acesso e controlo dos seus Dados Pessoais</h3>
              </CustomSummary>
              <CustomAccordionDetails>
                <p>
                  Qualquer pedido de acesso, atualização, retificação, oposição, limitação, remoção,
                  portabilidade ou não sujeição a decisões individuais automatizadas dos dados
                  pessoais suscetíveis de o identificar, pode ser requerido por contacto direto com a
                  MPE.

                </p>
                <p>
                  Caso a utilização dos seus dados pessoais seja baseada no
                  consentimento, tem o direito de o retirar, sem comprometer a
                  validade do tratamento de dados efetuado até esse momento.
                </p>
                <p>
                  Através do encarregado de proteção de dados, que poderá ser
                  contactado através do endereço eletrónico{" "}
                  <a
                    href="mailto:rgpd@mpe.pt"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    rgpd@mpe.pt
                  </a>{" "}
                  ou por carta registada com aviso de receção para Rua do Bispo, 16 – 2.º andar.
                  Sala 24 | 9000-073 Funchal.
                  <ul className="list-disc ml-5 my-5 flex flex-col gap-5">
                    <li>Informação acerca dos seus dados;</li>
                    <li>Informação acerca do processamento;</li>
                    <li>A finalidade subjacente ao tratamento;</li>
                    <li>A correção, ou atualização, de dados</li>
                    <li>Os seus dados num formato aberto;</li>
                    <li>
                      O apagamento dos dados, sem prejuízo da legislação
                      aplicável ao tratamento em causa.
                    </li>
                  </ul>
                </p>
              </CustomAccordionDetails>
            </CustomAccordion>

            <CustomAccordion className="z-[1]">
              <CustomSummary
                expandIcon={<ExpandMoreIcon style={{ fill: "#20452E" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <h3>Arquivo de Dados Pessoais</h3>
              </CustomSummary>
              <CustomAccordionDetails>
                <p>
                  Os seus dados pessoais são conservados pela MPE pelo
                  período necessário ao tratamento, de acordo com a finalidade
                  respetiva.
                </p>
                <p>
                  Os períodos de conservação podem alterar-se quando o
                  interesse público associado, motivos históricos, científicos
                  ou estatísticos o justifiquem, comprometendo-se a MPE a
                  adotar as adequadas medidas de conservação e segurança.
                </p>
              </CustomAccordionDetails>
            </CustomAccordion>
            <CustomAccordion className="z-[1]">
              <CustomSummary
                expandIcon={<ExpandMoreIcon style={{ fill: "#20452E" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <h3>Exoneração de Responsabilidade</h3>
              </CustomSummary>
              <CustomAccordionDetails>
                <p>
                  A MPE não poderá ser responsabilizada por quaisquer
                  prejuízos ou danos em sede de responsabilidade civil
                  (incluindo, mas sem que a estes estejam limitados, danos
                  emergentes, lucros cessantes e danos morais, causados direta
                  ou indiretamente), que surjam em consequência da utilização,
                  correta ou incorreta dos seus Serviços, sítios eletrónicos e
                  respetivos conteúdos por parte do utilizador, incluindo o
                  acesso indevido ao computador e sistema informático do
                  utilizador por terceiros.
                </p>
                <p>
                  A informação divulgada deve ser encarada na sua vertente
                  informativa.
                </p>
                <p>
                  Nos serviços, sítios e portais geridos pela MPE existem
                  conteúdos e serviços disponibilizados por entidades
                  terceiras, cabendo a sua atualização a essas entidades.
                  Deste modo, a MPE não poderá ser responsabilizada no que
                  respeita à completa exatidão e atualidade de qualquer
                  informação constante dos seus sítios.
                </p>
                <p>
                  Os sítios eletrónicos podem conter ligações para sítios
                  operados por entidades terceiras sobre os quais não tem
                  controlo e pelos quais não assume qualquer responsabilidade.
                </p>
                <p>
                  A visualização de disposições legais neste sítio, não
                  dispensa a consulta das normas legais em vigor, aprovadas
                  oficialmente, publicadas nas edições e suportes originais
                  (nomeadamente o Diário da República ou o Jornal Oficial da
                  União Europeia).
                </p>
              </CustomAccordionDetails>
            </CustomAccordion>
            <CustomAccordion className="z-[1]">
              <CustomSummary
                expandIcon={<ExpandMoreIcon style={{ fill: "#20452E" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <h3>Alteração à política de segurança e privacidade</h3>
              </CustomSummary>
              <CustomAccordionDetails>
                <p>
                  A MPE reserva-se o direito de a todo o momento e sem aviso prévio e com efeitos
                  imediatos, mas sem prejuízo dos direitos legais conferidos aos Titulares dos Dados,
                  alterar, acrescentar ou revogar, parcial ou totalmente, a presente Declaração de
                  Privacidade. Quaisquer alterações serão imediatamente divulgadas nesta mesma
                  página web, pelo que recomendamos a visita periódica a esta página para ficar a par
                  da versão mais recente.
                </p>
                <p>
                  Se tiver quaisquer dúvidas ou questões sobre a forma como a MPE procede ao
                  tratamento dos seus dados pessoais, por favor envie-nos o seu pedido de
                  esclarecimento através do email rgpd@mpe.pt ou para a morada da MPE.
                </p>

              </CustomAccordionDetails>
            </CustomAccordion>
            <CustomAccordion className="z-[1]">
              <CustomSummary
                expandIcon={<ExpandMoreIcon style={{ fill: "#20452E" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <h3>Lei aplicável e foro competente</h3>
              </CustomSummary>
              <CustomAccordionDetails>
                <p>
                  Os presentes Termos e Condições são regidos e interpretados
                  de acordo com a lei portuguesa. <br />É competente o
                  tribunal da área de Lisboa, com exclusão de qualquer outro,
                  para dirimir quaisquer conflitos que resultem da
                  interpretação e aplicação dos presentes Termos e Condições
                </p>
              </CustomAccordionDetails>
            </CustomAccordion>
            <div className="my-5 flex flex-col gap-5">
              <h3 className="font-normal text-2xl w-10/12">
                Política de Cookies
              </h3>
            </div>

            <CustomAccordion className="z-[1]">
              <CustomSummary
                expandIcon={<ExpandMoreIcon style={{ fill: "#20452E" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <h3>Cookies e Tecnologias Semelhantes</h3>
              </CustomSummary>
              <CustomAccordionDetails>
                <p>
                  A MPE utiliza nos seus sítios eletrónicos cookies e outras
                  tecnologias semelhantes que permitem melhorar o desempenho e
                  a experiência de navegação dos seus Utilizadores,
                  aumentando, por um lado, a rapidez e eficiência de resposta
                  e, por outro, eliminando a necessidade de introduzir
                  repetidamente as mesmas informações. A colocação de cookies
                  ajuda os sítios eletrónicos a reconhecer o dispositivo do
                  Utilizador na visita seguinte sendo com frequência
                  imprescindível para o funcionamento do mesmo.
                </p>
                <p>
                  Os cookies usados pela MPE não recolhem informações
                  pessoais que permitam identificar o Utilizador, guardando
                  apenas informações genéricas, designadamente a forma ou
                  região/cidade de acesso, entre outros.
                </p>
                <p>
                  Os cookies utilizados nos sítios e portais da MPE são os
                  seguintes:
                  <ul className="list-disc ml-5 my-5 flex flex-col gap-5">
                    <li>
                      Cookies primários – são os cookies definidos pelo sítio
                      web ou plataforma digital que o utilizador esteja a visitar
                      e só esse domínio terá acesso às informações deles retiradas.
                    </li>
                    <li>Cookies terceiros – são os cookies definidos por um domínio
                      diferente daquele que administra o sítio web ou plataforma
                      digital que o utilizador esteja a visitar, embora use esse
                      sítio web ou plataforma para definir um cookie.
                    </li>
                    <p>No caso dos sítios web, portais e plataformas digitais da MPE,
                      serão apenas utilizados cookies primários
                    </p>
                    <li>
                      Cookies persistentes – são os cookies que permanecem no dispositivo do
                      utilizado por um período de tempo específico e são ativados por cada vez
                      que o utilizador visita o sítio web, portal ou plataforma digital que inseriu
                      esse cookie específico.
                    </li>
                    <li>
                      Cookies de sessão – são os cookies que permitem que os administradores do sítio web,
                      portal ou plataforma digitais vinculem as ações do utilizador durante uma sessão do
                      navegador (a sessão tem início no momento em que o utilizador abre a janela do navegador
                      e termina quando fecha essa janela). Estes cookies são criados temporariamente e, ao fechar
                      o navegador, todos são excluídos do dispositivo móvel ou computador do utilizador.
                    </li>
                    <li>
                      Facebook, Twitter, YouTube, Instagram, Google Play, App
                      Store e outros websites geridos por terceiros, podem
                      instalar cookies para fins de partilha de conteúdos. O
                      número e o estatuto destes cookies podem depender da
                      forma de utilização das plataformas em questão antes ou
                      durante uma visita a este website. É útil verificar qual
                      a política de gestão dos cookies desses websites. A
                      informação gerada pela utilização dos cookies pode ser
                      enviada para terceiros para análise de tendências,
                      rastreio da navegação e desempenho dos mesmos, sem,
                      contudo, identificar qualquer pessoa.
                    </li>
                    <p>
                      Para informações adicionais sobre a presente política, o utilizador poderá contactar
                      a Direção Regional de Informática através do Endereço do email:
                      <a
                        href="mailto:dri@madeira.gov.pt"
                        className="text-blue-600 underline hover:text-blue-800 transition-colors"
                      >
                        dri@madeira.gov.pt
                      </a>
                    </p>

                  </ul>
                </p>
              </CustomAccordionDetails>
            </CustomAccordion>

            <CustomAccordion className="z-[1]">
              <CustomSummary
                expandIcon={<ExpandMoreIcon style={{ fill: "#20452E" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <h3>Como controlar o uso de Cookies?</h3>
              </CustomSummary>
              <CustomAccordionDetails>
                <p>
                  Os utilizadores podem impedir o uso de cookies nas
                  configurações do navegador. Se limitar esta opção, no
                  entanto, a utilização de alguns recursos ou o desempenho de
                  determinadas ferramentas pode ficar condicionada.
                </p>
                <p>
                  É ainda possível usar extensões dos navegadores (browser
                  add-on’s) para desativar a utilização de algumas funções. A
                  Google disponibiliza uma extensão para Google Analytics que
                  permite desativar a sua utilização. Está disponível em{" "}
                  <a
                    href="https://tools.google.com/dlpage/gaoptout/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://tools.google.com/dlpage/gaoptout/
                  </a>
                  <br />
                  <br />
                  <li>
                    <span className="font-semibold">
                      É possível configurar o navegador para:
                    </span>
                    <ol className="list-decimal ml-10">
                      <li>Eliminar cookies;</li>
                      <li>Bloquear cookies por predefinição;</li>
                      <li>Permitir cookies por predefinição;</li>
                      <li>
                        Manter cookies e dados do website por predefinição
                        até sair do navegador;
                      </li>
                      <li>
                        Criar exceções para cookies de websites ou domínios
                        específicos.
                      </li>
                    </ol>
                  </li>
                  <span className="font-semibold">
                    Como gerir os Cookies nos diversos navegadores:
                  </span>
                  <ul className="ml-5 my-5 flex flex-col gap-5">
                    <li>
                      <span className="font-semibold">Google Chrome:</span>
                      <p className="text-base">
                        Por defeito, são permitidos todos os cookies, mas pode
                        ajustar esta definição. Siga estes passos: Clique no
                        menu do Chrome na barra de ferramentas do navegador.
                      </p>
                      <ol className="list-decimal ml-10">
                        <li>Definições;</li>
                        <li>Mostrar definições avançadas;</li>
                        <li>
                          Em “Privacidade”, clique em Definições de conteúdo;
                        </li>
                        <li>
                          Selecione “Bloquear definição dos dados por parte
                          dos sites”
                        </li>
                      </ol>
                    </li>
                    <li>
                      <span className="font-semibold">
                        Internet Explorer:
                      </span>
                      <ol className="list-decimal ml-10">
                        <li>
                          Selecione o menu “Ferramentas” e a opção “Opções de
                          Internet”;
                        </li>
                        <li>Separador “Privacidade”;</li>
                        <li>
                          Mova a barra para o topo, onde surgirá a mensagem
                          “Bloquear todos os cookies”.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <span className="font-semibold">Mozilla Firefox:</span>
                      <ol className="list-decimal ml-10">
                        <li>Selecione o menu “Ferramentas”;</li>
                        <li>Selecione “Opções”;</li>
                        <li>
                          “Privacidade”, que encontra no painel superior;
                        </li>
                        <li>
                          Na secção cookies desative a opção “Aceitar cookies
                          dos sites”. Clique em “OK” para guardar as
                          alterações e fechar.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <span className="font-semibold">Safari:</span>
                      <ol className="list-decimal ml-10">
                        <li>Selecione “Editar”;</li>
                        <li>. Clique em “Preferências”;</li>
                        <li>
                          No painel superior, selecione o ícone “Privacidade”;
                        </li>
                        <li>
                          Na secção “Aceitar cookies”, selecione “Nunca”.
                        </li>
                      </ol>
                    </li>
                  </ul>
                </p>
              </CustomAccordionDetails>
            </CustomAccordion>

          </div>
        </div>
      </Wrapper>
    </>
  );
}

export default Privacy;
