import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Wrapper from "../../resources/components/general/Wrapper";
import BackButton from "../../resources/components/general/BackButton";
import { TextureBackground } from "./styles/news_style";
import HeroBanner from "../../resources/components/general/HeroBanner";
import FooterHero from "../../resources/images/footer_banner_trees.svg";

function SupportInfo() {
    const location = useLocation();
    const navigate = useNavigate();
    const selectedSupport = location.state?.selectedSupport || null;

    return (
        <>

            <HeroBanner title="Detalhes do apoio" />

            <TextureBackground className="relative z-[1] transition-opacity duration-500 linear animate-fade-in">
                <Wrapper closeFull>
                    <div className="flex justify-start mt-7">
                        <BackButton className="text-black relative" onClick={() => navigate(-1)} />
                    </div>

                    {selectedSupport ? (
                        <>
                            <h2 className="text-3xl font-bold text-[black] text-left my-6">
                                {selectedSupport.name}
                            </h2>

                            <div className="bg-[#546E63] p-6 md:p-20 border rounded-lg my-10 mt-10 transition-all duration-500 linear flex flex-col md:flex-row">

                                <div className="w-full md:w-2/3">
                                    <h2 className="text-2xl md:text-3xl font-semibold text-white mb-4">
                                        Objetivo
                                    </h2>
                                    <p className="text-white leading-relaxed">
                                        {selectedSupport.description || "Nenhuma informação disponível."}
                                    </p>

                                    <button
                                        onClick={() => {
                                            window.open("https://ide.madeira.gov.pt/Content/PaginasPublicas/Servicos-IDE-apoios-2021-2027-Sistemas-Incentivos-Investimento-MAIS-DIGITAL2030","_blank");
                                                
                                        }}
                                        className="block mx-auto mt-6 my-10 px-4 py-2 border text-white border-white hover:bg-[#2F5E42] hover:text-white transition-colors font-semibold rounded-lg"
                                    >
                                        Mais informações
                                    </button>
                                </div>

                                <div className="hidden md:block border-l border-white mx-10"></div>

                                <div className="w-full md:w-1/3">
                                    <h2 className="text-2xl md:text-3xl font-semibold text-white mb-4">
                                        Legislação
                                    </h2>
                                    <p className="text-white leading-relaxed">
                                        <a href={selectedSupport.file} target="_blank" rel="noreferrer">
                                            {selectedSupport.file_name || "Sem legislação disponível."}
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </>
                    ) : (
                        <p className="text-gray-800 text-center">Nenhuma informação disponível.</p>
                    )}
                </Wrapper>
            </TextureBackground>


            <div className="relative w-full mt-20 md:mt-32 lg:mt-64">
                <img src={FooterHero} alt="Footer" className="w-full h-auto" />
                <div className="absolute inset-0 bg-gradient-to-b from-transparent from-80% via-transparent to-[#121E1A] h-1/2 top-1/2"></div>
            </div>
        </>
    );
}

export default SupportInfo;
