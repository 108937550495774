import { TextField } from "@mui/material";
import React, { useRef, useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import axiosConfig from "../../providers/axiosConfig";
import Wrapper from "../../resources/components/general/Wrapper";
import FooterHero from "../../resources/images/footer_banner_trees.svg";
import "react-toastify/dist/ReactToastify.css";
import NewsHeroBG from "../../resources/images/aequipa/aequipa.png";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import ContactUs from '../../resources/images/about/contact_us.png'
import { Link, redirect, useNavigate } from "react-router-dom";

import {
	Wrapped,
	NewsHero,
} from "./styles/mpe-ativa_styles";

function MpeAtiva() {
	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
		reset,
	} = useForm();
	const form = useRef();

	const [selectedFiles, setSelectedFiles] = useState([]);
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState('');
	const [snackbarSeverity, setSnackbarSeverity] = useState('success');
	const [fileNames, setFileNames] = useState([]);
	const [loading, setLoading] = useState(false);
	const [places, setPlaces] = useState([]);
	const navigate = useNavigate();

	const Alert = React.forwardRef(function Alert(props, ref) {
		return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
	});

	const validaContribuinte = (contribuinte) => {
		if (!/^\d{9}$/.test(contribuinte)) return false;

		const validPrefixes = [
			"1", "2", "3", "45", "5", "6", "70", "71", "72", "77", "79",
			"8", "90", "91", "98", "99"
		];
		if (!validPrefixes.some(prefix => contribuinte.startsWith(prefix))) {
			return false;
		}

		let total = 0;
		for (let i = 0; i < 8; i++) {
			total += contribuinte[i] * (9 - i);
		}

		let modulo11 = total % 11;
		let comparador = (modulo11 === 1 || modulo11 === 0) ? 0 : 11 - modulo11;

		return contribuinte[8] == comparador;
	};

	const onSubmit = async (data) => {
		try {
			const formData = new FormData();
			formData.append("business_park_id", data.parque);
			formData.append("title", data.title);
			formData.append("description", data.descricao);
			formData.append("reporter_name", data.name);
			formData.append("reporter_email", data.email);
			formData.append("reporter_phone", data.telephone);
			formData.append("reporter_nif", data.nif);

			console.log(selectedFiles.length, " size");

			for (let index = 0; index < selectedFiles.length; index++) {
				const file = selectedFiles[index];
				if (file.size / 1024 > 2048) {
					console.log(file.size);
					setSnackbarMessage("O tamanho da imagem não pode ultrapassar os 2MB.");
					setSnackbarSeverity("error");
					setSnackbarOpen(true);
					return;
				}
				formData.append(`images[${index}]`, file);
			}

			const response = await axiosConfig.post("/occurrence-reports", formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			});

			if (response.status === 200 || response.status === 201) {
				console.log("Form submitted successfully", response.data);
				setSelectedFiles([]);
				reset();

				/* setSnackbarMessage("Submissão efetuada com sucesso!");
				setSnackbarSeverity("success");
				setSnackbarOpen(true); */
				navigate('/?message=Submissão efetuada com successo!&status=success');
			} else {
				console.error("Form submission failed", response);
				setSnackbarMessage("Error submitting form");
				setSnackbarSeverity("error");
				setSnackbarOpen(true);
			}
		} catch (error) {

			console.error("Submission Error:", error);
			setSnackbarMessage(error.response?.data?.message || "Error submitting form");
			setSnackbarSeverity("error");
			setSnackbarOpen(true);
		}
	};

	const handleFileChange = (e) => {
		const files = e.target.files;
		const fileNamesArray = Array.from(files).map((file) => file.name);
		setFileNames(fileNamesArray);

		const files_2 = Array.from(e.target.files);
		setSelectedFiles(files_2);
	};

	useEffect(() => {
		setLoading(true);
		axiosConfig
			.get(`/business-parks`)
			.then((res) => {
				setPlaces(res.data || []);
				setLoading(false);
			})
			.catch(() => setLoading(false));
	}, []);

	return (
		<>
			<Snackbar
				open={snackbarOpen}
				message={snackbarMessage}
				autoHideDuration={5000}
				onClose={() => setSnackbarOpen(false)}
			>
				<Alert severity={snackbarSeverity}>{snackbarMessage}</Alert>
			</Snackbar>
			<NewsHero className="min-h-[80vh]">
				<div
					className={`absolute top-0 left-0 h-full w-full -z-10 after:z-[-1] after:block after:absolute after:top-0 after:left-0 after:bg-gradient-to-b after:from-[#1A211FE5] after:via-[#1A211F80] after:to-[transparent] after:w-full after:h-full`}
				>
					<img
						src={
							ContactUs
						}
						alt="main card of highlights"
						className="absolute top-10 left-0 -z-[3] w-full h-full object-cover mt-10 "
						style={{ clipPath: "inset(0px 0px 30% 0px)" }}

					/>
				</div>
				<Wrapper closeFull>
					<div className="flex h-full">
						<div className="flex flex-col gap-5 pt-[35vh] sm:pt-[35vh] text-white basis-full">
							<h2 className="text-white text-3xl uppercase font-bold mt-10 text-center">
								MPE ATIVA
							</h2>
							<p className="text-sm sm:text-md md:text-lg lg:text-xl text-white text-center">
								Ajude-nos a manter os parques seguros e funcionais! Caso note algo fora do normal, <br />
								use este canal para nos informar.
							</p>
						</div>
					</div>
				</Wrapper>
			</NewsHero>

			<Wrapped className=" relative -top-[8vh] 3xl:-top-[10vh] 2xl:-top-[18vh] lg:-top-[16vh]  z-10">
				<div className="bg-[#2F5E42] text-white p-10 justify-center text-center">
					<p className="text-2xl pb-3">A SUA OPINIÃO É IMPORTANTE</p>
					<div className="flex flex-col md:px-10 w-full mt-5 lg:mt-5 md:mt-4 sm:mt-2 ">
						<form
							ref={form}
							className="flex flex-col gap-5 text-left text-black"
							onSubmit={handleSubmit(onSubmit)}
						>
							<div className="flex flex-col">
								<div className="flex lg:flex-row md:flex-col sm:flex-col flex-col w-full gap-24 mb-7">
									<div className="flex flex-col gap-4 lg:w-1/2 md:w-full sm:w-full w-full">
										{/*Ocorrencia*/}
										<div className="flex flex-col gap-2">
											<p className="text-base font-medium text-white">
												OCORRÊNCIA<span className="text-white">*</span>
											</p>
											<input
												className="h-10 rounded-[4px] border-[1px] focus:outline-none pl-2"
												{...register("title", { required: "Nome da ocorrência obrigatório" })}
											/>
											{errors.title && (
												<span className="text-[#ED1D24]">
													{errors.title.message}
												</span>
											)}
										</div>

										{/*Parque Empresarial (Dropdown)*/}
										<div className="flex flex-col gap-2">
											<p className="text-base font-medium text-white">
												PARQUE EMRPRESARIAL<span className="text-white">*</span>
											</p>
											<select
												className="h-10 rounded-[4px] border-[1px] text-black"
												{...register("parque", {
													required: "Parque Empresarial obrigatório",
												})}
											>
												<option value="">Selecione um parque empresarial</option>
												{places.map((park) => (
													<option key={park.id} value={park.id} >
														{park.name}
													</option>
												))}
												{/* Add more options as needed */}
											</select>
											{errors.parque && (
												<span className="text-[#ED1D24]">
													{errors.parque.message}
												</span>
											)}
										</div>

										{/*Descricao*/}
										<div className="flex flex-col gap-2">
											<p className="text-base font-medium text-white">
												DESCRIÇÃO<span className="text-white">*</span>
											</p>
											<textarea
												className="h-28 rounded-[4px] border-[1px] p-2 resize-none overflow-y-auto text-black"
												{...register("descricao", {
													required: "Descrição é obrigatório",
												})}
											/>
											{errors.descricao && (
												<span className="text-[#ED1D24]">
													{errors.descricao.message}
												</span>
											)}
										</div>


										{/*Imagens*/}
										<div className="flex flex-col gap-2">
											<p className="text-base font-medium text-white">
												IMAGENS (MÁX. 2MB POR IMAGEM)<span className="text-white">*</span>
											</p>

											{/* Container for the button and file list */}
											<div className="flex items-center w-full">
												{/* Attach button */}
												<label
													htmlFor="file-upload"
													className="cursor-pointer bg-black text-white py-2 px-4 rounded-l-md">
													Anexar
												</label>

												{/* Hidden file input */}
												<input
													id="file-upload"
													type="file"
													accept="image/*"
													multiple
													onChange={handleFileChange}
													className="hidden"
												/>

												{/* Display chosen files */}
												<div
													className="flex items-center justify-start w-full bg-white p-2 rounded-r-md text-gray-700 overflow-x-auto"
													style={{ maxHeight: '40px' }}>
													{fileNames.length === 0 ? (
														<span className="text-gray-400">Nenhuma imagem selecionada</span>
													) : (
														fileNames.map((fileName, index) => (
															<span key={index} className="mr-2 text-sm">{fileName}</span>
														))
													)}
												</div>
											</div>

											{/* Error message */}
											{errors.imagens && (
												<span className="text-[#ED1D24]">
													{errors.imagens.message}
												</span>
											)}
										</div>
									</div>

									<div className="flex flex-col gap-4 lg:w-1/2 md:w-full sm:w-full w-full">
										<div className="flex flex-row justify-between">
											<div>
												<p className="text-white">Dados Pessoais</p>
											</div>
										</div>

										{/* Name */}
										<div className="flex flex-col gap-2">
											<p className="text-base font-medium text-white">
												Nome<span className="text-white">*</span>
											</p>
											<input
												className="h-10 rounded-[4px] border-[1px] focus:outline-none pl-2"
												{...register("name", { required: "Nome obrigatório" })}
											/>
											{errors.name && (
												<span className="text-[#ED1D24]">
													{errors.name.message}
												</span>
											)}
										</div>

										{/* Email */}
										<div className="flex flex-col gap-2">
											<p className="text-base font-medium text-white">
												Email<span className="text-white">*</span>
											</p>
											<input
												className="h-10 rounded-[4px] border-[1px] focus:outline-none pl-2"
												{...register("email", {
													required: "Email obrigatório",
													pattern: {
														value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
														message: "Email inválido",
													},
												})}
											/>
											{errors.email && (
												<span className="text-[#ED1D24]">
													{errors.email.message}
												</span>
											)}
										</div>

										{/* Telemóvel */}
										<div className="flex flex-col gap-2">
											<p className="text-base font-semibold text-white">Telemóvel*</p>
											<div className="flex flex-row w-full">
												<div className="flex text-[#707070] rounded-l-[4px] bg-[#E2E2E2] border-[#E2E2E2] -mr-px border-[1px] flex-col items-center justify-center px-2">
													+351
												</div>
												<Controller
													name="telephone"
													control={control}
													rules={{
														required: "Telefone obrigatório",
														minLength: {
															value: 9,
															message: "Número de telefone inválido",
														},
														maxLength: {
															value: 9,
															message: "Número de telefone inválido",
														},
														pattern: {
															value: /^9[1236][0-9]{7}|2[1-9]{1,2}[0-9]{7}$/,
															message: "Número de telefone inválido",
														},
													}}
													render={({ field: { onChange, value } }) => (
														<TextField
															className="w-full bg-white rounded-[4px] border-[1px] border-[#E2E2E2] focus:outline-none focus:border-[#07146380]"
															displayEmpty
															onChange={onChange}
															value={value}
															sx={{
																'& .MuiOutlinedInput-root': {
																	borderRadius: '4px',
																	borderColor: '#E2E2E2',
																},
																'& .MuiOutlinedInput-input': {
																	paddingLeft: '10px',
																	backgroundColor: "white",
																	borderRadius: "12px",
																	height: "10px"
																},
																'& .MuiOutlinedInput-notchedOutline': {
																	border: 'none',
																},
															}}
														/>
													)}
												/>
											</div>
											{errors.telephone && (
												<span className="text-[#ED1D24]">
													{errors.telephone.message}
												</span>
											)}
										</div>

										{/* NIF */}
										<div className="flex flex-col gap-2">
											<p className="text-base font-semibold text-white">NIF*</p>
											<div className="flex flex-row w-full">
												<Controller
													name="nif"
													control={control}
													rules={{
														required: "NIF obrigatório",
														minLength: {
															value: 9,
															message: "NIF inválido (deve conter 9 dígitos)",
														},
														maxLength: {
															value: 9,
															message: "NIF inválido (deve conter 9 dígitos)",
														},
														pattern: {
															value: /^[1256789][0-9]{8}$/,
															message: "NIF inválido (deve começar com 1, 2, 5, 6, 7, 8 ou 9)",
														},
														validate: (value) => {
															if (!validaContribuinte(value)) {
																return "NIF inválido";
															}
															return true;
														},
													}}
													render={({ field: { onChange, value } }) => (
														<TextField
															className="w-full bg-white rounded-[4px] border-[1px] border-[#E2E2E2] focus:outline-none focus:border-[#07146380]"
															displayEmpty
															onChange={onChange}
															value={value}
															sx={{
																'& .MuiOutlinedInput-root': {
																	borderRadius: '4px',
																	borderColor: '#E2E2E2',
																},
																'& .MuiOutlinedInput-input': {
																	paddingLeft: '10px',
																	backgroundColor: "white",
																	borderRadius: "12px",
																	height: "10px"
																},
																'& .MuiOutlinedInput-notchedOutline': {
																	border: 'none',
																},
															}}
														/>
													)}
												/>
											</div>
											{errors.nif && (
												<span className="text-[#ED1D24]">
													{errors.nif.message}
												</span>
											)}
										</div>
									</div>
								</div>

								{/* Checkbox */}
								<div className="flex flex-row justify-between gap-3 h-32 mt-5">
									<div>
										<label htmlFor="green-checkbox" className="flex items-center gap-2 cursor-pointer">
											<input
												id="green-checkbox"
												type="checkbox"
												{...register("autorizo", { required: "É necessário aceitar os termos e condições" })}
												className="w-10 h-5 accent-green-600 rounded-sm"
											/>
										</label>
									</div>


									<div className="max-h-40 overflow-auto">
										<p className="text-white font-medium">
											Autorizo que os dados pessoais por mim fornecidos neste formulário sejam utilizados exclusivamente para processamento desta ocorrência. Concordo que me enviem notificações, com informação acerca do estado desta ocorrência, assim como possíveis contactos adicionais por parte dos serviços competentes da Madeira Parques Empresariais (ou entidades a prestar serviços à MPE), no âmbito da resolução da mesma.
										</p>
									</div>
								</div>
								{errors.autorizo && (
									<p className="text-red-500 text-sm items-center text-center mb-5">{errors.autorizo.message}</p>
								)}

								{/*button enviar*/}
								<div className="flex items-center justify-center mt-8">
									<button
										type="submit"
										className="flex bg-[#121E1A] text-white justify-center py-3 rounded-md w-full lg:w-[20%] md:w-full sm:w-full"
									>
										Enviar Mensagem
									</button>
								</div>
							</div>
						</form>
					</div>
				</div >
			</Wrapped >

			<div className="relative w-full">
				<img src={FooterHero} alt="Footer" className="w-full h-auto" />
				<div className="absolute inset-0 bg-gradient-to-b from-transparent from-80% via-transparent to-[#121E1A] h-1/2 top-1/2"></div>
			</div>

		</>
	);
}

export default MpeAtiva;
