import styled from "styled-components";



export const DocumentHero = styled("div")`
  width: 100%;
  position: relative;
  background-color: #ffffff03;
  backdrop-filter: blur(5px);
  top: ;
  left: 0;
  /* min-height: 40vh; */
  z-index: 10;
  padding: 20px 0;


  &::before {
    background-size: cover;
    background-repeat: no-repeat;
    content: "";
    display: block;
    width: 100vw;
    height: 100%;
    bottom: -30px;
    left: -10%;
    position: absolute;
    z-index: -1;
    transform: rotate(180deg);
    box-sizing: border-box;


    @media (min-width: 768px) {
      left: -20%;
    }
  }
`;
