import React, { useEffect, useState } from "react";
import moment from "moment";
import axiosConfig from "../../providers/axiosConfig";
import EventCard from "../../resources/components/general/EventCard";
import Wrapper from "../../resources/components/general/Wrapper";
import FooterHero from "../../resources/images/footer_banner_trees.svg";
import { TextureBackground } from "./styles/news_style";
import { CircularProgress } from "@mui/material";
import HeroBanner from "../../resources/components/general/HeroBanner";

function ListEvents() {
  const [loading, setLoading] = useState(false);
  const [nextEvent, setNextEvent] = useState(null);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [pastEvents, setPastEvents] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;

  useEffect(() => {
    setLoading(true);
    axiosConfig
      .get("/events")
      .then((res) => {
        console.log("Resposta da API:", res.data);
        const data = Array.isArray(res.data) ? res.data : [];
        const now = moment();

        const future = data.filter((evt) => {
          const earliestDate = getEarliestEventDate(evt);
          return earliestDate && moment(earliestDate).isSameOrAfter(now, "day");
        });

        const past = data.filter((evt) => {
          const earliestDate = getEarliestEventDate(evt);
          return !earliestDate || moment(earliestDate).isBefore(now, "day");
        });

        console.log("Eventos futuros filtrados:", future);

        const sortedFuture = future.sort((a, b) => {
          const dateA = getEarliestEventDate(a);
          const dateB = getEarliestEventDate(b);
          return moment(dateA).diff(moment(dateB));
        });

        const nextEvt = sortedFuture.length ? sortedFuture[0] : null;
        const scheduled = sortedFuture.length > 1 ? sortedFuture.slice(1) : [];

        const sortedPast = past.sort((a, b) => {
          const dateA = getEarliestEventDate(a);
          const dateB = getEarliestEventDate(b);
          return moment(dateB).diff(moment(dateA));
        });

        setNextEvent(nextEvt);
        setUpcomingEvents(scheduled);
        setPastEvents(sortedPast);
        setLoading(false);

        console.log(
          "Eventos agendados antes do slice:",
          sortedFuture.slice(0, 4)
        );
        console.log("Eventos agendados:", scheduled);
      })
      .catch(() => setLoading(false));
  }, []);

  const getEarliestEventDate = (evt) => {
    const dates = evt["event-dates"] || [];
    if (!dates.length) return null;

    const sorted = [...dates].sort((a, b) =>
      moment(a.date_time).diff(moment(b.date_time))
    );
    return sorted[0].date_time;
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentPast = pastEvents.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(pastEvents.length / itemsPerPage);
  const pageNumbers =
    totalPages > 0 ? [...Array(totalPages).keys()].map((i) => i + 1) : [];

  return (
    <>
      <HeroBanner title="Eventos" />
      <Wrapper closeFull className="mt-20 md:mt-32">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 justify-center">
          <div className="lg:col-span-2">
            <h2 className="font-bold uppercase text-3xl w-10/14 sm:w-full mb-4 text-left underline mx-auto">
              Próximo Evento
            </h2>

            {loading ? (
              <div className="flex justify-center w-full">
                <CircularProgress className="mx-auto" sx={{ color: "#FFF" }} />
              </div>
            ) : nextEvent ? (
              <EventCard big data={nextEvent} />
            ) : (
              <p>Não há próximo evento</p>
            )}
          </div>

          <div className="lg:col-span-1">
            <h2 className="font-bold uppercase text-xl mb-4 text-left underline">
              Eventos Agendados
            </h2>

            {upcomingEvents.length > 0 ? (
              <div className="flex flex-col gap-4 text-left">
                {upcomingEvents.map((item, index) => (
                  <EventCard key={index} data={item} hideImage={true} />
                ))}
              </div>
            ) : (
              <p className="text-lg text-left">Sem eventos agendados.</p>
            )}
          </div>
        </div>
      </Wrapper>

      <TextureBackground className="relative z-[1] mt-20 md:mt-32">
        <Wrapper closeFull>
          <h2 className="font-bold uppercase text-2xl w-10/15 z-10 mb-8 underline text-left">
            Eventos Realizados
          </h2>

          {loading ? (
            <div className="flex justify-center w-full">
              <CircularProgress className="mx-auto" sx={{ color: "#1C6C5E" }} />
            </div>
          ) : currentPast.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
              {currentPast.map((item, index) => (
                <EventCard key={index} data={item} secondary />
              ))}
            </div>
          ) : (
            <p className="text-lg">Ainda não há eventos realizados.</p>
          )}

          {totalPages > 1 && (
            <div className="flex justify-center mt-10">
              {currentPage > 1 && (
                <button
                  onClick={() =>
                    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
                  }
                  className="py-2 px-4 rounded mx-1 bg-opacity-0 bg-white text-black text-lg"
                  disabled={currentPage === 1}
                >
                  &#60;
                </button>
              )}

              {pageNumbers.length > 0 &&
                pageNumbers
                  .filter(
                    (number) =>
                      number >= currentPage - 1 && number <= currentPage + 1
                  )
                  .map((number) => (
                    <button
                      key={number}
                      onClick={() => setCurrentPage(number)}
                      className={`py-2 px-4 rounded mx-1 ${
                        currentPage === number
                          ? "bg-[#1C6C5E] text-white"
                          : "bg-opacity-0 bg-white text-black"
                      } text-lg`}
                    >
                      {number}
                    </button>
                  ))}

              {currentPage < totalPages && (
                <button
                  onClick={() =>
                    setCurrentPage((prevPage) =>
                      Math.min(prevPage + 1, totalPages)
                    )
                  }
                  className="py-2 px-4 rounded mx-1 bg-opacity-0 bg-white text-black text-lg"
                  disabled={currentPage === totalPages}
                >
                  &#62;
                </button>
              )}
            </div>
          )}
        </Wrapper>
      </TextureBackground>

      <div className="relative w-full mt-20 md:mt-32">
        <img src={FooterHero} alt="Footer" className="w-full h-auto" />
        <div className="absolute inset-0 bg-gradient-to-b from-transparent from-80% via-transparent to-[#121E1A] h-1/2 top-1/2"></div>
      </div>
    </>
  );
}

export default ListEvents;
