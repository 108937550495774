import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import "react-vertical-timeline-component/style.min.css";
import MadeiraMap from "../../resources/images/parks/madeira_map.png";
import PlaceHolder from "../../resources/images/parks/placeholder_directory.png";
import RedMarker from "../../resources/images/parks/map_marker_red.png";
import YellowMarker from "../../resources/images/parks/map_marker_yellow.png";
import EmailInfo from "../../resources/images/parks/email_info.png";
import PhoneInfo from "../../resources/images/parks/phone_info.png";
import PlusImage from "../../resources/images/parks/plus_image.png";
import WebsiteInfo from "../../resources/images/parks/web_info.png";
import LocInfo from "../../resources/images/parks/loc_info.png";
import RightArrow from "../../resources/images/parks/right_arrow.png";
import LotesIcon from "../../resources/images/parks/lotes_icon.png";
import EmpresasIcon from "../../resources/images/parks/empresas_icon.png";
import LeftArrow from "../../resources/images/parks/left_arrow.png";
import LocInfoVerde from "../../resources/images/parks/loc_info_verde.png";
import axiosConfig from "../../providers/axiosConfig";
import CheckMark from "../../resources/images/parks/check.png";
import XMark from "../../resources/images/parks/cross.png";
import styled, { keyframes } from 'styled-components';
import FooterHero from "../../resources/images/footer_banner_trees.svg";
import emptyImage from "../../resources/images/announcements/Warehouse.svg";




import {
	InsButton,
	Wrapped,
	WrappedNoOverflow,
	PlaceButton,
	PlaceDiv,
	SearchFilterContainer,
	SearchBar,
	FilterDropdown,
	GridContainer,
	GridItem,
	Image,
	Title,
	Header2div,
	CandDiv,
	CandP,
	ShowMoreBtn,
	GridInfoP,
	GridInfodiv,
	InfoSquareDiv,
	AddressP,
	FontDiv,
	ScrollDiv,
} from "./styles/parks_styles";
import HeroBanner from "../../resources/components/general/HeroBanner";

function Parks() {

	const markers = [
		{
			//Calheta
			id: 1,
			top: '73%',
			left: '21%',
			targetPage: 'Calheta',
		},
		{
			//Camacha
			id: 2,
			top: '55%',
			left: '68%',
			targetPage: 'Camacha',
		},
		{
			//Camara de Lobos
			id: 3,
			top: '73%',
			left: '53%',
			targetPage: 'Câmara de Lobos',
		},
		{
			//Cancela
			id: 4,
			top: '64%',
			left: '64%',
			targetPage: 'Cancela',
		},
		{
			//Machico
			id: 5,
			top: '40%',
			left: '70%',
			targetPage: 'Machico',
		},
		{
			//Porto Moniz
			id: 6,
			top: '50%',
			left: '11%',
			targetPage: 'Porto Moniz',
		},
		{
			//Porto Santo
			id: 7,
			top: '9%',
			left: '92%',
			targetPage: 'Porto Santo',
		},
		{
			//Ribeira Brava
			id: 8,
			top: '73%',
			left: '45%',
			targetPage: 'Ribeira Brava',
		},
		{
			//Santana
			id: 9,
			top: '35%',
			left: '55%',
			targetPage: 'Santana',
		},
		{
			//São Vicente
			id: 10,
			top: '50%',
			left: '33%',
			targetPage: 'São Vicente',
		},
		{
			//Zona Oeste
			id: 11,
			top: '73%',
			left: '59%',
			targetPage: 'Zona Oeste',
		},

	];

	const addresses = [
		{
			//Calheta
			id: 1,
			title: 'Parque Empresarial da Calheta',
			name: 'Estreito da Calheta',
			postal: '370-250 Estreito Da Calheta',
			cords: `32°44'55.9"N 17°11'30.4"W`,
			link: "https://www.google.pt/maps/place/32%C2%B044'55.9%22N+17%C2%B011'30.4%22W/@32.7488611,-17.1943527,17z/data=!3m1!4b1!4m4!3m3!8m2!3d32.7488611!4d-17.1917778?entry=ttu&g_ep=EgoyMDI1MDMxMi4wIKXMDSoJLDEwMjExNDU1SAFQAw%3D%3D",
		},
		{
			//Camacha
			id: 2,
			title: 'Parque Empresarial da Camacha',
			name: 'Parque Empresarial da Camacha',
			postal: '9135-401 Camacha',
			cords: `32°40'04.8"N 16°51'20.9"W`,
			link: "https://www.google.pt/maps/place/32%C2%B040'04.8%22N+16%C2%B051'20.9%22W/@32.668,-16.8583805,17z/data=!3m1!4b1!4m4!3m3!8m2!3d32.668!4d-16.8558056?entry=ttu&g_ep=EgoyMDI1MDMxMi4wIKXMDSoJLDEwMjExNDU1SAFQAw%3D%3D",
		},
		{
			//Camara de Lobos
			id: 3,
			title: 'Parque Empresarial de Câmara de Lobos',
			name: 'Parque Empresarial de Câmara de Lobos Estrada da Ribeira Garcia ',
			postal: '9300-324 Câmara de Lobos',
			cords: `32°40'03.9"N 16°59'50.6"W`,
			link: "https://www.google.pt/maps/place/32%C2%B040'03.9%22N+16%C2%B059'50.6%22W/@32.66775,-16.9999638,17z/data=!3m1!4b1!4m4!3m3!8m2!3d32.66775!4d-16.9973889?entry=ttu&g_ep=EgoyMDI1MDMxMi4wIKXMDSoJLDEwMjExNDU1SAFQAw%3D%3D",
		},
		{
			//Cancela
			id: 4,
			title: 'Parque Empresarial da Cancela',
			name: 'Parque Empresarial da Cancela ',
			postal: '9125-042 Caniço',
			cords: `32°38'53.7"N 16°51'37.8"W`,
			link: "https://www.google.com/maps/place/32%C2%B038'53.7%22N+16%C2%B051'37.8%22W/@32.64825,-16.8630749,17z/data=!3m1!4b1!4m4!3m3!8m2!3d32.64825!4d-16.8605?entry=ttu&g_ep=EgoyMDI1MDMxMi4wIKXMDSoASAFQAw%3D%3D",
		},
		{
			//Machico
			id: 5,
			title: 'Parque Empresarial de Machico',
			name: 'Parque Empresarial de Machico ',
			postal: '9200-405 Machico',
			cords: `32°43'50.3"N 16°48'14.4"W`,
			link: "https://www.google.pt/maps/place/32%C2%B043'50.3%22N+16%C2%B048'14.4%22W/@32.7434539,-16.8217884,15z/data=!4m4!3m3!8m2!3d32.7306389!4d-16.804?entry=ttu&g_ep=EgoyMDI1MDMxMi4wIKXMDSoJLDEwMjExNDU1SAFQAw%3D%3D",
		},
		{
			//Porto Moniz
			id: 6,
			title: 'Parque Empresarial do Porto Moniz',
			name: 'Parque Empresarial do Porto Moniz ',
			postal: '9270 Porto Moniz',
			cords: `32°50'58.6"N 17°11'44.0"W`,
			link: "https://www.google.com/maps/place/32%C2%B050'58.6%22N+17%C2%B011'44.0%22W/@32.8496111,-17.1981305,17z/data=!3m1!4b1!4m4!3m3!8m2!3d32.8496111!4d-17.1955556?entry=ttu&g_ep=EgoyMDI1MDMxMi4wIKXMDSoASAFQAw%3D%3D",
		},
		{
			//Porto Santo
			id: 7,
			title: 'Parque Empresarial do Porto Santo',
			name: 'Parque Empresarial do Porto Santo Tanque ',
			postal: '9400-100 Porto Santo',
			cords: `33°04'20.7"N 16°20'36.0"W`,
			link: "https://www.google.com/maps/place/33%C2%B004'20.7%22N+16%C2%B020'36.0%22W/@33.0724167,-16.3459082,17z/data=!3m1!4b1!4m4!3m3!8m2!3d33.0724167!4d-16.3433333?entry=ttu&g_ep=EgoyMDI1MDMxMi4wIKXMDSoASAFQAw%3D%3D",
		},
		{
			//Ribeira Brava
			id: 8,
			title: 'Parque Empresarial da Ribeira Brava',
			name: 'Sitio Da Boa Morte ',
			postal: '9350-104 Ribeira Brava',
			cords: `32°40'59.4"N 17°02'24.2"W`,
			link: "https://www.google.com/maps/place/32%C2%B040'59.4%22N+17%C2%B002'24.2%22W/@32.6831667,-17.0426305,17z/data=!3m1!4b1!4m4!3m3!8m2!3d32.6831667!4d-17.0400556?entry=ttu&g_ep=EgoyMDI1MDMxMi4wIKXMDSoASAFQAw%3D%3D",
		},
		{
			//Santana
			id: 9,
			title: 'Parque Empresarial de Santana',
			name: 'Estrada do Pico ',
			postal: '9230-096 Redondo Santana',
			cords: `32°47'10.3"N 16°52'49.0"W`,
			link: "https://www.google.com/maps/place/32%C2%B047'10.3%22N+16%C2%B052'49.0%22W/@32.7861944,-16.8828527,17z/data=!3m1!4b1!4m4!3m3!8m2!3d32.7861944!4d-16.8802778?entry=ttu&g_ep=EgoyMDI1MDMxMi4wIKXMDSoASAFQAw%3D%3D",
		},
		{
			//São Vicente
			id: 10,
			title: 'Parque Empresarial de São Vicente',
			name: 'Parque Empresarial de São Vicente ',
			postal: '9240 São Vicente',
			cords: `32°46'48.5"N 17°02'48.1"W`,
			link: "https://www.google.com/maps/place/32%C2%B046'48.5%22N+17%C2%B002'48.1%22W/@32.7801389,-17.0492693,17z/data=!3m1!4b1!4m4!3m3!8m2!3d32.7801389!4d-17.0466944?entry=ttu&g_ep=EgoyMDI1MDMxMi4wIKXMDSoASAFQAw%3D%3D",
		},
		{
			//Zona Oeste
			id: 11,
			title: 'Parque Empresarial da Zona Oeste',
			name: 'Parque Empresarial Zona Oeste ',
			postal: '9300-020 Câmara de Lobos',
			cords: `32°38'43.5"N 16°58'11.8"W`,
			link: "https://www.google.com/maps/place/32%C2%B038'43.5%22N+16%C2%B058'11.8%22W/@32.6454167,-16.9725193,17z/data=!3m1!4b1!4m4!3m3!8m2!3d32.6454167!4d-16.9699444?entry=ttu&g_ep=EgoyMDI1MDMxMi4wIKXMDSoASAFQAw%3D%3D",
		},

	];

	const [search, setSearch] = useState("");
	const [filter, setFilter] = useState("All");
	const [places, setPlaces] = useState([]);
	const [loading, setLoading] = useState(false);
	const companiesPerPage = 20;
	const navigate = useNavigate();

	const [activePlace, setActivePlace] = useState(null);
	const [activeButton, setActiveButton] = useState(null);
	const [selectedPlaceInfo, setSelectedPlaceInfo] = useState(null);

	useEffect(() => {
		setLoading(true);
		axiosConfig
			.get(`/business-parks`)
			.then((res) => {
				setPlaces(res.data || []);
				setLoading(false);
			})
			.catch(() => setLoading(false));
	}, []);


	const uniquePlaceNames = [...new Set(places.map(place => place.name))];

	const filteredPlaces = places.filter((place) =>
		(place.name.toLowerCase().includes(search.toLowerCase()) ||
			place.companies?.some(company => company.name.toLowerCase().includes(search.toLowerCase()))) &&
		(filter === "All" || place.name === filter)
	);

	const [visibleCount, setVisibleCount] = useState(companiesPerPage);

	const handleShowMore = () => {
		setVisibleCount(visibleCount + companiesPerPage);
	};

	const handleButtonClick = (selected) => {
		let place = places.find((p) => p.name === selected.targetPage) || selected;

		setActivePlace(place);
		setActiveButton(place.id);
		setSelectedPlaceInfo(place);
	};

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [currentImageIndex, setCurrentImageIndex] = useState(0);

	const openModal = (index) => {
		setCurrentImageIndex(index);
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};




	function ImageModal({ images, currentIndex, closeModal }) {
		return (
			<div className="fixed inset-0 z-50 h-full flex justify-center items-center bg-white bg-opacity-70">
				<div onClick={closeModal} className="absolute top-0 left-0 w-full h-[20%] cursor-pointer" />
				<div className="relative justify-center flex flex-col h-[60%] items-center w-full bg-[#7E7E7E] bg-opacity-30 py-20">
					<img
						src={images[currentIndex].path}
						alt="Place"
						className="max-w-full max-h-full object-contain"
					/>
					<div className="flex justify-between w-full absolute top-1/2 transform -translate-y-1/2 px-4">
						<button
							onClick={() => {
								if (currentIndex > 0) {
									setCurrentImageIndex(currentIndex - 1);
								}
							}}
							className="cursor-pointer "
						>
							<img src={LeftArrow} alt="Left" className="w-auto h-8" />
						</button>
						<button
							onClick={() => {
								if (currentIndex < images.length - 1) {
									setCurrentImageIndex(currentIndex + 1);
								}
							}}
							className="cursor-pointer"
						>
							<img src={RightArrow} alt="Right" className="w-auto h-8" />
						</button>
					</div>

					<div className="flex justify-center space-x-4 mt-4">
						{images.map((image, index) => (
							<div
								key={image.id}
								onClick={() => setCurrentImageIndex(index)}
								className="relative cursor-pointer"
							>
								<img
									src={image.path}
									alt={`Thumbnail ${index}`}
									className={`w-20 h-20 object-cover transition-all duration-200 ${index === currentIndex ? 'scale-110' : ''}`}
								/>
							</div>
						))}
					</div>
				</div>
				<div onClick={closeModal} className="absolute bottom-0 left-0 w-full h-[20%] cursor-pointer" />
			</div>
		);
	}

	const [isVisible, setIsVisible] = useState(false);
	const [showTelecom, setShowTelecom] = useState(false);
	const [showArea, setShowArea] = useState(false);
	const [showAccessibility, setShowAccessibility] = useState(false);

	useEffect(() => {
		if (selectedPlaceInfo) {
			setIsVisible(false);
			setShowTelecom(false);
			setShowArea(false);
			setShowAccessibility(false);

			setTimeout(() => setIsVisible(true), 800);
			setTimeout(() => setShowTelecom(true), 2200);
			setTimeout(() => setShowArea(true), 2000);
			setTimeout(() => setShowAccessibility(true), 1800);
		}
	}, [selectedPlaceInfo]);

	const OccupationRate = ({ occupationRate }) => {
		const [animatedValue, setAnimatedValue] = useState(0);
		const [isReadyForAnimation, setIsReadyForAnimation] = useState(false);

		const radius = 70;
		const circumference = 2 * Math.PI * radius;
		const maxDashArray = (240 / 360) * circumference;
		const dashOffset = (1 - animatedValue / 100) * maxDashArray;


		useEffect(() => {

			const timer = setTimeout(() => {
				setIsReadyForAnimation(true);
			}, 2000);

			return () => clearTimeout(timer);
		}, []);

		useEffect(() => {
			if (isReadyForAnimation) {
				setAnimatedValue(0);

				const duration = 800;

				const startTime = performance.now();

				const animate = (timestamp) => {
					const elapsed = timestamp - startTime;
					const progress = Math.min(elapsed / duration, 1);

					setAnimatedValue(Math.floor(progress * occupationRate));

					if (progress < 1) {
						requestAnimationFrame(animate);
					}
				};

				requestAnimationFrame(animate);
			}
		}, [isReadyForAnimation, occupationRate]);

		return (
			<div className="bg-[#2F5E42] text-white h-[248px] w-[40%] text-center py-4 px-4 rounded">
				<h2 className=" text-lg sm:text-xl md:text-1xl lg:text-[20px] font-semibold mb-3 sm:mb-4 md:mb-5">Taxa de Ocupação (%) </h2>

				<div className="relative flex justify-center items-center">
					<svg width="170" height="auto" viewBox="0 0 160 110" className="transform md:h-[100px]">
						<circle cx="50%" cy="80" r={radius} stroke="#ffffff" strokeWidth="15" fill="none" />

						<circle
							cx="50%"
							cy="80"
							r={radius}
							stroke="#344B57"
							strokeWidth="17"
							fill="none"
							strokeDasharray={maxDashArray}
							strokeDashoffset={dashOffset}
							transform="rotate(150 80 80)"
							style={{
								transition: "stroke-dashoffset 2s ease-out",
							}}
						/>

						<text x="50%" y="70%" textAnchor="middle" dominantBaseline="middle" className="text-5xl" fill="#ffffff">
							{Math.floor(animatedValue)}
						</text>
					</svg>
				</div>
			</div>
		);
	};

	const [hoveredMarker, setHoveredMarker] = useState(null);

	return (
		<>
			<HeroBanner title='Os Nossos Parques' />
			{/* Buttons Map */}
			<Wrapped className="mt-20 md:mt-32">
				<div className="flex gap-2 justify-left flex-wrap lg:gap-8 md:gap-4 sm:gap-2">
					{
						places.map((place) => (
							<PlaceButton key={place.id}
								onClick={() => handleButtonClick(place)}
								style={{
									backgroundColor: activeButton === place.id ? "#2F5D42" : "",
									color: activeButton === place.id ? "white" : "",
									borderColor: activeButton === place.id ? "#2F5D42" : "",
									transition: "background-color 0.3s ease",
								}}>
								<PlaceDiv>{place.name}</PlaceDiv>
							</PlaceButton>

						))
					}
				</div>
			</Wrapped>

			{/* Madeira Map*/}
			<Wrapped >
				<div className="flex flex-col lg:flex-row items-center justify-center">
					<div className="relative w-full lg:w-[50%] md:w-[80%] transition-all duration-200 "
						style={{
							width: selectedPlaceInfo ? (window.innerWidth >= 1024 ? '50%' : '80%') : '70%',
							transition: 'width 2s ease',
							position: 'relative',
						}}>
						<div className="overflow-hidden">
							<img src={MadeiraMap} alt="MadeiraMap" className="w-full h-auto max-w-full" />
						</div>


						{markers.map((marker) => (
							<button
								key={marker.id}
								onClick={() => handleButtonClick(marker)}
								onMouseEnter={() => setHoveredMarker(marker.id)}
								onMouseLeave={() => setHoveredMarker(null)}
								className="absolute"
								style={{
									top: marker.top,
									left: marker.left,
									transform: "translate(-50%, -50%)",
								}}
							>
								<img
									src={activeButton === marker.id ? YellowMarker : RedMarker}
									alt={`${marker.id}`}
									className={`h-auto w-[15px] ${!selectedPlaceInfo
										? "lg:w-[40px] md:w-[30px] sm:w-[20px]"
										: "lg:w-[30px] md:w-[25px] sm:w-[15px]"
										}`}
								/>
								{hoveredMarker === marker.id && (
									<div className="absolute top-1/2 left-1/2 z-50 flex transform -translate-x-1/2 mb-2 px-2 py-1 text-base text-white bg-black rounded">
										{marker.targetPage}
									</div>
								)}
							</button>
						))}
					</div>
					{selectedPlaceInfo && (
							<div className="w-full flex flex-col lg:w-[40%] transition-all linear lg:mt-[4%] lg:ml-[10%]"
								style={{
									transform: isVisible ? 'translateX(0)' : 'translateX(-200%)',
									opacity: isVisible ? 1 : 0,
									transitionDuration: isVisible ? '1s' : '0s',
									transitionTimingFunction: 'linear',
								}}>
								{selectedPlaceInfo.address && (
									<>
										{addresses
											.filter(address => address.id === selectedPlaceInfo.id)
											.map(filteredAddress => (
												<div key={filteredAddress.id}>
													<h2 className="text-2xl text-[#3A4448] my-3">{filteredAddress.title}</h2>
												</div>
											))}
									</>
								)}
								{selectedPlaceInfo?.id && places?.length > 0 && (
									places
										.filter(place => place.id === selectedPlaceInfo.id)
										.map(filteredPlace => (
											<FontDiv className="flex gap-2 mb-3 " key={filteredPlace.id}>
												{/* lots and companies */}
												<div className="bg-[#537D5C] text-white h-[248px] w-full md:w-[60%] text-center py-2 px-4 rounded">
													<div className="flex justify-evenly pt-5 gap-5 flex-col md:gap-0 md:pt-11 md:flex-row">
														<div className="flex flex-col items-center gap-2 md:gap-5">
															<img src={LotesIcon} alt="LotesIcon" className="w-auto h-8" />
															<div className="flex flex-row gap-5 md:flex-col ">
																<p className="text-2xl font-light sm:text-4xl">{filteredPlace.number_of_spaces}</p>
																<p >Lotes</p>
															</div>
														</div>
														<div className="flex flex-col items-center gap-2 md:gap-5">
															<img src={EmpresasIcon} alt="EmpresasIcon" className="w-auto h-8" />
															<div className="flex flex-row gap-5 md:flex-col ">
																<p className="text-2xl font-light sm:text-4xl">{filteredPlace.number_of_companies}</p>
																<p >Empresas</p>
															</div>
														</div>
													</div>
												</div>

												{/* Occupation rate */}
												<OccupationRate occupationRate={filteredPlace.occupation_rate} />

											</FontDiv>
										))
								)}
								{selectedPlaceInfo?.id && places?.length > 0 && (
									places
										.filter(place => place.id === selectedPlaceInfo.id)
										.map(filteredPlace => (
											<div className="flex gap-2 mb-1">
												{/* number of workers */}
												<FontDiv className="bg-[#2F5E42] text-white h-[248px] w-[40%] text-center py-14 px-4 rounded">
													<p className="pt-5 text-2xl sm:text-4xl">{filteredPlace.number_of_job_positions}</p>
													<p className="pt-1 font-light text-base md:text-lg">N.º estimado de trabalhadores</p>
												</FontDiv>

												{/* Description */}
												<ScrollDiv className="bg-[#20432E] text-white h-[248px] w-[60%] text-start p-5 rounded">
													<FontDiv>
														<p className="text-sm lg:text-lg sm:text-base">{filteredPlace.description}</p>
													</FontDiv>
												</ScrollDiv>
											</div>
										))
								)}
							</div>
					)}
				</div>

				{/* Conditionally render extra info */}
				{selectedPlaceInfo?.id && places?.length > 0 && (
					places
						.filter(place => place.id === selectedPlaceInfo.id)
						.map(filteredPlace => (
							<FontDiv className="flex gap-2 whitespace-nowrap w-full overflow-x-auto flex-nowrap justify-start 2xl:justify-end" key={filteredPlace.id}>
								{/* Telecomunicacoes */}
								<div className="bg-[#2F5E42] text-white h-52 text-center py-2 items-center px-4 rounded transition-all ease-out lg:w-[30%]"
									style={{
										transform: showTelecom ? "translateX(0)" : "translateX(100%)",
										opacity: showTelecom ? 1 : 0,
										transitionDuration: showTelecom ? '1s' : '0s',
										transitionTimingFunction: 'linear',
									}}>
									<p className=" text-lg sm:text-xl md:text-2xl lg:text-[20px] font-semibold mb-3 sm:mb-4 md:mb-8">Telecomunicações</p>

									<div className="flex justify-center items-center gap-2 sm:gap-5 text-base mt-10">
										{filteredPlace.five_g_coverage !== null && (
											<p className="flex items-start pr-4 gap-1 ">
												{filteredPlace.five_g_coverage ? (
													<img src={CheckMark} alt="CheckMark" className="h-4 w-auto filter invert sepia brightness-200" />
												) : (
													<img src={XMark} alt="XMark" className="h-4 w-auto filter invert sepia brightness-200" />
												)}
												Cobertura 5G
											</p>
										)}

										{filteredPlace.optic_fiber !== null && (
											<p className="flex items-center gap-1">
												{filteredPlace.optic_fiber ? (
													<img src={CheckMark} alt="CheckMark" className="h-4 w-auto filter invert sepia brightness-200" />
												) : (
													<img src={XMark} alt="XMark" className="h-4 w-auto filter invert sepia brightness-200" />
												)}
												Fibra Óptica
											</p>
										)}
									</div>

									{filteredPlace.five_g_coverage === null && filteredPlace.optic_fiber === null && (
										<p className="pt-5 flex items-center justify-center h-28 gap-2">
											Informação não disponível
										</p>
									)}
								</div>
								{/* area verde e construida */}
								<div className="bg-[#20432E] text-white h-52 text-center py-2 px-8 rounded transition-all ease-out lg:w-[30%]"
									style={{
										transform: showArea ? "translateX(0)" : "translateX(100%)",
										opacity: showArea ? 1 : 0,
										transitionDuration: showArea ? '1s' : '0s',
										transitionTimingFunction: 'linear',
									}}>
									<p className="text-lg sm:text-xl md:text-2xl lg:text-[20px] font-semibold mb-3 sm:mb-4 md:mb-8">Áreas</p>
									<div className="flex justify-center items-center w-full gap-16 lg:gap-[2%] xl:gap-6 text-center">
										<div>
											<p className={`${filteredPlace.built_area ? "text-3xl" : "text-xl"}`}>
												{filteredPlace.green_area ? `${parseInt(filteredPlace.green_area)} m${'\u00b2'}`
													: (<>
														<span> Informação </span><br />
														<span> não disponivel </span><br />
														<span> sobre: </span>
													</>)}
											</p>
											<p className="font-light pt-1">Área Verde</p>
										</div>
										<div>
											<p className={`${filteredPlace.built_area ? "text-3xl" : "text-xl"}`}>
												{filteredPlace.built_area ? `${parseInt(filteredPlace.built_area)} m${'\u00b2'}` : (<>
													<span> Informação </span><br />
													<span> não disponivel </span><br />
													<span> sobre: </span>
												</>)}
											</p>
											<p className="font-light pt-1">Área Construída</p>
										</div>
									</div>
								</div>
								{/* Acessibilidade */}
								<div className="bg-[#527D5C] text-white h-52 2xl:min-w-[39%] text-center py-2 px-4 rounded transition-all ease-out lg:w-[41%]"
									style={{
										transform: showAccessibility ? "translateX(0)" : "translateY(-20px)",
										opacity: showAccessibility ? 1 : 0,
										transitionDuration: showAccessibility ? '1s' : '0s',
										transitionTimingFunction: 'linear',
									}}>
									<div className="pb-8">
										<p className="text-lg sm:text-xl md:text-2xl lg:text-[20px] font-semibold mb-3 sm:mb-4 md:mb-5 ">
											Acessibilidade
										</p>
									</div>

									<div className="flex justify-evenly items-center w-full gap-1 sm:gap-6 md:gap-8 lg:gap-[2%] xl:gap-16 text-center mt-[-25px]">
										<div> 

											<p className="text-3xl">
												{filteredPlace.city_distance !== null && filteredPlace.city_distance !== undefined
													? `${filteredPlace.city_distance}km`
													: "--"}
												<p className="font-light">Funchal</p>
											</p>
										</div>
										<div>

											<p className="text-3xl">
												{filteredPlace.highway_distance !== null && filteredPlace.highway_distance !== undefined
													? `${filteredPlace.highway_distance}km`
													: "--"}
												<p className="font-light">Via Rapida</p>
											</p>
										</div>
										<div>

											<p className="text-3xl">
												{filteredPlace.airport_distance !== null && filteredPlace.airport_distance !== undefined
													? `${filteredPlace.airport_distance}km`
													: "--"}
												<p className="font-light">Aeroporto</p>
											</p>
										</div>
									</div>
								</div>
							</FontDiv>
						))
				)}
			</Wrapped>

			{/*Morada e Imagens depois de selecionar o parque*/}
			{selectedPlaceInfo && (
				<Wrapped className="mt-10 md:mt-16 flex justify-center">
					<div className="flex w-full max-w-[1200px] mt-8 flex-col lg:flex-row">
						{/* Address Section */}
						<div
							className={`p-4 mt-8 text-[#527D5C] w-full`}
						// className={`p-4 mt-8 text-[#527D5C] w-full ${selectedPlaceInfo.images && selectedPlaceInfo.images.length > 0 ? "lg:w-1/3" : "lg:w-full"}`}
						>
							{/* Center content when there are no images */}
							{/* <div className={`${!selectedPlaceInfo.images || selectedPlaceInfo.images.length === 0 ? "flex flex-col items-center justify-center" : ""}`}> */}
							<div className="flex flex-col items-center justify-center">
								<div className="mt-5 ml-5 lg:mr-50">
									<div className="flex items-center gap-2">
										<img src={LocInfoVerde} alt={'LocInfoVerde'} className="w-auto h-12 mx-5 lg:h-12 md:h-10 sm:h-8" />
										{selectedPlaceInfo.address && (
											<>
												{addresses
													.filter(address => address.id === selectedPlaceInfo.id)
													.map(filteredAddress => (
														<div key={filteredAddress.id} className="text-center">
															<AddressP>{filteredAddress.name}</AddressP>
															<AddressP>{filteredAddress.postal}</AddressP>
															<AddressP className="pt-3"><a href={filteredAddress.link} target="_blank" rel="noopener noreferrer" >{filteredAddress.cords}</a></AddressP>
														</div>
													))}
											</>
										)}
									</div>
								</div>
							</div>
						</div>

						{/* Image Section (Only if images exist) */}
						{/* {selectedPlaceInfo.images && selectedPlaceInfo.images.length > 0 && (
							<div className="flex mt-4 w-full lg:w-2/3">
								<div className="flex flex-wrap gap-2 ml-4">


									<img
										src={selectedPlaceInfo.images[0].path}
										alt="Place"
										className="w-full h-auto cursor-pointer object-cover sm:hidden"
										onClick={() => openModal(0)}
									/>

									<div className="hidden sm:flex flex-wrap gap-2">
										{selectedPlaceInfo.images.length === 1 && (
											<img
												src={selectedPlaceInfo.images[0].path}
												alt="Place"
												className="w-full h-auto max-h-[320px] cursor-pointer object-cover lg:max-h-[300px] md:max-h-[200px]"
												onClick={() => openModal(0)}
											/>
										)}
										{selectedPlaceInfo.images.length === 2 && (
											<>
												<img
													src={selectedPlaceInfo.images[0].path}
													alt="Place"
													className="w-full h-auto max-h-[320px] cursor-pointer mr-1 object-cover lg:max-h-[300px] md:max-h-[200px]"
													onClick={() => openModal(0)}
												/>
												<img
													src={selectedPlaceInfo.images[1].path}
													alt="Place"
													className="w-full h-auto max-h-[160px] cursor-pointer object-cover lg:max-h-[160px] md:max-h-[100px]"
													onClick={() => openModal(1)}
												/>
											</>
										)}
										{selectedPlaceInfo.images.length >= 3 && (
											<div className="flex flex-col sm:flex-row gap-2">
												<img
													src={selectedPlaceInfo.images[0].path}
													alt="Place"
													className="w-full sm:w-2/3 h-auto cursor-pointer object-cover"
													onClick={() => openModal(0)}
												/>

												<div className="flex flex-col sm:w-1/3 gap-2">
													<img
														src={selectedPlaceInfo.images[1].path}
														alt="Place"
														className="w-full h-auto cursor-pointer object-cover"
														onClick={() => openModal(1)}
													/>
													<div className="relative">
														<img
															src={selectedPlaceInfo.images[2].path}
															alt="Place"
															className="w-full h-auto cursor-pointer object-cover"
															onClick={() => openModal(2)}
														/>
														<div className="absolute inset-0 flex justify-center items-center bg-black bg-opacity-50">
															<img
																src={PlusImage}
																alt="Plus"
																className="w-10 h-10 cursor-pointer opacity-80 hover:opacity-100 transition-opacity duration-300"
																onClick={() => openModal(2)}
															/>
														</div>
													</div>
												</div>
											</div>
										)}
									</div>

								</div>
							</div>
						)} */}
						{/* Modal to view images in full screen */}
						{/* {isModalOpen && (
							<ImageModal
								images={selectedPlaceInfo.images}
								currentIndex={currentImageIndex}
								closeModal={closeModal}
							/>
						)} */}
					</div>
				</Wrapped >
			)
			}

			{/* Directorio */}
			<Wrapped className="mt-20 md:mt-32">
				<Header2div >
					<h2>Diretório</h2>
				</Header2div>

				<div>

					<SearchFilterContainer>
						<SearchBar
							type="text"
							placeholder="Procurar..."
							value={search}
							onChange={(e) => setSearch(e.target.value)}
						/>

						<div className="flex items-center space-x-2">
							{/* Label for the dropdown */}
							<label htmlFor="parkFilter" className="text-base font-medium text-black">
								Filtrar por Parque:
							</label>

							{/* Filter Dropdown */}
							<FilterDropdown
								id="parkFilter"
								value={filter}
								onChange={(e) => setFilter(e.target.value)}
								className="px-4 py-2 border rounded"
							>
								<option value="All">Todos os Parques</option>
								{uniquePlaceNames.map((name, index) => (
									<option key={index} value={name}>
										{name}
									</option>
								))}
							</FilterDropdown>
						</div>
					</SearchFilterContainer>

					<GridContainer>
						{filteredPlaces
							.flatMap((place) => place.companies)
							.filter((company) =>
								company.name.toLowerCase().includes(search.toLowerCase())
							).length === 0 ? (
							<div className="text-center mt-6 w-full">
								<p>Dados temporariamente indisponiveis.</p>
								<img
									src={emptyImage}
									alt=""
									className="h-auto object-contain mb-2 py-10 mx-auto"
								/>
							</div>
						) : (
							<GridContainer>
								{filteredPlaces
									.flatMap((place) => place.companies)
									.filter((company) =>
										company.name.toLowerCase().includes(search.toLowerCase())
									)
									.slice(0, visibleCount)
									.map((company) => (
										<GridItem
											key={company.id}
											className="relative group flex flex-col items-center"
										>
											<div className="flex flex-col items-center">
												<Image
													src={company.image_path || PlaceHolder}
													alt={company.name}
												/>
												<Title>{company.name}</Title>
											</div>

											<InfoSquareDiv>
												{company.name && <GridInfoP>{company.name}</GridInfoP>}

												{company.email && (
													<GridInfodiv className="flex items-center gap-2">
														<img src={EmailInfo} className="w-auto h-5" alt="Email"/>
														<a href={`mailto:${company.email}`}>{company.email}</a>
													</GridInfodiv>
												)}

												{company.phone && (
													<GridInfodiv className="flex items-center gap-2">
														<img src={PhoneInfo} className="w-auto h-5" alt="Phone"/>
														<span>{company.phone}</span>
													</GridInfodiv>
												)}

												{company.location && (
													<GridInfodiv className="flex items-center gap-2">
														<img src={LocInfo} className="w-auto h-5" alt="Location"/>
														<span>{company.location}</span>
													</GridInfodiv>
												)}

												{company.website && (
													<GridInfodiv className="flex items-center gap-2">
														<img src={WebsiteInfo} className="w-auto h-5" alt="Website"/>
														<a href={company.website} target="_blank" rel="noreferrer">{company.website}</a>
													</GridInfodiv>
												)}

												{company.nif && (
													<GridInfodiv className="flex items-center gap-2">
														<span>NIF</span>
														<span>{company.nif}</span>
													</GridInfodiv>
												)}
											</InfoSquareDiv>
										</GridItem>
									))}
							</GridContainer>
						)
						}
					</GridContainer>

					{filteredPlaces
						.flatMap((place) => place.companies)
						.filter((company) =>
							company.name.toLowerCase().includes(search.toLowerCase())
						).length > visibleCount && (
							<div className="text-center mt-4">
								<ShowMoreBtn onClick={handleShowMore}>
									Mostrar Mais
								</ShowMoreBtn>
							</div>
						)}

				</div>
			</Wrapped>

			{/* Inscrever-me */}
			<WrappedNoOverflow className="mt-20 md:mt-32 text-center">
				<div className="md:mb-8 lg:mb-16">
					<CandP>Interessado em fazer parte dos nossos parques empresariais?</CandP>
					<CandP>Junte-se à nossa comunidade!</CandP>
				</div>
				<InsButton className="mx-auto whitespace-nowrap" onClick={() => navigate('/inscricao', { replace: true })}>
					<CandDiv>
						Candidate-se agora!
					</CandDiv>
				</InsButton>
			</WrappedNoOverflow>


			<div className="relative w-full mt-20 md:mt-32">
				<img src={FooterHero} alt="Footer" className="w-full h-auto" />
				<div className="absolute inset-0 bg-gradient-to-b from-transparent from-80% via-transparent to-[#121E1A] h-1/2 top-1/2"></div>
			</div>
		</>


	);
}

export default Parks;
