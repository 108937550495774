import React from 'react'
import { NewsHero } from '../../../routes/inscricao/styles/inscricao_style'
import NewsHeroBG from "../../../resources/images/news/news_heroo.svg";
import Wrapper from './Wrapper';

function HeroBanner({title}) {
  return (
    <NewsHero className={`min-h-[50vh]`} >
        <div
        className={`absolute top-0 left-0 h-full w-full after:z-[-1] after:block after:absolute after:top-0 after:left-0 after:bg-gradient-to-r  after:to-[transparent] after:w-full after:h-full`}
        >
        <img
            src={
            NewsHeroBG
            }
            alt="main card of highlights"
            className="absolute top-20 left-0 z-[-2] w-full h-full object-cover"


        />
        </div>
        <Wrapper closeFull>
        <div className="flex h-full">
            <div className="flex flex-col gap-5 pt-24 md:pt-32 text-white basis-full">
            <h2 className="text-white text-3xl  font-bold mt-10">
                {title}
            </h2>
            </div>
        </div>
        
        </Wrapper>
    </NewsHero>
  )
}

export default HeroBanner