import React, { useRef, useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import "react-vertical-timeline-component/style.min.css";
import Wrapper from "../../resources/components/general/Wrapper";
import VisaoMontage from "../../resources/images/about/visao_montage.png";
import MissaoMontage from "../../resources/images/about/missao_montage.png";
import MensagemRedonda from "../../resources/images/about/mensagem_redonda.png";
import MountainAbout from "../../resources/images/about/mountain_about.png";
import MpeVideo from "../../resources/videos/MPE.mp4";
import FooterHero from "../../resources/images/footer_banner_trees.svg";
import BannerTree from "../../resources/images/about/banner_down_trees2.png";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

import {
  AboutP,
  ImagesAbout,
  BigImagesAbout,
  SideImagesAbout,
  SideDivAbout,
  CalltoAction,
  GraphsDiv,
  Wrapped,
  MainContainer,
  ScrollingWrapper,
  ScrollingImage,
  Circle,
  Info,
  TreeImage,
  TitleHistory,
} from "./styles/about_styles";
import HeroBanner from "../../resources/components/general/HeroBanner";

// Function to format numbers
const formatNumber = (value) => {
  return new Intl.NumberFormat("pt-PT", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
};

function About() {
  const { ref: ref1, inView: inView1 } = useInView({
    threshold: window.innerWidth < 768 ? 0.2 : 0.5,
    triggerOnce: true,
  });

  const { ref: ref3, inView: inView3 } = useInView({
    threshold: 0.25,
    triggerOnce: true,
  });

  const { ref: ref5, inView: inView5 } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  const { ref: ref7, inView: inView7 } = useInView({
    threshold: 0.25,
    triggerOnce: true,
  });

  const [selectedIndicator, setSelectedIndicator] = useState(1);
  const [selectedOption, setSelectedOption] = useState(null);

  const GraphsData = {
    IndFinanceiro: [
      {
        year: "2021",
        AutonomiaFinanceira: 48.52,
        LiquidezGeral: 27.69,
        Solvabilidade: 94.23,
      },
      {
        year: "2022",
        AutonomiaFinanceira: 57.53,
        LiquidezGeral: 33.85,
        Solvabilidade: 135.46,
      },
      {
        year: "2023",
        AutonomiaFinanceira: 81.78,
        LiquidezGeral: 37.79,
        Solvabilidade: 448.78,
      },
      {
        year: "2024",
        AutonomiaFinanceira: 84.37,
        LiquidezGeral: 64.24,
        Solvabilidade: 539.97,
      },
      {
        year: "PAO (25-27)",
        AutonomiaFinanceira: 84.43,
        LiquidezGeral: 56.83,
        Solvabilidade: 542.44,
      },
    ],
    IndEconomicos: [
      {
        year: "2021",
        EBITDA: 3368190,
        EBIT: 2384949,
        ResultadosBrutos: 1647766,
        ResultadosLiquidos: 1452643,
      },
      {
        year: "2022",
        EBITDA: 5229370,
        EBIT: 4270651,
        ResultadosBrutos: 3549910,
        ResultadosLiquidos: 3056710,
      },
      {
        year: "2023",
        EBITDA: 14963846,
        EBIT: 14022267,
        ResultadosBrutos: 13405503,
        ResultadosLiquidos: 11475660,
      },
      {
        year: "2024",
        EBITDA: 2430278,
        EBIT: 1502009,
        ResultadosBrutos: 1127300,
        ResultadosLiquidos: 952582,
      },
      {
        year: "PAO (25-27)",
        EBITDA: 2207632,
        EBIT: 1276155,
        ResultadosBrutos: 820318,
        ResultadosLiquidos: 664236,
      },
    ],
    IndFinanciamento: [
      { year: "2021", TaxaOcupacao: 70.02 },
      { year: "2022", TaxaOcupacao: 77.52 },
      { year: "2023", TaxaOcupacao: 76.98 },
      { year: "2024", TaxaOcupacao: 80.23 },
    ],
  };

  const CustomTooltip = ({
    active,
    payload,
    label,
    coordinate,
    showPercentage,
  }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            position: "absolute",
            top: coordinate ? coordinate.y - 50 : 0,
            left: coordinate ? coordinate.x - 50 : 0,
            backgroundColor: "#000",
            padding: "10px",
            borderRadius: "5px",
            border: "1px solid #000",
          }}
        >
          {payload.map((data, index) => (
            <p key={index} style={{ color: "#fff", margin: 0 }}>
              {showPercentage ? `${data.value}%` :`${formatNumber(data.value)}€`}
            </p>
          ))}
        </div>
      );
    }

    return null;
  };

  const titleRef = useRef(null);
  const containerRef = useRef(null);
  const circlesRef = useRef([]);
  const [scrollingActive, setScrollingActive] = useState(false);
  const [scrollProgress, setScrollProgress] = useState(0);
  const [visibleCircles, setVisibleCircles] = useState([]);
  const [titleHidden, setTitleHidden] = useState(false);

  useEffect(() => {
    const handleScroll = (e) => {
      const container = containerRef.current;
      if (!container) return;

      const rect = container.getBoundingClientRect();
      const isInView = rect.top <= window.innerHeight * 0.5 && rect.bottom > 0;

      if (isInView) {
        setScrollingActive(true);
      } else {
        setScrollingActive(false);
      }

      if (scrollingActive) {
        e.preventDefault();
        const deltaY = e.deltaY;
        container.scrollLeft += deltaY * 2;

        if (container.scrollLeft > 200) {
          setTitleHidden(true);
        } else {
          setTitleHidden(false);
        }

        const maxScrollLeft = container.scrollWidth - container.clientWidth;
        setScrollProgress(container.scrollLeft / maxScrollLeft);

        if (
          container.scrollLeft >= maxScrollLeft ||
          container.scrollLeft <= 0
        ) {
          setScrollingActive(false);
        }
      }

      const updatedVisibleCircles = [];
      circlesRef.current.forEach((circle, index) => {
        if (circle) {
          const rect = circle.getBoundingClientRect();
          const centerThreshold = window.innerWidth * 0.4;
          const distanceFromCenter = Math.abs(
            rect.left + rect.width / 2 - window.innerWidth / 2
          );
          const scrollLeft = container.scrollLeft;

          let showInfo = false;

          if (distanceFromCenter < 200) {
            showInfo = true;
          } else if (scrollLeft === 0 && index < 3) {
            showInfo = true;
          } else if (
            scrollLeft >= container.scrollWidth - container.clientWidth &&
            index >= circlesRef.current.length - 3
          ) {
            showInfo = true;
          }

          if (showInfo) {
            updatedVisibleCircles.push(index);
            circle.classList.add("show-info");
          } else {
            circle.classList.remove("show-info");
          }
        }
      });

      setVisibleCircles(updatedVisibleCircles);
    };

    window.addEventListener("wheel", handleScroll, { passive: false });
    return () => window.removeEventListener("wheel", handleScroll);
  }, [scrollingActive]);

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  useEffect(() => {
    const calculateWidth = () => {
      const windowHeight = window.innerHeight;
      const calculatedWidth = (windowHeight * 0.7 * 24185) / 3538;

      setHeight(windowHeight * 0.7);
      setWidth(calculatedWidth);
    };

    calculateWidth();

    window.addEventListener("resize", calculateWidth);

    return () => window.removeEventListener("resize", calculateWidth);
  }, []);

  //A Nossa Historia data
  const events = [
    {
      id: 1,
      date: "2001",
      title: "Criação da Madeira Parques Empresaiais S.A.",
      description:
        "A Madeira Parques Empresariais S.A. foi criada em 2001, com o objetivo de promover o desenvolvimento económico da Região Autónoma da Madeira... A empresa tem sido fundamental na criação e gestão dos Parques Empresariais da Madeira e Porto Santo, infraestruturas de excelência, que proporcionam às empresas locais e aos investidores, espaços adequados para a instalação e expansão dos seus negócios...",
      left: 0.195,
      top: 0.61,

      infoLeft: "-105px",
      infoTop: "-180px",
      infoWidth: "110px",
      infoHeight: "175px",
      infoTopInfo: "-180px",
    },
    {
      id: 2,
      date: "2004",
      title: "Inauguração do Parque Empresarial da Calheta",
      description: "O Parque Empresarial da Calheta foi inaugurado em 2004.",
      left: 0.305,
      top: 0.58,

      infoLeft: "-105px",
      infoTop: "-180px",
      infoWidth: "110px",
      infoHeight: "175px",
      infoTopInfo: "-180px",
    },
    {
      id: 3,
      date: "2004",
      title: "Inauguração do Parque Empresarial da Ribeira Brava",
      description:
        "O Parque Empresarial da Ribeira Brava foi inaugurado em 2004.",
      left: 0.37,
      top: 0.55,

      infoLeft: "-105px",
      infoTop: "-180px",
      infoWidth: "110px",
      infoHeight: "175px",
      infoTopInfo: "-180px",
    },
    {
      id: 4,
      date: "2004",
      title: "Inauguração do Parque Empresarial de Santana",
      description: "O Parque Empresarial de Santana foi inaugurado em 2004.",
      left: 0.43,
      top: 0.5,

      infoLeft: "-105px",
      infoTop: "-180px",
      infoWidth: "110px",
      infoHeight: "175px",
      infoTopInfo: "-180px",
    },
    {
      id: 5,
      date: "2004",
      title: "Inauguração do Parque Empresarial do Porto Moniz",
      description:
        "O Parque Empresarial do Porto Moniz foi inaugurado em 2004.",
      left: 0.495,
      top: 0.45,

      infoLeft: "-105px",
      infoTop: "-180px",
      infoWidth: "110px",
      infoHeight: "175px",
      infoTopInfo: "-180px",
    },
    {
      id: 6,
      date: "2004",
      title: "Inauguração do Parque Empresarial de São Vicente",
      description:
        "O Parque Empresarial de São Vicente foi inaugurado em 2004.",
      left: 0.608,
      top: 0.38,

      infoLeft: "-105px",
      infoTop: "-180px",
      infoWidth: "110px",
      infoHeight: "175px",
      infoTopInfo: "-180px",
    },
    {
      id: 7,
      date: "2005",
      title: "Inauguração do Parque Empresarial do Porto Santo",
      description:
        "O Parque Empresarial do Porto Santo foi inaugurado em 2005.",
      left: 0.685,
      top: 0.35,

      infoLeft: "-105px",
      infoTop: "-170px",
      infoWidth: "110px",
      infoHeight: "175px",
      infoTopInfo: "-170px",
    },
    {
      id: 8,
      date: "2005",
      title: "Inauguração do Parque Empresarial de Machico",
      description: "O Parque Empresarial de Machico foi inaugurado em 2004.",
      left: 0.741,
      top: 0.25,

      infoLeft: "40px",
      infoTop: "10px",
      infoWidth: "120px",
      infoHeight: "175px",
      infoLeftafter: "800%",
      infoLeftInfo: "450%",
      infoTopInfo: "75px",
      text: "end",
    },
    {
      id: 9,
      date: "2005",
      title: "Inauguração do Parque Empresarial da Camacha",
      description: "O Parque Empresarial da Camacha foi inaugurado em 2005.",
      left: 0.795,
      top: 0.22,

      infoLeft: "40px",
      infoTop: "10px",
      infoWidth: "120px",
      infoHeight: "175px",
      infoLeftafter: "800%",
      infoLeftInfo: "450%",
      infoTopInfo: "75px",
      text: "end",
    },
    {
      id: 10,
      date: "2008",
      title: "Inauguração do Parque Empresarial da Câmara de Lobos",
      description:
        "O Parque Empresarial da Câmara de Lobos foi inaugurado em 2008.",
      left: 0.85,
      top: 0.12,

      infoLeft: "40px",
      infoTop: "10px",
      infoWidth: "120px",
      infoHeight: "175px",
      infoLeftafter: "800%",
      infoLeftInfo: "450%",
      infoTopInfo: "75px",
      text: "end",
    },
  ];

  return (
    <>
      <div className="bg-[#EEEEEE] pb-10">
        <HeroBanner title="MPE" />
        {/* Mensagem do Secretário */}
        <Wrapped className="mt-20 md:mt-32">
          <div
            className="flex flex-col md:flex-row justify-center items-start h-full gap-10 overflow-hidden"
            ref={ref1}
          >
            <div
              className={`flex flex-col justify-center items-center 3xl:items-center md:items-center w-full md:w-1/2 space-y-4 ${
                inView1 ? "slide-in-left visible" : "invisible"
              }`}
            >
              <h3 className="max-w-[600px] text-[#121E1A] text-2xl lg:text-4xl sm:text-lg md:text-xl font-semibold text-center md:text-left">
                Mensagem do Secretário Regional de Economia, Turismo e Cultura
              </h3>
              <div className="flex justify-center items-center w-full">
                <ImagesAbout src={MensagemRedonda} alt="" className="rounded-full max-h-[400px]"/>
              </div>
            </div>
            <div
              className={`flex flex-col w-full md:w-1/2 space-y-6 ${
                inView1 ? "slide-in-right visible" : "invisible"
              }`}
            >
              <p className="mb-5 text-justify text-[#1D2224] 3xl:text-2xl">
                Desde 2001, a Madeira Parques Empresariais S.A. tem desempenhado
                um papel importante no desenvolvimento económico da Região. Esta
                entidade tem sido fundamental na criação e gestão dos Parques
                Empresariais da Madeira e Porto Santo, infraestruturas de
                excelência, que proporcionam às empresas locais e aos
                investidores, espaços adequados para a instalação e expansão dos
                seus negócios. Através da oferta de parques empresariais
                modernos e bem equipados, a Região Autónoma da Madeira tem
                conseguido atrair investimentos que geram emprego e dinamizam a
                economia regional.
              </p>
              <p className="mb-5 text-justify text-[#1D2224] 3xl:text-2xl">
                A Madeira Parques Empresariais não só disponibiliza espaços
                físicos, mas também promove um ambiente propício à inovação e ao
                empreendedorismo. Com uma gestão eficiente e orientada para as
                necessidades dos empresários, tem facilitado a implementação de
                projetos em diversos setores, desde a indústria tecnológica até
                à construção. Este apoio tem sido essencial para a
                diversificação da nossa economia e para a criação de novas
                oportunidades de negócio na Região.
              </p>
              <p className="mb-5 text-justify text-[#1D2224] 3xl:text-2xl">
                Reafirmamos o nosso compromisso em continuar a fortalecer a
                Madeira Parques Empresariais, reconhecendo a sua importância
                estratégica para o crescimento sustentável da Região.
                Acreditamos que, através do trabalho contínuo nesta área,
                conseguiremos consolidar a posição da Madeira como um destino
                atrativo para investimentos e como um exemplo de desenvolvimento
                económico equilibrado e inovador.
              </p>
              <p className="font-bold text-base 3xl:text-xl">Eduardo Jesus</p>
            </div>
          </div>
        </Wrapped>
      </div>

      {/* Madeira Parques Empresariais */}
      <div className="py-5 mt-20 md:mt-32">
        <Wrapped>
          <div className="flex flex-col justify-center h-full gap-10 w-full">
            <div className="flex flex-col justify-center h-full gap-10 overflow-hidden w-full">
              <div className="flex flex-col w-full mx-auto">
                <h3 className="text-[#172726] text-left text-xl mt-10 lg:text-3xl sm:text-xl md:text-2xl lg:mt-10 sm:mt-10 md:mt-10 font-semibold">
                  {/* Título opcional aqui */}
                </h3>
                <h2 className="text-[#172726] text-left text-2xl mb-10 lg:text-4xl sm:text-2xl md:text-3xl lg:mb-10 sm:mb-10 md:mb-10 font-semibold">
                  Sobre a MPE
                </h2>
                <AboutP className="mb-5">
                  Tendo em vista a promoção de polos de atração ao investimento
                  e desenvolvimento e a redução das assimetrias existentes entre
                  os diversos concelhos da Região Autónoma da Madeira,
                  nomeadamente através da criação de postos de trabalho que
                  permitissem fixar a população residente, foi criada, em 2001,
                  a MPE- Madeira Parques Empresariais, Sociedade Gestora, S.A.,
                  a quem foi concessionada a criação, instalação, gestão,
                  exploração e promoção dos parques empresariais da Calheta,
                  Camacha, Câmara de Lobos, Cancela, Machico, Porto Moniz, Porto
                  Santo, Ribeira Brava, Santana, São Vicente e Zona Oeste.
                </AboutP>
                <AboutP>
                  Estes parques constituem espaços infraestruturados, dotados de
                  serviços de apoio de utilização comum, vocacionados para
                  acolher atividades industriais, de armazenagem, de serviços e
                  de comércio, como uso dominante, e outras que, pelas suas
                  características, se revelem incompatíveis com a sua
                  localização nas restantes categorias de solo urbano, desde que
                  compatíveis com os usos existentes, exercidas por entidades
                  públicas ou privadas.
                </AboutP>
                <AboutP className="mt-5">
                  Acreditamos que os parques Empresariais são um veículo
                  importante no desenvolvimento económico da Região Autónoma da
                  Madeira e um importante contributo para um melhor ordenamento
                  do seu território, melhor qualidade do Ambiente e a melhor
                  solução para a deslocalização das empresas instaladas nos
                  centros urbanos e em situação irregular.
                </AboutP>
              </div>

              {/* Vídeo abaixo do texto */}
              <div className="flex justify-center w-full mt-10 lg:w-[75%] mx-auto">
                <div className="w-full flex justify-center">
                  <BigImagesAbout
                    src={MpeVideo}
                    alt="About Video"
                    className="w-full h-auto rounded-lg shadow-lg"
                    controls
                  />
                </div>
              </div>
            </div>
          </div>
        </Wrapped>
      </div>

      {/* Missão */}
        <Wrapped className="mt-20 md:mt-32 bg-[white]">
          <div
            className="flex flex-col justify-center h-full gap-10 md:flex-row overflow-hidden "
            ref={ref3}
          >
            <SideDivAbout
              className={`flex flex-col slide-in-left w-[100%] ${
                inView3 ? "slide-in-right visible" : "invisible"
              }`}
            >
              <h3 className="text-[#172726] text-left text-2xl lg:text-4xl sm:text-2xl md:text-3xl font-semibold py-5 ">
                Missão
              </h3>
              <AboutP>
                A criação, instalação, gestão, exploração, promoção e manutenção
                dos parques empresariais que lhe foram concessionados, de modo a
                assegurar o acesso dos utentes aos mesmos , com qualidade,
                eficácia e eficiência operacional, contribuindo para o
                desenvolvimento sustentável da Região Autónoma da Madeira.
              </AboutP>
            </SideDivAbout>
            <div
              className={`justify-center flex sm:justify-center md:justify-end lg:justify-end  ${
                inView3 ? "slide-in-right visible" : "invisible"
              }`}
            >
              <SideImagesAbout src={MissaoMontage} alt="" />
            </div>
          </div>
        </Wrapped>
        {/*Postos de Trabalho*/}
        <CalltoAction className="mt-20 md:mt-32">
          <Wrapper>
            <div className={"flex flex-col items-center "}>
              <h2 className="text-[#fff] text-xl md:text-2xl xl:text-2xl font-normal text-center py-5 w-full sm:w-2/3 md:w-1/2 xl:w-full">
                “Geramos oportunidades e impulsionamos a economia local.”
              </h2>
              <p className="text-[#fff] text-4xl lg:text-4xl sm:text-2xl md:text-3xl">
                2246
              </p>
              <p className="text-[#fff] text-m lg:text-lg sm:text-lg md:text-base">
                postos de trabalho
              </p>
            </div>
          </Wrapper>
        </CalltoAction>
        {/* Visão */}
        <Wrapped className="mt-20 md:mt-32">
          <div
            className="flex flex-col justify-center h-full gap-10 md:flex-row overflow-hidden "
            ref={ref5}
          >
            <div
              className={`items-center justify-center md:flex hidden md:justify-end lg:justify-end ${
                inView5 ? "slide-in-left visible" : "invisible"
              }`}
            >
              <SideImagesAbout
                src={VisaoMontage}
                alt=""
                className="max-h-[450px]"
              />
            </div>
            <SideDivAbout
              className={`flex flex-col slide-in-left w-[100%] ${
                inView5 ? "slide-in-right visible" : "invisible"
              }`}
            >
              <h3 className="text-[#172726] text-left text-2xl lg:text-4xl md:text-2xl sm:text-lg font-semibold py-5 ">
                Visão
              </h3>
              <AboutP>
                Contribuir para o desenvolvimento empresarial da Região e para a
                deslocalização das empresas para os parques empresariais
                distribuídos pelos diversos concelhos, onde poderão beneficiar
                das infraestruturas e serviços de apoio de utilização comum
                disponíveis, contribuindo assim, para uma melhoria da qualidade
                de vida, do ambiente e do ordenamento do território
              </AboutP>
              <AboutP>
                Apoiar os novos projetos de investimento, bem como as entidades
                que continuam a laborar com métodos e processos ultrapassados
                e/ou com licenciamentos precários, para que deêm passos no
                caminho da inovação e modernização elevando o patamar de
                competitividade do nosso setor empresarial.
              </AboutP>
            </SideDivAbout>
            <div
              className={`items-center flex justify-center md:hidden md:justify-end lg:justify-end ${
                inView5 ? "slide-in-left visible" : "invisible"
              }`}
            >
              <SideImagesAbout
                src={VisaoMontage}
                alt=""
                className="max-h-[450px]"
              />
            </div>
          </div>
        </Wrapped>

        {/* A Nossa Historia */}
        <div className="relative w-full h-[70vh]">
          <TitleHistory
            ref={titleRef}
            className={titleHidden ? "hide-title" : ""}
          >
            A Nossa História
          </TitleHistory>
          <div className="relative w-full h-full">
            <MainContainer
              ref={containerRef}
              className="mt-[15vh] w-[100vw] h-[70vh] bg-[#1e1612] relative overflow-y-hidden"
            >
              <ScrollingWrapper width={width - width * 0.1}>
                {events.map((event, index) => (
                  <Circle
                    key={index}
                    ref={(el) => (circlesRef.current[index] = el)}
                    lineWidth={event.infoWidth}
                    lineHeight={event.infoHeight}
                    lineTop={event.infoTop}
                    lineLeft={event.infoLeft}
                    lineLeftafter={event.infoLeftafter}
                    style={{
                      left: `${(width - width * 0.1) * event.left}px`,
                      top: `${height * event.top}px`,
                    }}
                  >
                    <Info
                      lineLeftInfo={event.infoLeftInfo}
                      lineTop={`${parseInt(event.infoTopInfo, 10) - 10}px`}
                      text={event.text}
                    >
                      <span className="font-bold text-lg">{event.date}</span>
                      <br></br>
                      <span className="text-base">{event.title}</span>
                    </Info>
                  </Circle>
                ))}
                <ScrollingImage src={MountainAbout} alt="Mountain About"/>
              </ScrollingWrapper>
            </MainContainer>
            <TreeImage src={BannerTree} alt="Trees" />
          </div>
        </div>

        {/* Os nossos Numeros */}
        <Wrapped className="mt-20 md:mt-[356px]">
          <div
            className="flex flex-col justify-center items-start h-full gap-5 lg:flex-row md:flex-col overflow-hidden w-full mb-20"
            ref={ref7}
          >
            <GraphsDiv
              className={`flex flex-col w-full lg:w-1/3 md:w-full ${
                inView7 ? "slide-in-left visible" : "invisible"
              }`}
            >
              <h3 className="text-[#172726] text-left text-2xl 3xl:text-5xl lg:text-4xl sm:text-2xl md:text-3xl font-semibold py-5">
                Os Nossos Números
              </h3>
              <AboutP>
                Trabalhamos para crescer de forma sustentável, apoiar a economia
                local e ajudar empresas a prosperar. Os nossos números refletem
                esse compromisso ao longo dos anos.
              </AboutP>

              {/* Button Sections */}
              <div className="my-3">
                <button
                  onClick={() => {
                    setSelectedOption(null);
                    setSelectedIndicator(1);
                  }}
                  className={`py-2 px-4 border-2 rounded-lg w-full ${
                    selectedIndicator === 1
                      ? "bg-[#255A37] text-white border-[#255A37]"
                      : "border-[#121E1A] text-black"
                  }`}
                >
                  Indicadores de Posição Financeira
                </button>
              </div>
              <div className="my-3">
                <button
                  onClick={() => {
                    setSelectedOption(null);
                    setSelectedIndicator(2);
                  }}
                  className={`py-2 px-4 border-2 rounded-lg w-full ${
                    selectedIndicator === 2
                      ? "bg-[#255A37] text-white border-[#255A37]"
                      : "border-[#121E1A] text-black"
                  }`}
                >
                  Indicadores Económicos
                </button>
              </div>
              <div className="my-3">
                <button
                  onClick={() => {
                    setSelectedOption(null);
                    setSelectedIndicator(3);
                  }}
                  className={`py-2 px-4 border-2 rounded-lg w-full ${
                    selectedIndicator === 3
                      ? "bg-[#255A37] text-white border-[#255A37]"
                      : "border-[#121E1A] text-black"
                  }`}
                >
                  Taxa de Ocupação
                </button>
              </div>
            </GraphsDiv>

            <div className="flex w-full lg:w-2/3 md:w-full sm:w-full">
              {selectedIndicator === 1 && (
                <div className="mt-5 w-full">
                  <div className="w-full h-auto">
                    <ResponsiveContainer width="100%" height={400}>
                      <BarChart
                        data={
                          selectedIndicator === 1
                            ? GraphsData.IndFinanceiro
                            : selectedIndicator === 2
                            ? GraphsData.IndEconomicos
                            : GraphsData.IndFinanciamento
                        }
                        barCategoryGap="80%"
                        barGap={3}
                        margin={{ top: 0, right: 0, left: 25, bottom: 0 }}
                      >
                        <XAxis dataKey="year" />
                        <YAxis unit="%" />
                        <Tooltip
                          content={<CustomTooltip showPercentage={true} />}
                          cursor={{ fill: "transparent" }}
                        />

                        <Bar
                          dataKey="AutonomiaFinanceira"
                          fill="#255A37"
                          name="Autonomia Financeira"
                          opacity={
                            selectedOption === 1
                              ? 1
                              : selectedOption === 2 || selectedOption === 3
                              ? 0.3
                              : 1
                          }
                          animationDuration={800}
                          barSize={30}
                          radius={[5, 5, 0, 0]}
                        />

                        <Bar
                          dataKey="LiquidezGeral"
                          fill="#6C4235"
                          name="Liquidez Geral"
                          opacity={
                            selectedOption === 2
                              ? 1
                              : selectedOption === 1 || selectedOption === 3
                              ? 0.3
                              : 1
                          }
                          animationDuration={800}
                          barSize={30}
                          radius={[5, 5, 0, 0]}
                        />

                        <Bar
                          dataKey="Solvabilidade"
                          fill="#203343"
                          name="Solvabilidade"
                          opacity={
                            selectedOption === 3
                              ? 1
                              : selectedOption === 2 || selectedOption === 1
                              ? 0.3
                              : 1
                          }
                          animationDuration={800}
                          barSize={30}
                          radius={[5, 5, 0, 0]}
                        />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>

                  {/* Sub-Option Buttons */}
                  <div className="mt-3 flex gap-5 justify-center">
                    <button
                      onClick={() => setSelectedOption(1)}
                      className="flex items-center gap-2 text-[#121E1A] w-auto"
                    >
                      <span className="w-8 h-4 bg-[#255A37] rounded-sm"></span>
                      Autonomia Financeira
                    </button>
                    <button
                      onClick={() => setSelectedOption(2)}
                      className="flex items-center gap-2 text-[#121E1A] w-auto"
                    >
                      <span className="w-8 h-4 bg-[#6C4235] rounded-sm"></span>
                      Liquidez Geral
                    </button>
                    <button
                      onClick={() => setSelectedOption(3)}
                      className="flex items-center gap-2 text-[#121E1A] w-auto"
                    >
                      <span className="w-8 h-4 bg-[#203343] rounded-sm"></span>
                      Solvabilidade
                    </button>
                  </div>
                </div>
              )}
              {selectedIndicator === 2 && (
                <div className="mt-5 w-full">
                  <div className="w-full h-auto">
                    <ResponsiveContainer width="100%" height={400}>
                      <BarChart
                        data={
                          selectedIndicator === 1
                            ? GraphsData.IndFinanceiro
                            : selectedIndicator === 2
                            ? GraphsData.IndEconomicos
                            : GraphsData.IndFinanciamento
                        }
                        barCategoryGap="80%"
                        barGap={3}
                        margin={{ top: 0, right: 0, left: 25, bottom: 0 }}
                      >
                        <XAxis dataKey="year" />
                        <YAxis unit="€" />
                        <Tooltip
                          content={<CustomTooltip showPercentage={false} />}
                          cursor={{ fill: "transparent" }}
                        />

                        <Bar
                          dataKey="EBITDA"
                          fill="#255A37"
                          name="EBITDA"
                          opacity={
                            selectedOption === 1
                              ? 1
                              : selectedOption === 4 ||
                                selectedOption === 2 ||
                                selectedOption === 3
                              ? 0.3
                              : 1
                          }
                          animationDuration={800}
                          barSize={30}
                          radius={[5, 5, 0, 0]}
                        />

                        <Bar
                          dataKey="EBIT"
                          fill="#153921"
                          name="EBIT"
                          opacity={
                            selectedOption === 4
                              ? 1
                              : selectedOption === 1 ||
                                selectedOption === 2 ||
                                selectedOption === 3
                              ? 0.3
                              : 1
                          }
                          animationDuration={800}
                          barSize={30}
                          radius={[5, 5, 0, 0]}
                        />

                        <Bar
                          dataKey="ResultadosBrutos"
                          fill="#6C4235"
                          name="Resultados Brutos"
                          opacity={
                            selectedOption === 2
                              ? 1
                              : selectedOption === 4 ||
                                selectedOption === 1 ||
                                selectedOption === 3
                              ? 0.3
                              : 1
                          }
                          animationDuration={800}
                          barSize={30}
                          radius={[5, 5, 0, 0]}
                        />

                        <Bar
                          dataKey="ResultadosLiquidos"
                          fill="#203343"
                          name="Resultados Líquidos"
                          opacity={
                            selectedOption === 3
                              ? 1
                              : selectedOption === 4 ||
                                selectedOption === 2 ||
                                selectedOption === 1
                              ? 0.3
                              : 1
                          }
                          animationDuration={800}
                          barSize={30}
                          radius={[5, 5, 0, 0]}
                        />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>

                  {/* Sub-Option Buttons */}
                  <div className="mt-3 flex gap-5 justify-center">
                    <button
                      onClick={() => setSelectedOption(1)}
                      className="flex items-center gap-2 text-[#121E1A] w-auto"
                    >
                      <span className="w-8 h-4 bg-[#255A37] rounded-sm"></span>
                      EBITDA
                    </button>
                    <button
                      onClick={() => setSelectedOption(4)}
                      className="flex items-center gap-2 text-[#121E1A] w-auto"
                    >
                      <span className="w-8 h-4 bg-[#153921] rounded-sm"></span>
                      EBIT
                    </button>
                    <button
                      onClick={() => setSelectedOption(2)}
                      className="flex items-center gap-2 text-[#121E1A] w-auto"
                    >
                      <span className="w-8 h-4 bg-[#6C4235] rounded-sm"></span>
                      Resultados Brutos
                    </button>
                    <button
                      onClick={() => setSelectedOption(3)}
                      className="flex items-center gap-2 text-[#121E1A] w-auto"
                    >
                      <span className="w-8 h-4 bg-[#203343] rounded-sm"></span>
                      Resultados Líquidos
                    </button>
                  </div>
                </div>
              )}

              {selectedIndicator === 3 && (
                <div className="mt-5 w-full">
                  <div className="w-full h-auto">
                    <ResponsiveContainer width="100%" height={400}>
                      <BarChart
                        data={
                          selectedIndicator === 1
                            ? GraphsData.IndFinanceiroOption1
                            : selectedIndicator === 2
                            ? GraphsData.IndEconomicos
                            : GraphsData.IndFinanciamento
                        }
                        barCategoryGap="80%"
                        barGap={5}
                        margin={{ top: 0, right: 0, left: 25, bottom: 0 }}
                      >
                        <XAxis dataKey="year" />
                        <YAxis unit="%" />
                        <Tooltip
                          content={<CustomTooltip showPercentage={true} />}
                          cursor={{ fill: "transparent" }}
                        />
                        <Bar
                          dataKey="TaxaOcupacao"
                          fill="#255A37"
                          name="Taxa Ocupacao"
                          opacity={1}
                          animationDuration={800}
                          barSize={60}
                          radius={[5, 5, 0, 0]}
                        />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Wrapped>
        <div className="relative w-full mt-20 md:mt-32">
          <img src={FooterHero} alt="Footer" className="w-full h-auto" />
          <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-[#121E1A] h-1/2 top-1/2"></div>
        </div>
    </>
  );
}

export default About;
