import { TextField } from "@mui/material";
import React, { useRef, useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import Wrapper from "../../resources/components/general/Wrapper";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FooterHero from "../../resources/images/footer_banner_trees.svg";
import NewsHeroBG from "../../resources/images/aequipa/aequipa.png";
import axios from "../../providers/axiosConfig";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import "@fullcalendar/core/locales-all";
import "./styles/calendar.css";
import { Box, CircularProgress } from "@mui/material";
import ContactUs from "../../resources/images/about/contact_us.png";

import { Wrapped, NewsHero } from "./styles/agendar_styles";

function Agendar() {
  const form = useRef();
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    try {
      setHasSubmitted(true);
      const currentDate = new Date();
      const selectedDate = new Date(data.date);

      if (selectedDate < currentDate) {
        throw new Error("The selected date cannot be in the past.");
      }

      const selectedSlot = selectedUserAvailabilitySlots
        .filter((slot) => slot.day_of_the_week.toLowerCase() === selectedDay)
        .flatMap((slot) => generateTimeSlots(slot))
        .find(
          (slot) =>
            slot.start_time.toLocaleTimeString() ===
            new Date(data.hour).toLocaleTimeString()
        );

      if (!selectedSlot) {
        throw new Error("Selected time slot not found");
      }

      const start_time = new Date(selectedSlot.start_time).toLocaleTimeString(
        "en-GB",
        { hour: "2-digit", minute: "2-digit" }
      );
      const end_time = new Date(selectedSlot.end_time).toLocaleTimeString(
        "en-GB",
        { hour: "2-digit", minute: "2-digit" }
      );
      const newMeetingData = {
        user_id: data.user,
        title: data.title,
        description: data.description,
        date: data.date,
        start_time: start_time,
        end_time: end_time,
        participant_name: data.participant_name,
        participant_email: data.email,
        participant_phone: data.telephone,
        meeting_type: "onsite",
      };

      const response = await axios.post("/new-meeting", newMeetingData);
      toast.success("O seu pedido foi enviado com sucesso!");

      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      console.log("An error occurred", error);
      console.log(error);
      if (
        error.message &&
        error.message === "The selected date cannot be in the past."
      ) {
        toast.error(
          "Ocorreu um erro ao enviar o seu pedido de reunião. A data da reunião não pode ser anterior à data atual."
        );
      } else {
        toast.error(
          "Ocorreu um erro ao enviar o seu pedido de reunião. Por favor, tente novamente."
        );
      }
      setHasSubmitted(false);
    }
  };

  const [locale, setLocale] = useState("pt");
  const calendarRef = useRef(null);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [meetingData, setMeetingData] = useState([]);
  const [allUserData, setAllUserData] = useState([]);
  const [isUserDataLoading, setIsUserDataLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedUserAvailabilitySlots, setSelectedUserAvailabilitySlots] =
    useState([]);
  const [calendarSlots, setCalendarSlots] = useState({});

  const monthNames = [
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez",
  ];

  const years = Array.from(
    { length: 2 },
    (_, i) => new Date().getFullYear() + i
  );

  const handleDateClick = (info) => {
    const clickedDate = info.dateStr;
    const clickedDay = info.date
      .toLocaleString("en-US", { weekday: "long" })
      .toLowerCase();

    const today = new Date();
    const todayStr = today.toISOString().split("T")[0];

    if (clickedDate < todayStr) {
      toast.error(
        "Não pode selecionar uma data no passado. Selecione uma data válida."
      );
      return;
    }

    if (!calendarSlots || Object.keys(calendarSlots).length === 0) {
      toast.error("Não selecionou um responsável!");
      return;
    }

    if (!calendarSlots[clickedDay]) {
      toast.error(
        "O dia que selecionou é inválido, tente novamente por favor."
      );
      setSelectedDate(null);
      setSelectedDay(null);
      setValue("hour", "");
      return;
    }

    setSelectedDate(clickedDate);
    setSelectedDay(clickedDay);
    setValue("hour", "");
    toast.success(
      `Selecionou o dia ${formatDate(
        clickedDate
      )}. Selecione a hora desejada, por favor.`
    );
    setValue("date", clickedDate);
  };

  const updateDateState = (date) => {
    setCurrentYear(date.getFullYear());
    setCurrentMonth(date.getMonth());
  };

  const handleDateChange = (amount, type) => {
    const calendarApi = calendarRef.current?.getApi();
    if (!calendarApi) return;

    const newDate = new Date(calendarApi.getDate());

    if (type === "month") {
      newDate.setMonth(newDate.getMonth() + amount);
    } else if (type === "year") {
      newDate.setFullYear(newDate.getFullYear() + amount);
    }

    calendarApi.gotoDate(newDate);
    updateDateState(newDate);
  };

  const handleMonthDropdownChange = (e) => {
    const selectedMonth = parseInt(e.target.value, 10);
    const calendarApi = calendarRef.current?.getApi();
    if (!calendarApi) return;
    const currentDisplayedMonth = new Date(calendarApi.getDate()).getMonth();
    const diff = selectedMonth - currentDisplayedMonth;
    handleDateChange(diff, "month");
  };

  const handleYearDropdownChange = (e) => {
    const selectedYear = parseInt(e.target.value, 10);
    const calendarApi = calendarRef.current?.getApi();
    if (!calendarApi) return;
    const currentDisplayedYear = new Date(calendarApi.getDate()).getFullYear();
    const diff = selectedYear - currentDisplayedYear;
    handleDateChange(diff, "year");
  };

  const handleUserSelect = (e) => {
    const userID = e.target.value;
    const user = allUserData.find(
      (userData) => userData.user.id === parseInt(userID)
    );

    if (user) {
      setSelectedUser(user);
      setSelectedUserAvailabilitySlots(user?.availabilitySlots || []);
      setValue("user.id", userID);
    } else {
      setSelectedUser(null);
      setSelectedUserAvailabilitySlots([]);
      setSelectedDate(null);
    }
  };

  const formatTime = (isoDateTime) => {
    const date = new Date(isoDateTime);
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleTimeSelect = (e) => {
    const selectedSlot = e.target.value;
    setSelectedUserAvailabilitySlots(selectedSlot);
    setValue("hour", selectedSlot);
  };

  const generateTimeSlots = (slot) => {
    const startTime = new Date(slot.start_time);
    const endTime = new Date(slot.end_time);
    const slotDuration = slot.slot_duration;
    const intervalBetweenSlots = slot.interval_between_slots;
    const dayOfTheWeek = slot.day_of_the_week;

    const slots = [];
    let currentStartTime = startTime;

    while (currentStartTime < endTime) {
      let currentEndTime = new Date(
        currentStartTime.getTime() + slotDuration * 60000
      );
      if (currentEndTime > endTime) {
        break;
      }

      slots.push({
        start_time: currentStartTime,
        end_time: currentEndTime,
        day_of_the_week: dayOfTheWeek,
      });

      currentStartTime = new Date(
        currentEndTime.getTime() + intervalBetweenSlots * 60000
      );
    }

    return slots;
  };

  const dayCellClassNames = (info) => {
    const dayName = info.date
      .toLocaleString("en-US", { weekday: "long" })
      .toLowerCase();
    if (calendarSlots[dayName]) {
      return "custom-bg-light-green";
    }
    return "";
  };

  useEffect(() => {
    const currentMonthIndex = new Date().getMonth();
    setCurrentMonth(currentMonthIndex);
  }, [locale]);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        const response = await axios.get(`/new-meeting-users`);

        if (!response || !response.data || response.data.length === 0) {
          return;
        }

        if (isMounted) {
          setMeetingData(response.data);
          const allUsers = response.data.map((userData) => {
            return {
              user: userData.user,
              availabilitySlots: userData.availability_slots,
            };
          });
          setAllUserData(allUsers);
          setIsUserDataLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsUserDataLoading(false);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    const map = selectedUserAvailabilitySlots.reduce((map, slot) => {
      if (slot.active) {
        map[slot.day_of_the_week.toLowerCase()] = true;
      }
      return map;
    }, {});
    setCalendarSlots(map);
  }, [selectedUserAvailabilitySlots]);

  return (
    <>
      <NewsHero className="min-h-[80vh]">
        <div
          className={`absolute top-0 left-0 h-full w-full -z-10 after:z-[-1] after:block after:absolute after:top-0 after:left-0 after:bg-gradient-to-b after:from-[#1A211FE5] after:via-[#1A211F80] after:to-[transparent] after:w-full after:h-full`}
        >
          <img
            src={ContactUs}
            alt="main card of highlights"
            className="absolute top-10 left-0 -z-[3] w-full h-full object-cover mt-10 "
            style={{ clipPath: "inset(0px 0px 30% 0px)" }}
          />
        </div>
        <Wrapper closeFull>
          <div className="flex h-full">
            <div className="flex flex-col gap-5 pt-[35vh] sm:pt-[35vh] text-white basis-full">
              <h2 className="text-white text-3xl uppercase font-bold mt-10 text-center">
                Agendar reunião
              </h2>
              <p className="text-sm sm:text-md md:text-lg lg:text-xl text-white text-center">
                Agende a sua reunião connosco de forma fácil e rápida! Preencha
                o formulário abaixo <br />e entraremos em contacto para
                confirmar o horário.
              </p>
            </div>
          </div>
        </Wrapper>
      </NewsHero>

      <Wrapped className=" relative -top-[8vh] 3xl:-top-[10vh] 2xl:-top-[18vh] lg:-top-[16vh] z-10">
        <div className="bg-[#2F5E42] text-white p-10 justify-center text-center">
          <div className="flex flex-col md:px-10 w-full">
            <form
              ref={form}
              className="flex flex-col gap-5 text-left"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="flex lg:flex-row md:flex-col sm:flex-col flex-col w-full gap-24">
                <div className="flex flex-col gap-4 lg:w-1/2 md:w-full sm:w-full w-full">
                  {/* Nome do responsável */}
                  <div className="flex flex-col gap-2">
                    <label className="text-base font-medium text-white">
                      RESPONSÁVEL<span className="text-white">*</span>
                    </label>
                    <Controller
                      control={control}
                      name="user"
                      rules={{ required: "Este campo é obrigatório." }}
                      render={({ field }) => (
                        <select
                          {...field}
                          className="h-10 rounded-[4px] border-[1px] text-black"
                          disabled={isUserDataLoading}
                          onChange={(e) => {
                            handleUserSelect(e);
                            field.onChange(e);
                          }}
                        >
                          <option value="">Selecione um responsável</option>
                          {allUserData.map((user, index) => (
                            <option key={index} value={user.user.id}>
                              {user.user.name}
                            </option>
                          ))}
                        </select>
                      )}
                    />
                    {errors["user"] && (
                      <span className="text-red-500">
                        {errors["user"].message}
                      </span>
                    )}
                  </div>
                  {/*Nome*/}
                  <div className="flex flex-col gap-2">
                    <label className="text-base font-medium text-white">
                      NOME<span className="text-white">*</span>
                    </label>
                    <Controller
                      control={control}
                      name="participant_name"
                      rules={{ required: "Este campo é obrigatório." }}
                      render={({ field }) => (
                        <input
                          {...field}
                          className="h-10 text-black rounded-[4px] border-[1px] focus:outline-none pl-2"
                          type="text"
                          id="participant_name"
                        />
                      )}
                    />
                    {errors.participant_name && (
                      <span className="text-[#ED1D24]">
                        {errors.participant_name.message}
                      </span>
                    )}
                  </div>
                  {/* Email */}
                  <div className="flex flex-col gap-2">
                    <label className="text-base font-medium text-white">
                      EMAIL<span className="text-white">*</span>
                    </label>
                    <Controller
                      control={control}
                      name="email"
                      rules={{
                        required: "Este campo é obrigatório.",
                        pattern: {
                          value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                          message: "Email inválido",
                        },
                      }}
                      render={({ field }) => (
                        <input
                          {...field}
                          className="h-10 text-black rounded-[4px] border-[1px] focus:outline-none pl-2"
                          type="text"
                          id="email"
                        />
                      )}
                    />
                    {errors.email && (
                      <span className="text-[#ED1D24]">
                        {errors.email.message}
                      </span>
                    )}
                  </div>

                  {/* Telemóvel */}
                  <div className="flex flex-col gap-2">
                    <label className="text-base font-medium text-white">
                      TELEMÓVEL<span className="text-white">*</span>
                    </label>
                    <div className="flex flex-row w-full">
                      <div className="flex text-[#707070] rounded-l-[4px] bg-[#E2E2E2] border-[#E2E2E2] border-[1px] flex-col items-center justify-center px-2">
                        +351
                      </div>
                      <Controller
                        name="telephone"
                        control={control}
                        rules={{
                          minLength: {
                            value: 9,
                            message: "Número de telefone inválido",
                          },
                          maxLength: {
                            value: 9,
                            message: "Número de telefone inválido",
                          },
                          pattern: {
                            value: /^9[1236][0-9]{7}|2[1-9]{1,2}[0-9]{7}$/,
                            message: "Número de telefone inválido",
                          },
                        }}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            className="w-full h-full bg-white rounded-[4px] border-[1px] border-[#E2E2E2]"
                            displayEmpty
                            onChange={onChange}
                            value={value}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                borderRadius: "4px",
                                borderColor: "#E2E2E2",
                              },
                              "& .MuiOutlinedInput-input": {
                                paddingLeft: "10px",
                                backgroundColor: "white",
                                borderRadius: "12px",
                                height: "10px",
                              },
                              "& .MuiOutlinedInput-notchedOutline": {
                                border: "none",
                              },
                            }}
                          />
                        )}
                      />
                    </div>
                    {errors.telephone && (
                      <span className="text-[#ED1D24]">
                        {errors.telephone.message}
                      </span>
                    )}
                  </div>

                  {/* Assunto */}
                  <div className="flex flex-col gap-2">
                    <label className="text-base font-medium text-white">
                      ASSUNTO/MOTIVO<span className="text-white">*</span>
                    </label>
                    <Controller
                      control={control}
                      name="title"
                      rules={{ required: "Este campo é obrigatório." }}
                      render={({ field }) => (
                        <input
                          {...field}
                          className="h-10 text-black rounded-[4px] border-[1px] focus:outline-none pl-2"
                          type="text"
                          id="title"
                        />
                      )}
                    />
                    {errors.title && (
                      <span className="text-[#ED1D24]">
                        {errors.title.message}
                      </span>
                    )}
                  </div>

                  {/*Observações*/}
                  <div className="flex flex-col gap-2">
                    <label className="text-base font-medium text-white">
                      OBSERVAÇÕES<span className="text-white">*</span>
                    </label>
                    <Controller
                      control={control}
                      name="description"
                      rules={{ required: "Este campo é obrigatório." }}
                      render={({ field }) => (
                        <textarea
                          {...field}
                          className="text-black rounded-[4px] border-[1px] resize-none focus:outline-none pl-2 pt-2"
                          rows="4"
                          cols="50"
                          id="description"
                        />
                      )}
                    />
                    {errors.description && (
                      <span className="text-[#ED1D24]">
                        {errors.description.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="flex flex-col gap-4 lg:w-1/2 md:w-full sm:w-full w-full">
                  {/* Calendar */}
                  <div className="flex flex-col gap-4">
                    <label className="text-base font-medium text-white">
                      DATA<span className="text-white">*</span>
                    </label>

                    <div className="h-full w-full">
                      <div className="flex flex-row gap-5 mb-5">
                        <select
                          className="text-[#000] p-2 text-center rounded-[4px] border-[1px]"
                          onChange={handleMonthDropdownChange}
                          value={currentMonth}
                        >
                          {monthNames.map((name, index) => (
                            <option key={index} value={index}>
                              {name}
                            </option>
                          ))}
                        </select>
                        <select
                          className="text-[#000] p-2 text-center rounded-[4px] border-[1px]"
                          onChange={handleYearDropdownChange}
                          value={currentYear}
                        >
                          {years.map((year) => (
                            <option key={year} value={year}>
                              {year}
                            </option>
                          ))}
                        </select>
                      </div>

                      <FullCalendar
                        ref={calendarRef}
                        plugins={[dayGridPlugin, interactionPlugin]}
                        initialView="dayGridMonth"
                        locale={locale}
                        dayHeaderFormat={{ weekday: "narrow" }}
                        dateClick={(info) => {
                          handleDateClick(info);
                        }}
                        eventClassNames={(event) => {
                          return ["bg-green-500", "text-white", "border-none"];
                        }}
                        dayCellClassNames={dayCellClassNames}
                        timezone="Europe/Lisbon"
                        headerToolbar={false}
                        editable={true}
                        selectable={true}
                      />
                    </div>

                    {/* Hidden date input */}
                    <input
                      id="date"
                      type="hidden"
                      {...register("date", {
                        required: "Este campo é obrigatório.",
                      })}
                    />
                  </div>
                  {/* Hora */}
                  <div className="flex flex-col gap-2">
                    <label className="text-base font-medium text-white">
                      HORA<span className="text-white">*</span>
                    </label>
                    <div className="relative">
                      <select
                        className="h-10 rounded-[4px] border-[1px] text-black"
                        disabled={
                          !selectedUser ||
                          selectedUserAvailabilitySlots.length === 0 ||
                          !selectedDate
                        }
                        onChange={handleTimeSelect}
                        {...register("hour", {
                          required: "Este campo é obrigatório.",
                        })}
                      >
                        <option value="">Selecione a hora</option>
                        {selectedUserAvailabilitySlots
                          .filter(
                            (slot) =>
                              slot.day_of_the_week.toLowerCase() === selectedDay
                          )
                          .flatMap((slot) => generateTimeSlots(slot))
                          .map((individualSlot, index) => (
                            <option
                              key={index}
                              value={individualSlot.start_time}
                            >
                              {formatTime(individualSlot.start_time)} -{" "}
                              {formatTime(individualSlot.end_time)}
                            </option>
                          ))}
                      </select>
                      {(!selectedUser ||
                        selectedUserAvailabilitySlots.length === 0 ||
                        !selectedDate) && (
                        <div
                          className="absolute inset-0 cursor-not-allowed"
                          onClick={() => {
                            if (
                              !selectedUser &&
                              selectedUserAvailabilitySlots.length === 0
                            ) {
                              toast.error(
                                "Por favor, selecione um responsável antes de escolher um horário."
                              );
                            } else if (!selectedDate) {
                              toast.error(
                                "Por favor, selecione uma data antes de escolher um horário."
                              );
                            }
                          }}
                        />
                      )}
                    </div>
                    {errors.hour && (
                      <span className="text-[#ED1D24]">
                        {errors.hour.message}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              {/*button enviar*/}
              <Box className="flex items-center justify-center pt-10">
                <button
                  type="submit"
                  disabled={hasSubmitted}
                  className={`flex items-center justify-center gap-2 bg-[#121E1A] text-white py-4 px-6 rounded-lg text-lg font-semibold min-w-[150px] min-h-[50px] transition-all duration-300 ease-in-out
        ${
          hasSubmitted
            ? "opacity-50 cursor-not-allowed"
            : "hover:bg-green-600 hover:scale-105"
        }`}
                >
                  {hasSubmitted ? (
                    <>
                      <CircularProgress size={24} style={{ color: "white" }} />A
                      Enviar...
                    </>
                  ) : (
                    "Submeter"
                  )}
                </button>
              </Box>
            </form>
          </div>
        </div>
      </Wrapped>

      <div className="relative w-full mt-10 ">
        <img src={FooterHero} alt="Footer" className="w-full h-auto" />
        <div className="absolute inset-0 bg-gradient-to-b from-transparent from-80% via-transparent to-[#121E1A] h-1/2 top-1/2"></div>
      </div>

      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default Agendar;
