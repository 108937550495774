import "moment/locale/pt";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosConfig from "../../providers/axiosConfig";
import Wrapper from "../../resources/components/general/Wrapper";
import NewsHeroBG from "../../resources/images/aequipa/aequipa.png";
import TeamBanner from "../../resources/images/aequipa/team_banner.png"
import HeroBanner from "../../resources/components/general/HeroBanner";
import {
  ImagesAbout,
  NewsHero,
  TextureBackground,
  Wrapped,
  Card,
} from "./styles/news_style";
import Foto_presidente from "../../resources/images/aequipa/foto_presindente.png";
import Foto_drTeresa from "../../resources/images/aequipa/drTeresaPereira.png";
import Foto_arqElias from "../../resources/images/aequipa/arqElias.png";
import Default_Man from "../../resources/images/aequipa/man_default_avatar.png";
import Default_Woman from "../../resources/images/aequipa/woman_default_avatar.png";
import Acionistas from "../../resources/images/aequipa/acionistas.png";
import Fiscal_Unico from "../../resources/images/aequipa/fiscal_unico.png";
import FooterHero from "../../resources/images/footer_banner_trees.svg";
import Foto_drMiguelFreitas from "../../resources/images/aequipa/drMiguelFreitas.png";

function Aequipa() {
  const [mainNews, setMainNews] = useState();
  const [remainNews, setRemainNews] = useState();
  const [allNews, setAllNews] = useState();
  const [loading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;

  useEffect(() => {
    setLoading(true);
    axiosConfig
      .get(`/news`)
      .then((res) => {
        const data = res.data || [];
        setAllNews(data);
        setMainNews(data[0]);
        setRemainNews(data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, []);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentNews = remainNews?.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages =
    remainNews && Array.isArray(remainNews)
      ? Math.ceil(remainNews.length / itemsPerPage)
      : 0;
  const pageNumbers =
    totalPages > 0 ? [...Array(totalPages).keys()].map((i) => i + 1) : [];

  return (
    <>
      <NewsHero className="min-h-[80vh]">
        <div
          className={`absolute top-0 left-0 h-full w-full -z-10 after:z-[-1] after:block after:absolute after:top-0 after:left-0 after:bg-gradient-to-b after:from-[#1A211FE5] after:via-[#1A211F80] after:to-[transparent] after:w-full after:h-[80%]`}
        >
          <img
            src={TeamBanner}
            alt="main card of highlights"
            className="absolute top-10 left-0 -z-[3] w-full h-full object-cover mt-10 "
            style={{ clipPath: "inset(0px 0px 30% 0px)" }}
          />
        </div>
        <Wrapper closeFull>
          <div className="flex h-full">
            <div className="flex flex-col gap-5 pt-[37vh] sm:pt-[35vh] text-white basis-full">
              <h2 className="text-white text-3xl uppercase font-bold mt-10 text-start">
                A equipa
              </h2>
            </div>
          </div>
        </Wrapper>
      </NewsHero>
      {/* <HeroBanner title='A Equipa'/> */}

      <Wrapped className="mt-0 md:mt-10">
        <div className="flex flex-col md:flex-row justify-center items-start h-full gap-10 overflow-hidden">
          <div
            className={`flex flex-col justify-center items-center 3xl:items-center md:items-center w-full md:w-1/2 space-y-4 `}
          >
            <h2 className="max-w-[600px] text-[#121E1A] text-2xl lg:text-4xl sm:text-lg md:text-xl font-semibold text-left">
              Mensagem do Presidente do Conselho de Administração
            </h2>
            <div className="flex justify-center items-center w-full">
              <ImagesAbout
                src={Foto_presidente}
                alt="Presidente"
                className="rounded-full max-h-[400px]"
              />
            </div>
          </div>
          <div className={`flex flex-col w-full md:w-1/2 space-y-6`}>
            <p className="mb-5 text-justify text-[#1D2224] 3xl:text-2xl">
              A pandemia, a guerra e a inflação provocaram um tremendo choque no
              nosso tecido empresarial. Mas apesar de todas as adversidades que
              nos assolaram ao longo dos últimos anos, a Madeira Parques teve a
              visão e a capacidade de ultrapassar os desafios, reforçando a sua
              trajetória de crescimento sustentável, através do aumento das
              taxas de ocupação nos 12 parques empresariais geridos pela nossa
              instituição.
            </p>
            <p className="3xl:text-2xl">
              Só foi possível vencer porque aqui, na MPE, temos uma equipa
              extraordinária, com excelentes profissionais, bem preparados para
              enfrentar esses mesmos desafios. Tivemos também excelentes
              parceiros, tais como as autarquias e outras entidades ligadas às
              áreas do ordenamento do território, bem como a Secretaria Regional
              de Economia, Turismo e Cultura, que acolheu as nossas propostas,
              visando a captação de investimento privado e tendo como destino os
              parques empresariais.
            </p>
            <br></br>
            <p className="3xl:text-2xl">
              Todos, sem exceção, continuarão a trabalhar em prol do
              desenvolvimento económico e sustentável da nossa Região.
              Continuamos alinhados no sentido de criar valor e riqueza para a
              nossa economia regional. Somos mais de 220 empresas e temos mais
              de 2200 colaboradores diretos a trabalhar nas empresas sediadas
              nos parques empresariais, aumentando assim o grau de
              responsabilidade do Conselho de Administração e dos seus
              acionistas. O nosso propósito será o de continuarmos a trabalhar
              no sentido de garantirmos melhores resultados, quer em termos
              qualitativos, quer em termos quantitativos.
            </p>
            <br></br>
            <p className="font-bold text-base 3xl:text-xl">
              Gonçalo Nuno Pimenta Camacho.
            </p>
          </div>
        </div>
      </Wrapped>

      <Wrapped className="mt-20 md:mt-32">
        <h3 className="text-xl 3xl:text-4xl lg:text-3xl md:text-2xl xl:text-xl md:mb-10 text-center">
          Conselho de Administração
        </h3>
        <div className="flex flex-col md:flex-row justify-center items-end md:gap-20 3xl:gap-32">
          <div className="flex flex-col">
            <div className="flex flex-col h-full bg-gradient-to-b from-[#121E1A] to-[#506D64] bg-opacity-70 pt-10 rounded-[4px] ">
              <div
                className="w-full h-full min-h-[400px] min-w-[350px] 3xl:min-h-[500px] 3xl:min-w-[450px] bg-cover bg-center flex flex-col justify-end items-start"
                style={{
                  backgroundImage: `url(${Foto_presidente})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
            </div>
            <div className=" text-black p-2 max-w-[350px]">
              <h2 className="text-[20px] font-semibold">Dr. Gonçalo Nuno Pimenta Camacho</h2>
              <h3 className="text-[18px] text-[#255A37]">Presidente</h3>
            </div>
          </div>

          <div className="flex flex-col">
            <div className="flex flex-col h-full bg-gradient-to-b from-[#121E1A] to-[#506D64] bg-opacity-70 rounded">
              <div
                className="w-full h-full min-h-[400px] min-w-[350px] bg-cover bg-center flex flex-col justify-end items-start"
                style={{
                  backgroundImage: `url(${Foto_drMiguelFreitas})`,
                  backgroundSize: "contain",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
            </div>
            <div className=" text-black p-2 max-w-[350px]">
              <h2 className="text-[20px] font-semibold">
                Dr. Luis Miguel Pinto Correia Velosa de Freitas
              </h2>
              <h3 className="text-[16px] text-[#255A37]">Vogal Executivo</h3>
            </div>
          </div>

          <div className="flex flex-col">
            <div className="flex flex-col h-full bg-gradient-to-b from-[#121E1A] to-[#506D64] bg-opacity-70 rounded">
              <div
                className="w-full h-full min-h-[400px] min-w-[350px] bg-cover bg-center flex flex-col justify-end items-start"
                style={{
                  backgroundImage: `url(${Default_Man})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
            </div>
            <div className=" text-black p-2 max-w-[350px]">
              <h2 className="text-[20px] font-semibold">
                Arq.to Elias Rodrigues Homem de Gouveia
              </h2>
              <h3 className="text-[18px] text-[#255A37]">Vogal Executivo</h3>
            </div>
          </div>
        </div>
      </Wrapped>

      <Wrapped className="mt-20 md:mt-32">
        <h3 className="text-xl 3xl:text-4xl lg:text-3xl md:text-2xl xl:text-xl md:mb-10 text-center">
          Mesa da Assembleia Geral
        </h3>
        <div className="flex flex-col md:flex-row justify-center items-end md:gap-20 3xl:gap-32">
          <div className="flex flex-col">
            <div className="flex flex-col h-full bg-gradient-to-b from-[#121E1A] to-[#506D64] bg-opacity-70 pt-10 rounded">
              <div
                className="w-full h-full min-h-[400px] min-w-[350px] 3xl:min-h-[500px] 3xl:min-w-[450px] bg-cover bg-center flex flex-col justify-end items-start"
                style={{
                  backgroundImage: `url(${Foto_drTeresa})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
            </div>
            <div className=" text-black p-2 max-w-[350px]">
              <h2 className="text-[20px] font-semibold">
                Dra. Teresa Daniela dos Santos Pereira Figueira Neves
              </h2>
              <h3 className="text-[18px] text-[#255A37]">Presidente</h3>
            </div>
          </div>

          <div className="flex flex-col">
            <div className="flex flex-col h-full bg-gradient-to-b from-[#121E1A] to-[#506D64] bg-opacity-70 rounded">
              <div
                className="w-full h-full min-h-[400px] min-w-[350px] bg-cover bg-center flex flex-col justify-end items-start"
                style={{
                  backgroundImage: `url(${Default_Woman})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
            </div>
            <div className=" text-black p-2 max-w-[350px]">
              <h2 className="text-[20px] font-semibold">
                Eng.ª Micaela Ferreira Armas Gonçalves
              </h2>
              <h3 className="text-[18px] text-[#255A37]">Vice-Presidente</h3>
            </div>
          </div>

          <div className="flex flex-col">
            <div className="flex flex-col h-full bg-gradient-to-b from-[#121E1A] to-[#506D64] bg-opacity-70 rounded">
              <div
                className="w-full h-full min-h-[400px] min-w-[350px] bg-cover bg-center flex flex-col justify-end items-start"
                style={{
                  backgroundImage: `url(${Default_Woman})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
            </div>
            <div className=" text-black p-2 max-w-[350px]">
              <h2 className="text-[20px] font-semibold">Dra. Rubina Cova Viríssimo</h2>
              <h3 className="text-[18px] text-[#255A37]">Secretária</h3>
              <br />
            </div>
          </div>
        </div>
      </Wrapped>

      <Wrapped className="mt-20 md:mt-32">
        <div className="flex flex-col items-center justify-center ">
          <div className="flex flex-col xl:flex-row justify-center items-center w-full md:gap-20 3xl:gap-32">
            <div className="flex justify-center items-center relative">
              <img
                src={Acionistas}
                alt="Qualidade"
                className="max-h-[450px] object-cover justify-center mt-4"
              />
            </div>
            <div className="flex justify-center items-center relative mt-5 mb-6">
              <img
                src={Fiscal_Unico}
                alt="Presidente"
                className="max-h-[450px] object-cover justify-center mt-4"
              />
            </div>
          </div>
        </div>
      </Wrapped>

      <div className="relative w-full mt-20 md:mt-32">
        <img src={FooterHero} alt="Footer" className="w-full h-auto" />
        <div className="absolute inset-0 bg-gradient-to-b from-transparent from-80% via-transparent to-[#121E1A] h-1/2 top-1/2"></div>
      </div>
    </>
  );
}

export default Aequipa;
