import styled, { keyframes, css } from "styled-components";
import HeroSection from "../../../resources/images/about/hero_section.png";
import People from "../../../resources/images/about/people_about.png";
import BannerTree from "../../../resources/images/about/banner_down_trees.png";
import MountainAbout from "../../../resources/images/about/mountain_about.png";


export const AboutP = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  text-color: #1D2224;

  @media only screen and (min-width: 2551px){ 
    font-size: 22px;
  }
`;

export const ImagesAbout = styled.img`
  @media (min-width: 100px) and (max-width: 600px){
    max-width: 12rem;
  }
  @media  (min-width:601px) and (max-width: 1020px) {
    max-width: 18rem;
  }
  @media  (min-width: 1021px) and (max-width: 1440px) { 
    max-width: 20rem;
  }
  @media only screen and (min-width: 1441px) and (max-width: 2550px) { 
    max-width: 24rem;
  }

`;

export const BigImagesAbout = styled.video`
  @media (min-width: 100px) and (max-width: 600px){
    max-width: 28rem;
  }
  @media  (min-width:601px) and (max-width: 1020px) {
    max-width: 36rem;
  }
  @media  (min-width: 1021px) and (max-width: 1440px) { 
    max-width: 48rem;
  }
  @media only screen and (min-width: 1441px) and (max-width: 2550px) { 
    max-width: 60rem;
  }

`;

export const SideImagesAbout = styled.img`
  @media (min-width: 100px) and (max-width: 600px){
    max-width: 24rem;
  }
  @media  (min-width:601px) and (max-width: 1020px) {
    max-width: 20rem;
  }
  @media  (min-width: 1021px) and (max-width: 1440px) { 
    max-width: 30rem;
  }
  @media only screen and (min-width: 1441px) and (max-width: 2550px) { 
    max-width: 36rem;
  }
`;

export const SideDivAbout = styled.div`
  @media (min-width: 100px) and (max-width: 600px){
    width: 100%;
  }
  @media  (min-width:601px) and (max-width: 1020px) {
    width: 100%;
  }
  @media  (min-width: 1021px) and (max-width: 1440px) { 
    width: 50%;
  }
  @media only screen and (min-width: 1441px) and (max-width: 2550px) { 
    width: 50%;
  }
`;

export const CalltoAction = styled.div`
  width: 100vw;
  background-color: #2F5E42;
  position: relative;
  min-height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

    &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: url(${People});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
`;

export const GraphsDiv = styled.div`
  @media (min-width: 100px) and (max-width: 600px){
    width: 90%;
  }
  @media  (min-width:601px) and (max-width: 1020px) {
    width: 55%;
  }
  @media  (min-width: 1021px) and (max-width: 1440px) { 
    width: 45%;
  }
  @media only screen and (min-width: 1441px) and (max-width: 2550px) { 
    width: 40%;
  }
`;

export const Wrapped = styled.div`
    margin-left: 200px;
    margin-right: 200px;
    font-family: 'Open Sans', sans-serif;

  @media (min-width: 100px) and (max-width: 600px){
    margin-left: 16px;
    margin-right: 16px;
    font-family: 'Open Sans', sans-serif;
  }

  @media  (min-width:601px) and (max-width: 1020px) {
    margin-left: 80px;
    margin-right: 80px;
    font-family: 'Open Sans', sans-serif;
  }

  @media  (min-width: 1021px) and (max-width: 1440px) { 
    margin-left: 100px;
    margin-right: 100px;
    font-family: 'Open Sans', sans-serif;
  }

  @media only screen and (min-width: 1441px) and (max-width: 2550px) { 
    margin-left: 200px;
    margin-right: 200px;
    font-family: 'Open Sans', sans-serif;
  }

  @media only screen and (min-width: 2551px) and (max-width: 4000px) { 
    margin-left: 400px;
    margin-right: 400px;
    font-family: 'Open Sans', sans-serif;
  }
`;

export const MainContainer = styled.div`
  margin-top: 15vh;
  width: 100vw;
  height: 68vh;
  background-color: #121E1A;
  position: relative;
  overflow-x: hidden;
`;

export const ScrollingWrapper = styled.div`
  background: url(${MountainAbout}) repeat-x;
  height: 100%;
  width: ${(props) => props.width}px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);


`;

export const ScrollingImage = styled.img`
  height: 100%;
  object-fit: cover;

  

`;

export const Circle = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #D7CCAC;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 4px solid #D7CCAC;
  outline-offset: 4px;

  &::before,
  &::after {
    content: "";
    position: absolute;
    display: none;
  }

  &::before {
    width: ${(props) => props.lineWidth};
    height: 10px;
    border-top: 3px dashed #D7CCAC;
    top: ${(props) => props.lineTop};
    left: ${(props) => props.lineLeft};
  }

  &::after {
    width: 2px;
    height: ${(props) => props.lineHeight};
    border-left: 3px dashed #D7CCAC;
    top: ${(props) => props.lineTop};
    left: ${(props) => props.lineLeftafter || '50%'};
    transform: translateX(-50%);
  }

  &.show-info::before,
  &.show-info::after {
    display: block;
  }
`;

export const Info = styled.div`
  position: absolute;
  width: 133px;
  top: ${(props) => props.lineTop};
  left: ${(props) => props.lineLeftInfo || '-500%'};
  transform: translateX(-50%);
  color: white;
  display: none;
  text-align: ${(props) => props.text || 'start'};
  font-family: 'Open Sans', sans-serif;


  ${Circle}.show-info & {
    display: block;
  }
`;

export const TreeImage = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateY(35%);
  width: 100%;
  height: auto;
  object-fit: cover;
  z-index: 1;
`;

export const TitleHistory = styled.h1`
  position: absolute;
  top: 10%;
  left: 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 2rem;
  color: #ffffff;
  background-color: #7D8B6E4D;
  padding-left: 5vw;
  padding-right: 3vw;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  padding-top: 2vh;
  padding-bottom: 2vh;
  z-index: 2;
  &.hide-title {
      opacity: 0;
      transition: opacity 0.5s ease-out;
  }

  @media (max-width: 768px) {
    &.hide-title {
      opacity: 0;
      transition: opacity 0.5s ease-out;
    }
  }
`;	
