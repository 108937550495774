import React, { useEffect, useState } from "react";
import Wrapper from "../../resources/components/general/Wrapper";
import { SimpleTextureBackground } from "../home/styles/home_styles";
import FooterHero from "../../resources/images/footer_banner_trees.svg";
import NewsHeroBG from "../../resources/images/news/news_heroo.svg";
import { DocumentHero } from "./styles/documents_style";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axiosConfig from "../../providers/axiosConfig";
import moment from "moment";
import "moment/locale/pt";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
} from "@mui/material";

const DocumentList = ({ arr }) => {
  const [expanded, setExpanded] = useState([]);

  useEffect(() => {
    if (arr) {
      const allKeys = Object.keys(arr);
      setExpanded(allKeys);
    }
  }, [arr]);

  const handleAccordionChange = (key) => {
    setExpanded((prevExpanded) =>
      prevExpanded.includes(key)
        ? prevExpanded.filter(item => item !== key)
        : [...prevExpanded, key]
    );
  };

  console.log(expanded, "expanded categories");

  if (!arr || typeof arr !== "object") return null;

  const documents = Object.entries(arr).filter(([key, _]) => !isNaN(key)); // Numeric keys
  const subcategories = Object.entries(arr).filter(([key, _]) => isNaN(key)); // Named keys

  return (
    <>
      {/* Render Direct Documents */}
      {documents.length > 0 &&
        documents.map(([_, document], index) => {
          let date = moment(document?.submission_date).locale("pt").format("YYYY");
          let documentNameTruncated =
            document?.name?.length > 80 ? document.name.slice(0, 80) + "..." : document.name || "Documento Sem Nome";

          return (
            <Accordion
              key={index}
              expanded={expanded.includes(index)}
              onChange={() => handleAccordionChange(index)}
              sx={{ backgroundColor: "transparent", boxShadow: "none" }}
            >
              {/* <AccordionSummary> */}
              <div className="flex items-center justify-between w-full">
                <span className="px-5 py-2 bg-[#E8E7E3] rounded text-[#707070] font-semibold cursor-pointer">
                  {date}
                </span>

                <a
                  href={document.doc_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center text-center justify-center w-full"
                >
                  <span className="text-[#17232780] px-5 py-2">{documentNameTruncated}</span>
                </a>

              </div>
              {/* </AccordionSummary> */}
            </Accordion>
          );
        })}

      {/* Render Subcategories as Nested Accordions */}
      {subcategories.length > 0 &&
        subcategories.map(([key, value], index) => (
          <Accordion
            key={index}
            expanded={expanded.includes(key)}
            onChange={() => handleAccordionChange(key)}
            sx={{ backgroundColor: "transparent", boxShadow: "none" }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <h2 className="uppercase text-[22px] text-[#707070]">{key}</h2>
            </AccordionSummary>
            <AccordionDetails>
              <DocumentList arr={value} />
            </AccordionDetails>
          </Accordion>
        ))}
    </>
  );
};

function Documents() {
  const [documents, setDocuments] = useState(null);
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(null);

  useEffect(() => {
    setLoading(true);
    axiosConfig
      .get(`/documentation/institucional`)
      .then((res) => {
        setDocuments(res.data?.documents);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, []);

  /* useEffect(() => {
    if (documents) {
      const allKeys = Object.keys(documents); 
      setExpanded(allKeys);  
    }
  }, [documents]);

  const handleAccordionChange = (key) => {
    setExpanded((prevExpanded) =>
      prevExpanded.includes(key)
        ? prevExpanded.filter(item => item !== key)  
        : [...prevExpanded, key] 
    );
  }; */

  function handleClick(e) {
    e.preventDefault();
    const href = e.target.getAttribute("href");
    if (!href) return;

    const target = document.querySelector(href);
    if (target) {
      target.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }

  return (
    <>
      <DocumentHero className="relative min-h-[50vh] z-10">
        <div className="absolute top-0 left-0 h-full w-full after:z-[-1] after:block after:absolute after:top-0 after:left-0 after:bg-gradient-to-r after:to-[transparent] after:w-full after:h-full">
          <img
            src={NewsHeroBG}
            alt="main card of highlights"
            className="absolute top-20 left-0 z-[-2] w-full h-full object-cover"
          />
        </div>

        <Wrapper closeFull>
          <div className="flex flex-col gap-5 pt-[100px] text-white basis-full">

            <h2 className="text-white text-3xl font-bold mt-10">Institucional</h2>


          </div>
        </Wrapper>
      </DocumentHero>


      <SimpleTextureBackground className="mt-20 md:mt-32 min-h-[40vh]">
        <Wrapper>
          <div className="flex flex-wrap gap-7 mt-4 items-center justify-left text-center relative bottom-20 xxs:flex-col xxs:items-center xxs:justify-center md:flex-row md:items-start md:justify-start">
            {loading ? (
              <CircularProgress className="mx-auto" sx={{ color: "#FFF" }} />
            ) : (
              documents &&
              Object.entries(documents).map(([key], index) => (
                <div key={index} className="z-20">
                  <a
                    href={`#${key.replace(/[^\w\s]/gi, "").replace(/\s+/g, "")}`}
                    className="uppercase text-black border-2 border-black bg-transparent py-2 px-4 rounded-md hover:text-white hover:bg-black transition flex items-center justify-center whitespace-nowrap w-auto sm:w-auto text-sm md:text-base"
                    onClick={handleClick}
                  >
                    {key}
                  </a>
                </div>
              ))
            )}
          </div>
          <div className="flex flex-col gap-5">

            {loading ? (
              <CircularProgress className="mx-auto" sx={{ color: "#1C6C5E" }} />
            ) : documents ? (
              Object.entries(documents).map(([key, value], index) => (
                <Accordion
                  key={index}
                  id={key.replace(/[^\w\s]/gi, "").replace(/\s+/g, "")}
                  expanded={expanded === key}
                  onChange={() => setExpanded((prev) => (prev === key ? null : key))}
                  sx={{ backgroundColor: "transparent", boxShadow: "none" }}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <h2 className="uppercase text-3xl text-[#707070] font-semibold">
                      {key}
                    </h2>
                  </AccordionSummary>
                  <AccordionDetails>
                    {value ? (
                      <DocumentList arr={value} />
                    ) : (
                      <h3 className="uppercase text-xl text-[#707070] font-medium">
                        Sem documentos disponíveis nesta categoria
                      </h3>
                    )}
                  </AccordionDetails>
                </Accordion>
              ))
            ) : (
              <h3 className="text-2xl xl:text-4xl font-semibold text-center">Brevemente disponível toda a documentação</h3>
            )}
          </div>
        </Wrapper>
      </SimpleTextureBackground>

      <div className="relative w-full mt-20 md:mt-32">
        <img src={FooterHero} alt="Footer" className="w-full h-auto" />
        <div className="absolute inset-0 bg-gradient-to-b from-transparent from-80% via-transparent to-[#121E1A] h-1/2 top-1/2"></div>
      </div>
    </>
  );
}

export default Documents;
