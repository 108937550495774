import { Tabs, Tab, Box, Snackbar, Alert } from "@mui/material";
import "moment/locale/pt";
import React, { useEffect, useState, useRef } from "react";
import { Link, redirect, useNavigate } from "react-router-dom";
import axiosConfig from "../../../providers/axiosConfig";
import { NewsHero, TextureBackground } from "../styles/inscricao_style";
import FooterHero from "../../../resources/images/news/footer_heroo.png";
import PaperPlane from "../../../resources/images/inscricao/paperplane.png"

function Formulario() {
  const [loading, setLoading] = useState(false);

  const [activeTab, setActiveTab] = useState(0);
  const navigate = useNavigate();

  //Snackbar state variables
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const validateTab1 = () => {
    let newErrors = {};

    // Check if fields are empty or invalid
    if (!idPark) newErrors.business_park_id = ["Selecione um parque empresarial"];
    if (!entity.trim()) newErrors.entity = ["O campo entidade é obrigatório"];
    if (!address.trim()) newErrors.address = ["O campo morada é obrigatório"];
    if (!postal_code.match(/^\d{4}-\d{3}$/)) newErrors.postal_code = ["Insira um código postal válido (ex: 9000-000)"];
    if (!city.trim()) newErrors.city = ["O campo concelho é obrigatório"];
    if (!phone.match(/^\d{9}$/)) newErrors.phone = ["Insira um telefone válido (9 dígitos)"];
    if (fax && !fax.match(/^\d{9}$/)) newErrors.fax = ["Insira um fax válido (9 dígitos)"];
    if (!email.match(/^\S+@\S+\.\S+$/)) newErrors.email = ["Insira um email válido"];
    if (website && !website.startsWith("www.")) newErrors.website = ["Insira um website válido (ex: www.exemplo.pt)"];
    if (!nif.match(/^\d{9}$/)) newErrors.nif = ["Insira um NIF válido (9 dígitos)"];

    // Date check (DD-MM-YYYY)
    if (!day || !month || !year || !/^\d{2}$/.test(day) || !/^\d{2}$/.test(month) || !/^\d{4}$/.test(year))
      newErrors.activity_start = ["Insira uma data de início válida"];

    if (!CAE.trim()) newErrors.CAE = ["O campo CAE é obrigatório"];
    if (!branch.trim()) newErrors.branch = ["O campo ramo é obrigatório"];
    if (industrial_license === null) newErrors.industrial_license = ["Selecione uma opção"];
    if (!area.trim() || isNaN(area)) newErrors.area = ["Insira uma área ocupada válida"];

    // Set errors and return the validation result
    setErrors(newErrors);

    // Return true if no errors, false if errors exist
    return Object.keys(newErrors).length === 0;
  };

  const validateTab2 = () => {
    let errors = {};
    let isValid = true;

    // Validate Name
    if (!personName.trim()) {
      errors.person_name = ["O nome é obrigatório."];
      isValid = false;
    }

    // Validate Email
    if (!personEmail.trim()) {
      errors.person_email = ["O email é obrigatório."];
      isValid = false;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(personEmail)) {
      errors.person_email = ["O email inserido não é válido."];
      isValid = false;
    }

    // Validate Phone Number
    if (!personPhone.trim()) {
      errors.person_phone = ["O telefone é obrigatório."];
      isValid = false;
    } else if (!/^\d{9}$/.test(personPhone)) {
      errors.person_phone = ["O telefone deve ter exatamente 9 dígitos numéricos."];
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const validateTab3 = () => {
    let errors = {};
    let isValid = true;

    // Validate Motivo (at least one checkbox should be checked)
    if (motivo.length === 0) {
      errors.motivo = ["Selecione pelo menos um motivo."];
      isValid = false;
    }

    // Validate Current Number of Employees
    if (!current_number_of_employees.trim()) {
      errors.current_number_of_employees = ["O número atual de funcionários é obrigatório."];
      isValid = false;
    } else if (!/^\d+$/.test(current_number_of_employees)) {
      errors.current_number_of_employees = ["Apenas números são permitidos."];
      isValid = false;
    }

    // Validate Future Number of Employees
    if (!future_number_of_employees.trim()) {
      errors.future_number_of_employees = ["O número futuro de funcionários é obrigatório."];
      isValid = false;
    } else if (!/^\d+$/.test(future_number_of_employees)) {
      errors.future_number_of_employees = ["Apenas números são permitidos."];
      isValid = false;
    }

    // Validate Desired Area (Minimum & Maximum)
    if (!min_desired_area.trim()) {
      errors.min_desired_area = ["A área mínima é obrigatória."];
      isValid = false;
    } else if (!/^\d+$/.test(min_desired_area)) {
      errors.min_desired_area = ["Apenas números são permitidos."];
      isValid = false;
    }

    if (!max_desired_area.trim()) {
      errors.max_desired_area = ["A área máxima é obrigatória."];
      isValid = false;
    } else if (!/^\d+$/.test(max_desired_area)) {
      errors.max_desired_area = ["Apenas números são permitidos."];
      isValid = false;
    } else if (parseInt(max_desired_area) < parseInt(min_desired_area)) {
      errors.max_desired_area = ["A área máxima deve ser maior ou igual à mínima."];
      isValid = false;
    }

    // Validate Expected Installation Deadline (Start & End)
    if (!prazoIniciomm.trim() || !prazoInicioaaaa.trim()) {
      errors.expected_deadline_start = ["O prazo de instalação inicial é obrigatório."];
      isValid = false;
    } else if (!/^\d{2}$/.test(prazoIniciomm) || parseInt(prazoIniciomm) < 1 || parseInt(prazoIniciomm) > 12) {
      errors.expected_deadline_start = ["O mês inicial deve estar entre 01 e 12."];
      isValid = false;
    } else if (!/^\d{4}$/.test(prazoInicioaaaa)) {
      errors.expected_deadline_start = ["O ano inicial deve conter 4 dígitos."];
      isValid = false;
    }

    if (!prazoEndmm.trim() || !prazoEndaaaa.trim()) {
      errors.expected_deadline_end = ["O prazo de instalação final é obrigatório."];
      isValid = false;
    } else if (!/^\d{2}$/.test(prazoEndmm) || parseInt(prazoEndmm) < 1 || parseInt(prazoEndmm) > 12) {
      errors.expected_deadline_end = ["O mês final deve estar entre 01 e 12."];
      isValid = false;
    } else if (!/^\d{4}$/.test(prazoEndaaaa)) {
      errors.expected_deadline_end = ["O ano final deve conter 4 dígitos."];
      isValid = false;
    }

    // Validate Expected Investment
    if (!expected_investment.trim()) {
      errors.expected_investment = ["O investimento previsto é obrigatório."];
      isValid = false;
    } else if (!/^\d+(\.\d{1,2})?$/.test(expected_investment)) {
      errors.expected_investment = ["O investimento deve ser um valor numérico."];
      isValid = false;
    }

    // Validate Activity Description
    if (!description.trim()) {
      errors.description = ["A descrição da atividade é obrigatória."];
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };


  const validateTab4 = () => {
    let errors = {};
    let isValid = true;

    // Validate if the user has selected the required option
    if (!selectedOption) {
      errors.errorMessage = "É necessário autorizar o tratamento dos dados pessoais para prosseguir.";
      isValid = false;
    }

    setErrorMessage(errors.errorMessage || null);
    return isValid;
  };

  const handleTabChange = (event, newValue) => {
    let isValid = false;

    // Validate the current tab before switching
    if (activeTab === 0) {
      isValid = validateTab1();
    } else if (activeTab === 1) {
      isValid = validateTab2();
    } else if (activeTab === 2) {
      isValid = validateTab3();
    } else if (activeTab === 3) {
      isValid = validateTab4();
    }

    // Only allow tab change if validation passes
    if (isValid) {
      setActiveTab(newValue);
    }
  };

  const [selectedOption, setSelectedOption] = useState("");
  const tabRefs = [useRef(null), useRef(null)];

  const manejarClickAvancar = () => {
    let isValid = false;

    // Validate the current tab before proceeding
    if (activeTab === 0) {
      isValid = validateTab1();
    } else if (activeTab === 1) {
      isValid = validateTab2();
    } else if (activeTab === 2) {
      isValid = validateTab3();
    } else if (activeTab === 3) {
      isValid = validateTab4();
    }

    // If validation is successful, move to the next tab
    if (isValid) {
      setActiveTab((prevTab) => prevTab + 1);
    }
  };

  const manejarClickretroceder = () => {
    setActiveTab((prevTab) => prevTab - 1);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [activeTab]);


  const [email, setemail] = useState("");

  const handleCheckboxChange = (e) => {
    const value = e.target.value;
    setMotivo(prevMotivo => {
      if (e.target.checked) {

        return [...new Set([...prevMotivo, value])];
      } else {

        return prevMotivo.filter(item => item !== value);
      }
    });
  };

  useEffect(() => {
    setLoading(true);
    axiosConfig
      .get(`/business-parks`)
      .then((res) => {
        setPlaces(res.data || []);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, []);
  const [parques, setPlaces] = useState([]);


  const sendFormulario = async () => {
    setErroPersonName('');
    setErroPersonEmail('');
    setErroPersonPhone('');

    const motivoJsonString = JSON.stringify(motivo);
    console.log("motivo", motivo);

    const activityStart = `${year.padStart(4, '0')}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    const expecedStart = `${prazoInicioaaaa.padStart(4, '0')}-${prazoIniciomm.padStart(2, '0')}-${'01'}`;
    const expecedEnd = `${prazoEndaaaa.padStart(4, '0')}-${prazoEndmm.padStart(2, '0')}-${'28'}`;

    const formData = {
      business_park_id: idPark,
      postal_code,
      entity,
      address,
      city,
      phone,
      fax,
      website,
      nif,
      activity_start: activityStart,
      CAE,
      branch,
      area,
      email,
      industrial_license,
      person_name: personName,
      person_email: personEmail,
      person_phone: personPhone,
      motive: motivoJsonString,
      current_number_of_employees,
      future_number_of_employees,
      min_desired_area,
      max_desired_area,
      expected_deadline_begin: expecedStart,
      expected_deadline_end: expecedEnd,
      expected_investment,
      description,
    };

    try {
      const response = await axiosConfig.post('/applications', formData);

      // Show success popup
      setShowPopup(false);

      setentity('');
      setaddress('');
      setcity('');
      setphone('');
      setfax('');
      setemail('');
      setwebsite('');
      setnif('');
      setCAE('');
      setbranch('');
      setarea('');
      setPersonName('');
      setPersonEmail('');
      setPersonPhone('');
      setMotivo('');
      setcurrent_number_of_employees('');
      setfuture_number_of_employees('');
      setmin_desired_area('');
      setmax_desired_area('');
      setPrazoIniciomm('');
      setPrazoFim('');
      setexpectedinvestment('');
      setdescription('');
      setday('');
      setmonth('');
      setyear('');
      setError(null);
      setpostalCode(''); 

      setErrors({});
      // Trigger Snackbar on success
      /*       setSnackbarMessage("Submissão efetuada com sucesso!");
            setSnackbarSeverity("success"); */
      setSnackbarOpen(true);
      navigate('/?message=Submissão efetuada com successo!&status=success');

    } catch (error) {
      console.error('Erro:', error);

      if (error.response && error.response.data.error) {
        setErrors(error.response.data.error);
        handleErrorMessages(error.response.data.error);
      } else {
        console.log('Erro ao enviar dados para o servidor');
      }
      setSnackbarMessage("Error submitting form");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleErrorMessages = (errorData) => {
    Object.keys(errorData).forEach((field) => {
      console.log(`Erro no campo ${field}:`, errorData[field]);
      setErrorMessage("Formulário preenchido com dados inválidos. Verifique os campos e submeta novamente.");
      setSelectedOption('');
    });
    //Trigger snackbar on error
    setSnackbarMessage(error.response?.data?.message || "Error submitting form");
    setSnackbarSeverity("error");
    setSnackbarOpen(true);
  };

  const [errorMessage, setErrorMessage] = useState("");
  const [errors, setErrors] = useState({});

  const [idPark, setIdPark] = useState('');
  const [entity, setentity] = useState('');
  const [address, setaddress] = useState('');
  const [city, setcity] = useState('');
  const [postal_code, setpostalCode] = useState('');
  const [phone, setphone] = useState('');
  const [fax, setfax] = useState('');
  const [website, setwebsite] = useState('');
  const [nif, setnif] = useState('');
  const [CAE, setCAE] = useState('');
  const [day, setday] = useState('');
  const [month, setmonth] = useState('');
  const [year, setyear] = useState('');
  const [branch, setbranch] = useState('');
  const [industrial_license, setSelectedLicenca] = useState(null);
  const [area, setarea] = useState('');

  const [personName, setPersonName] = useState('');
  const [personEmail, setPersonEmail] = useState('');
  const [personPhone, setPersonPhone] = useState('');
  const [erroPersonName, setErroPersonName] = useState('');
  const [erroPersonEmail, setErroPersonEmail] = useState('');
  const [erroPersonPhone, setErroPersonPhone] = useState('');
  const [motivo, setMotivo] = useState([]);
  const [error, setError] = useState(null);
  const [current_number_of_employees, setcurrent_number_of_employees] = useState('');
  const [future_number_of_employees, setfuture_number_of_employees] = useState('');
  const [min_desired_area, setmin_desired_area] = useState('');
  const [max_desired_area, setmax_desired_area] = useState('');
  const [prazoIniciomm, setPrazoIniciomm] = useState('');
  const [prazoInicioaaaa, setPrazoInicioaaaa] = useState('');
  const [prazoEndmm, setPrazoEndmm] = useState('');
  const [prazoEndaaaa, setPrazoEndaaaa] = useState('');
  const [prazoFim, setPrazoFim] = useState('');
  const [expected_investment, setexpectedinvestment] = useState('');
  const [description, setdescription] = useState('');


  const handlentityChange = (e) => setentity(e.target.value);
  const handleaddressChange = (e) => setaddress(e.target.value);
  const handlecityChange = (e) => setcity(e.target.value);
  const handlecphoneChange = (e) => setphone(e.target.value);
  const handlefaxChange = (e) => setfax(e.target.value);
  const handleemailChange = (e) => setemail(e.target.value);
  const handlewebsiteChange = (e) => setwebsite(e.target.value);
  const handlenifChange = (e) => setnif(e.target.value);
  const handleCAEChange = (e) => setCAE(e.target.value);
  const handlePersonNameChange = (e) => setPersonName(e.target.value);
  const handlePersonEmailChange = (e) => setPersonEmail(e.target.value);
  const handlePersonPhoneChange = (e) => setPersonPhone(e.target.value);
  const handlebranchChange = (e) => setbranch(e.target.value);
  const handleexpectedinvestmentChange = (e) => setexpectedinvestment(e.target.value);
  const handleareaChange = (e) => setarea(e.target.value);

  const motivosDisponibles = [
    "Início da Atividade (empresa a constituir)",
    "Proximidade de Empresas Complementares",
    "Expansão da Atividade",
    "Imposição das Autoridades Competentes",
  ];
  const [showPopup, setShowPopup] = useState(false);

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <>
      <br></br>
      <Snackbar
        open={snackbarOpen}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        message={snackbarMessage}
        autoHideDuration={5000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert variant="filled" severity={snackbarSeverity}>{snackbarMessage}</Alert>
      </Snackbar>
      <TextureBackground className="top-[110px]">
        <h1 className="text-center text-[#255a37] text-[48px] mb-4">Formulário de Inscrição</h1>
        <Box sx={{ width: "100%" }}>
          <div style={{
            display: 'flex', overflowX: 'auto', width: '100%',
          }} className="justify-start sm:justify-center">
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              aria-label="formular tabs"
              sx={{
                flexShrink: 0,
                minWidth: 'fit-content',

              }}
            >
              {["1.Dados da Empresa", "2.Pessoa a Contactar", "3.Dados Sobre o Projecto", "4.Aceitação de Termos"].map((label, index) => (
                <Tab key={index} label={label} sx={{ color: "#255a37", "&.Mui-selected": { color: "#000" } }} />
              ))}
            </Tabs>
          </div>

          {activeTab === 0 && (
            <div>
              {
                <div className="flex justify-center items-center min-h-screen  mb-96 md:mb-80">
                  <div className="w-full max-w-4xl p-8 bg-white rounded-lg shadow-lg">
                    <h3 className="text-[#255a37] text-2xl ">Dados da Empresa</h3>
                    <div className="flex flex-col gap-4 mt-4">
                      <div>
                        <label className="text-[#255a37]">Parque empresarial*</label>
                        <br></br>

                        <select
                          className="w-full h-[48px] p-2 border rounded-lg bg-[#dcdcdc] " value={idPark} onChange={(e) => setIdPark(e.target.value)}
                        >
                          <option value="">Selecione um parque empresarial</option>
                          {parques.length > 0 ? (
                            parques.map((parque) => (
                              <option key={parque.id} value={parque.id}>
                                {parque.name}
                              </option>
                            ))
                          ) : (<option value="">Nenhum parque encontrado</option>)}
                        </select>
                        {errors.business_park_id && <p className="text-red-500 text-sm">{errors.business_park_id[0]}</p>}
                      </div>

                      <div>
                        <label className="text-[#255a37]">Entidade*</label>
                        <br></br>
                        <input
                          type="text"
                          className="w-full h-[48px] p-2 border rounded-lg bg-[#dcdcdc] "
                          placeholder=""
                          value={entity}
                          onChange={(e) => {
                            handlentityChange(e);
                            if (errors.entity) {
                              setErrors((prevErrors) => ({ ...prevErrors, entity: null }));
                            }
                          }}

                        />
                        {errors.entity && <p className="text-red-500 text-sm">{errors.entity[0]}</p>}

                      </div>

                      <div>
                        <label className="text-[#255a37]">Morada*</label>
                        <br></br>
                        <input
                          type="text"
                          className="w-full h-[48px] p-2 border rounded-lg bg-[#dcdcdc]"
                          placeholder=""
                          value={address}
                          onChange={(e) => {
                            handleaddressChange(e);
                            if (errors.address) {
                              setErrors((prevErrors) => ({ ...prevErrors, address: null }));
                            }
                          }}
                        />
                        {errors.address && <p className="text-red-500 text-sm">{errors.address[0]}</p>}
                      </div>

                      <div className="flex gap-4 flex-col sm:flex-row">
                        <div className="flex flex-col w-full sm:w-1/2">
                          <label className="text-[#255a37]">Código Postal*</label>
                          <div className="flex flex-col">
                            <input
                              type="text"
                              className="w-full h-[51px] p-2 border rounded-lg bg-[#dcdcdc]"
                              placeholder="9000-000"
                              maxLength="8"
                              value={postal_code}
                              onChange={(e) => {
                                let value = e.target.value.replace(/\D/g, "");
                                if (value.length > 4) {
                                  value = value.slice(0, 4) + "-" + value.slice(4, 7);
                                }
                                setpostalCode(value);
                                if (errors.postal_code) {
                                  setErrors((prevErrors) => ({ ...prevErrors, postal_code: null }));
                                }
                              }}
                            />
                            {errors.postal_code && <p className="text-red-500 text-sm">{errors.postal_code[0]}</p>}
                          </div>
                        </div>
                        <div className="flex flex-col w-full sm:w-1/2 md:ml-4">
                          <label className="text-[#255a37]">Concelho*</label>
                          <div className="flex flex-col">
                            <input
                              type="text"
                              className="w-full h-[51px] p-2 border rounded-lg bg-[#dcdcdc]"
                              placeholder=""
                              value={city}
                              onChange={(e) => {
                                handlecityChange(e);
                                if (errors.city) {
                                  setErrors((prevErrors) => ({ ...prevErrors, city: null }));
                                }
                              }}
                            />
                            {errors.city && <p className="text-red-500 text-sm">{errors.city[0]}</p>}
                          </div>
                        </div>
                      </div>

                      <div className="flex gap-4 flex-col sm:flex-row">
                        <div className="flex flex-col w-full sm:w-1/2">
                          <label className="text-[#255a37]">Telefone*</label>
                          <div className="flex">
                            <input
                              type="text"
                              className="w-[25%] h-[51px] p-2 border rounded-l-lg bg-[#2f5e42]/50 text-[#121e1a] placeholder-[#121e1a] text-center"
                              placeholder="+351"
                              maxLength="2"
                              disabled
                            />
                            <input
                              type="text"
                              className="w-[75%] h-[51px] p-2 border rounded-r-lg bg-[#dcdcdc] text-[#121e1a] placeholder-[#121e1a] text-center"

                              placeholder="000 000 000*"
                              maxLength="9"
                              onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
                              value={phone}
                              //onChange={handlecphoneChange}
                              onChange={(e) => {
                                handlecphoneChange(e);
                                if (errors.phone) {
                                  setErrors((prevErrors) => ({ ...prevErrors, phone: null }));
                                }
                              }}
                            />
                          </div>
                          {errors.phone && <p className="text-red-500 text-sm">{errors.phone[0]}</p>}
                        </div>
                        <div className="flex flex-col md:ml-4 w-full sm:w-1/2">
                          <label className="text-[#255a37]">Fax</label>
                          <div className="flex">
                            <input
                              type="text"
                              className="w-full h-[51px] p-2 border rounded-lg bg-[#dcdcdc]"
                              placeholder=""
                              maxLength="9"
                              onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
                              value={fax}
                              onChange={(e) => {
                                handlefaxChange(e);
                                if (errors.fax) {
                                  setErrors((prevErrors) => ({ ...prevErrors, fax: null }));
                                }
                              }}
                            />
                            {errors.fax && <p className="text-red-500 text-sm">{errors.fax[0]}</p>}
                          </div>
                        </div>
                      </div>

                      <div className="flex gap-4 flex-col sm:flex-row">
                        <div className="flex flex-col w-full sm:w-1/2">
                          <label className="text-[#255a37]">Email*</label>
                          <input
                            type="text"
                            className="w-full h-[51px] p-2 border rounded-lg bg-[#dcdcdc] "
                            placeholder="info@mre.pt"
                            value={email}
                            onChange={(e) => {
                              handleemailChange(e);
                              if (errors.email) {
                                setErrors((prevErrors) => ({ ...prevErrors, email: null }));
                              }
                            }}
                          />
                          {errors.email && <p className="text-red-500 text-sm">{errors.email[0]}</p>}
                        </div>
                        <div className="flex flex-col md:ml-4 w-full sm:w-1/2">
                          <label className="text-[#255a37]">Website</label>
                          <input
                            type="text"
                            className="w-full h-[51px] p-2 border rounded-lg bg-[#dcdcdc]"
                            placeholder="www.mpe.pt"
                            value={website}
                            onChange={handlewebsiteChange}
                          />
                        </div>
                      </div>


                      <div className="flex gap-4 flex-col sm:flex-row">
                        <div className="flex flex-col w-full sm:w-1/2">
                          <label className="text-[#255a37]">NIF*</label>
                          <div className="flex flex-col">
                            <input
                              type="text"
                              className="w-full h-[51px] p-2 border rounded-lg bg-[#dcdcdc]"
                              placeholder=""
                              maxLength="9"
                              onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
                              value={nif}
                              onChange={(e) => {
                                handlenifChange(e);
                                if (errors.nif) {
                                  setErrors((prevErrors) => ({ ...prevErrors, nif: null }));
                                }
                              }}
                            />
                            {errors.nif && <p className="text-red-500 text-sm">{errors.nif[0]}</p>}

                          </div>
                        </div>

                        <div className="flex flex-col md:ml-4 sm:w-1/2 w-full">
                          <label className="text-[#255a37]">Início de Atividade*</label>
                          <div className="flex">
                            <input
                              type="text"
                              className="w-full h-[48px] p-2 border rounded-l-lg bg-[#dcdcdc] text-[#121e1a] placeholder-[#121e1a] text-center"
                              placeholder="DD"
                              maxLength="2"
                              value={day}
                              onChange={(e) => setday(e.target.value)}
                            />
                            <input
                              type="text"
                              className="w-full h-[48px] p-2 border bg-[#dcdcdc] text-[#121e1a] placeholder-[#121e1a] text-center"
                              placeholder="MM"
                              maxLength="2"
                              value={month}
                              onChange={(e) => setmonth(e.target.value)}
                            />
                            <input
                              type="text"
                              className="w-full h-[48px] p-2 border rounded-r-lg bg-[#dcdcdc] text-[#121e1a] placeholder-[#121e1a] text-center"
                              placeholder="AAAA"
                              maxLength="4"
                              value={year}
                              onChange={(e) => setyear(e.target.value)}
                            />
                          </div>
                          {errors.activity_start && <p className="text-red-500 text-sm">{errors.activity_start}</p>}
                        </div>
                      </div>

                      <div className="flex gap-4 flex-col sm:flex-row">
                        <div className="flex flex-col w-full sm:w-1/2">
                          <label className="text-[#255a37]">CAE*</label>
                          <div className="flex flex-col">
                            <input
                              type="text"
                              className="w-full h-[51px] p-2 border rounded-lg bg-[#dcdcdc]"
                              placeholder=""
                              value={CAE}
                              //onChange={handleCAEChange}
                              onChange={(e) => {
                                handleCAEChange(e);
                                if (errors.CAE) {
                                  setErrors((prevErrors) => ({ ...prevErrors, CAE: null }));
                                }
                              }}
                            />
                            {errors.CAE && <p className="text-red-500 text-sm">{errors.CAE[0]}</p>}
                          </div>
                        </div>

                        <div className="flex flex-col md:ml-4 w-full sm:w-1/2">
                          <label className="text-[#255a37]">Ramo*</label>
                          <div className="flex flex-col">
                            <input
                              type="text"
                              className="w-full h-[51px] p-2 border rounded-lg bg-[#dcdcdc]"
                              placeholder=""
                              value={branch}
                              //onChange={handlebranchChange}
                              onChange={(e) => {
                                handlebranchChange(e);
                                if (errors.branch) {
                                  setErrors((prevErrors) => ({ ...prevErrors, branch: null }));
                                }
                              }}
                            />
                            {errors.branch && <p className="text-red-500 text-sm">{errors.branch[0]}</p>}

                          </div>
                        </div>
                      </div>


                      <div className="flex flex-col">
                        <div className="flex gap-4 mb-4 flex-col sm:flex-row">
                          <div className="flex flex-col w-full sm:w-1/2">
                            <label className="text-[#255a37] mb-2">Possui Licenciamento Industrial?*</label>
                            <div className="flex gap-4">
                              {/* Sim option */}
                              <label className="flex items-center gap-1">
                                <input
                                  type="radio"
                                  name="licenca"
                                  value={1}
                                  className="w-5 h-5 accent-[#2f5e42]"
                                  checked={industrial_license === '1'}
                                  onChange={(e) => {
                                    setSelectedLicenca(e.target.value);
                                    // Clear error message when user selects an option
                                    setErrors((prevErrors) => ({ ...prevErrors, industrial_license: null }));
                                  }}
                                />
                                <span className="text-[#121e1a]">Sim</span>
                              </label>

                              {/* Não option */}
                              <label className="flex items-center gap-1">
                                <input
                                  type="radio"
                                  name="licenca"
                                  value={0}
                                  className="w-5 h-5 accent-[#2f5e42]"
                                  checked={industrial_license === '0'}
                                  onChange={(e) => {
                                    setSelectedLicenca(e.target.value);
                                    // Clear error message when user selects an option
                                    setErrors((prevErrors) => ({ ...prevErrors, industrial_license: null }));
                                  }}
                                />
                                <span className="text-[#121e1a]">Não</span>
                              </label>
                            </div>

                            {/* Error Message */}
                            {errors.industrial_license && <p className="text-red-500 text-sm">{errors.industrial_license[0]}</p>}
                          </div>
                          <div className="flex flex-col md:ml-4 w-full sm:w-1/2">
                            <label className="text-[#255a37]">Área Ocupada*</label>
                            <input
                              type="text"
                              className="w-full h-[51px] p-2 border rounded-lg bg-[#dcdcdc]"
                              placeholder="(m²)"
                              maxLength={7}
                              value={area}
                              // onChange={handleareaChange}
                              onChange={(e) => {
                                handleareaChange(e);
                                if (errors.area) {
                                  setErrors((prevErrors) => ({ ...prevErrors, area: null }));
                                }
                              }}
                            />
                            {errors.area && <p className="text-red-500 text-sm">{errors.area[0]}</p>}
                          </div>
                        </div>
                      </div>

                      <label className="text-[#255a37] mb-2">* Preenchimento obrigatório</label>
                      <div className="relative w-full flex justify-end md:w-fit mx-auto">
                        <input
                          className="bg-[#2F5E42] text-[#FFFFFF] mt-10 w-full md:w-fit md:px-10 py-3 text-lg md:text-1xl font-semibold block shadow-sm rounded-md cursor-pointer hover:shadow-md"
                          type="submit"
                          value="Avançar >"
                          onClick={manejarClickAvancar}
                          onChange={(e) => {
                            manejarClickAvancar(e);
                            if (errors.industrial_license) {
                              setErrors((prevErrors) => ({ ...prevErrors, industrial_license: null }));
                            }
                          }}
                        />

                      </div>

                    </div>
                  </div>
                </div>
              }
            </div>
          )}

          {activeTab === 1 && (
            <div>
              <div className="flex justify-center min-h-screen md:mb-30">
                <div className="w-full max-w-4xl p-8 bg-white rounded-lg shadow-lg max-h-[530px]">
                  <h3 className="text-[#255a37] text-2xl">Pessoa a Contactar</h3>
                  <div className="flex flex-col">
                    <div className="mt-2">
                      <label className="text-[#255a37]">Nome*</label>
                      <input
                        type="text"
                        className={`w-full h-[48px] p-2 border rounded-lg bg-[#dcdcdc] ${erroPersonName ? "border-red-500" : "bg-[#dcdcdc]"}`}
                        value={personName}
                        onChange={(e) => {
                          handlePersonNameChange(e);
                          if (errors.person_name) {
                            setErrors((prevErrors) => ({ ...prevErrors, person_name: null }));
                          }
                        }}
                      />
                      {errors.person_name && <p className="text-red-500 text-sm">{errors.person_name[0]}</p>}
                    </div>
                    <div className="mt-2">
                      <label className="text-[#255a37]">Email*</label>
                      <br />
                      <input
                        type="text"
                        className={`w-full h-[48px] p-2 border rounded-lg ${erroPersonEmail ? "border-red-500" : "bg-[#dcdcdc]"}`}
                        value={personEmail}
                        onChange={(e) => {
                          handlePersonEmailChange(e);
                          if (errors.person_email) {
                            setErrors((prevErrors) => ({ ...prevErrors, person_email: null }));
                          }
                        }}
                      />
                      {errors.person_email && <p className="text-red-500 text-sm">{errors.person_email[0]}</p>}
                    </div>
                    <div className="mt-2">
                      <label className="text-[#255a37]">Telefone*</label>
                      <div className="flex">
                        <input
                          type="text"
                          className="w-[25%] h-[51px] p-2 border rounded-l-lg bg-[#2f5e42]/50 text-[#121e1a] placeholder-[#121e1a] text-center"
                          placeholder="+351"
                          maxLength="2"
                          disabled
                        />
                        <input
                          type="text"
                          className="w-[75%] h-[51px] p-2 border rounded-r-lg bg-[#dcdcdc] text-center"
                          placeholder="000 000 000*"
                          maxLength="9"
                          onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
                          value={personPhone}
                          onChange={(e) => {
                            handlePersonPhoneChange(e);
                            if (errors.person_phone) {
                              setErrors((prevErrors) => ({ ...prevErrors, person_phone: null }));
                            }
                          }}
                        />
                      </div>
                      {errors.person_phone && <p className="text-red-500 text-sm">{errors.person_phone[0]}</p>}
                    </div>
                  </div>
                  <label className="text-[#255a37] mb-2">* Preenchimento obrigatório</label>
                  <div className="relative w-full flex justify-between gap-x-4 mt-10">
                    <input
                      className="bg-[#2F5E42] text-[#FFFFFF] w-full md:w-fit md:px-10 py-3 text-lg md:text-1xl font-semibold block shadow-sm rounded-md cursor-pointer hover:shadow-md"
                      type="button"
                      value="< Retroceder"
                      onClick={manejarClickretroceder}
                    />
                    <input
                      className="bg-[#2F5E42] text-[#FFFFFF] w-full md:w-fit md:px-10 py-3 text-lg md:text-1xl font-semibold block shadow-sm rounded-md cursor-pointer hover:shadow-md"
                      type="submit"
                      value="Avançar >"
                      onClick={manejarClickAvancar}
                    />
                  </div>
                </div>
              </div>
            </div>

          )}

          {activeTab === 2 && (
            <div>
              <div className="flex justify-center min-h-screen mb-96 md:mb-80">
                <div className="w-full max-w-4xl p-8 bg-white rounded-lg shadow-lg">
                  <h3 className="text-[#255a37] text-2xl">Dados sobre o Projecto de Instalação no Parque Empresarial</h3>
                  <div className="flex flex-col">
                    <div className="mt-2">
                      <label className="text-[#255a37]">Motivo*</label>
                      <br />
                      <div className="flex flex-col gap-2">
                        {motivosDisponibles.map((item, index) => (
                          <label key={index} className="text-[#121e1a] flex items-center w-full sm:w-2/5 h-[35px]">
                            <span className="flex-grow">{item}</span>
                            <input
                              type="checkbox"
                              name="motivo"
                              value={item}
                              checked={motivo.includes(item)}
                              onChange={handleCheckboxChange}
                              className="appearance-none w-5 h-5 border-2 border-[#121e1a] rounded-none checked:bg-[#2f5e42] checked:border-transparent"
                            />
                          </label>
                        ))}
                      </div>
                    </div>
                    <br></br>
                    <div className="flex gap-4 flex-col sm:flex-row">
                      <div className="flex flex-col w-full sm:w-1/2">
                        <label className="text-[#255a37]">N.º Atual de Funcionários*</label>
                        <div className="flex">
                          <input
                            type="text"
                            className="w-full h-[48px] p-2 border rounded-lg bg-[#dcdcdc] text-center"
                            placeholder=""
                            maxLength="10"
                            value={current_number_of_employees}
                            onChange={(e) => setcurrent_number_of_employees(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="flex flex-col md:ml-4 w-full sm:w-1/2">
                        <label className="text-[#255a37]">N.º Futuro de Funcionários*</label>
                        <div className="flex">
                          <input
                            type="text"
                            className="w-full h-[48px] p-2 border rounded-lg bg-[#dcdcdc] text-center"
                            placeholder=""
                            maxLength="10"
                            value={future_number_of_employees}
                            onChange={(e) => setfuture_number_of_employees(e.target.value)}
                          />
                        </div>
                      </div>

                    </div>
                    {errors.current_number_of_employees && <p className="text-red-500 text-sm">{errors.current_number_of_employees[0]}</p>}

                    <div className="flex gap-0 sm:gap-4 flex-col sm:flex-row mt-4">
                      <div className="flex flex-col w-full sm:w-1/2">
                        <label className="text-[#255a37]">Área Pretendida*</label>
                        <div className="flex">
                          <input
                            type="text"
                            className="w-full h-[48px] p-2 border rounded-lg bg-[#dcdcdc] text-center"
                            placeholder="Mínima(m²)"
                            maxLength="7"
                            value={min_desired_area}
                            onChange={(e) => setmin_desired_area(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="flex flex-col md:ml-4 w-full sm:w-1/2">
                        <label className="text-[#255a37] mb-6"></label>
                        <div className="flex">
                          <input
                            type="text"
                            className="w-full h-[48px] p-2 border rounded-lg bg-[#dcdcdc] text-center"
                            placeholder="Máxima(m²)"
                            maxLength="7"
                            value={max_desired_area}
                            onChange={(e) => setmax_desired_area(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    {errors.max_desired_area && <p className="text-red-500 text-sm">{errors.max_desired_area[0]}</p>}

                    <div className="flex gap-4 flex-col sm:flex-row mt-4">
                      <div className="flex flex-col w-full sm:w-1/2">
                        <label className="text-[#255a37]">Prazo de Instalação Previsto*:</label>
                        <div className="flex">
                          <label className="text-[#255a37] mr-2">Inicio:</label>
                          <input
                            type="text"
                            className="w-full h-[48px] p-2 border rounded-l-lg bg-[#dcdcdc] text-[#121e1a] placeholder-[#121e1a] text-center"
                            placeholder="MM"
                            maxLength="2"
                            value={prazoIniciomm}
                            onChange={(e) => setPrazoIniciomm(e.target.value)}
                          />
                          <input
                            type="text"
                            className="w-full h-[48px] p-2 border rounded-r-lg bg-[#dcdcdc] text-[#121e1a] placeholder-[#121e1a] text-center"
                            placeholder="AAAA"
                            maxLength="4"
                            value={prazoInicioaaaa}
                            onChange={(e) => setPrazoInicioaaaa(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="flex flex-col md:ml-4 w-full sm:w-1/2">
                        <label className="text-[#255a37] mb-6"> </label>
                        <div className="flex">
                          <label className="text-[#255a37] mr-2">Fim:</label>
                          <input
                            type="text"
                            className="w-full h-[48px] p-2 border rounded-l-lg bg-[#dcdcdc] text-[#121e1a] placeholder-[#121e1a] text-center"
                            placeholder="MM"
                            maxLength="2"
                            value={prazoEndmm}
                            onChange={(e) => setPrazoEndmm(e.target.value)}
                          />
                          <input
                            type="text"
                            className="w-full h-[48px] p-2 border rounded-r-lg bg-[#dcdcdc] text-[#121e1a] placeholder-[#121e1a] text-center"
                            placeholder="AAAA"
                            maxLength="4"
                            value={prazoEndaaaa}
                            onChange={(e) => setPrazoEndaaaa(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    {errors.expected_deadline_end && <p className="text-red-500 text-sm">{errors.expected_deadline_end[0]}</p>}

                    <div className="mt-4">
                      <label className="text-[#255a37]">Investimento Previsto*</label>
                      <br />
                      <input
                        type="text"
                        className="w-full h-[48px] p-2 border rounded-lg bg-[#dcdcdc] text-center"
                        placeholder="€"
                        //onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
                        value={expected_investment}
                        //onChange={(e) => setexpected_investment(e.target.value)}
                        onChange={(e) => {
                          handleexpectedinvestmentChange(e);
                          if (errors.expected_investment) {
                            setErrors((prevErrors) => ({ ...prevErrors, expected_investment: null }));
                          }
                        }}
                      />
                      {errors.expected_investment && <p className="text-red-500 text-sm">{errors.expected_investment[0]}</p>}
                    </div>

                    <div className="mt-4">
                      <label className="text-[#255a37]">Descrição de Atividade a Desenvolver no Parque Empresarial*</label>
                      <br />
                      <textarea
                        type="text"
                        className="w-full h-[190px] p-2 border rounded-lg bg-[#dcdcdc] "
                        value={description}
                        onChange={(e) => setdescription(e.target.value)}
                        rows="5"
                      />
                      {errors.description && <p className="text-red-500 text-sm">{errors.description[0]}</p>}
                    </div>

                  </div>
                  <label className="text-[#255a37] mb-4">* Preenchimento obrigatório</label>
                  <div className="relative w-full flex justify-between gap-x-4 mt-10">
                    <input
                      className="bg-[#2F5E42] text-[#FFFFFF] w-full md:w-fit md:px-10 py-3 text-lg md:text-1xl font-semibold block shadow-sm rounded-md cursor-pointer hover:shadow-md"
                      type="button"
                      value="< Retroceder"
                      onClick={manejarClickretroceder}
                    />
                    <input
                      className="bg-[#2F5E42] text-[#FFFFFF] w-full md:w-fit md:px-10 py-3 text-lg md:text-1xl font-semibold block shadow-sm rounded-md cursor-pointer hover:shadow-md"
                      type="button"
                      value="Avançar >"
                      onClick={manejarClickAvancar}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {activeTab === 3 && (
            <div className="flex justify-center min-h-screen md:mb-10">
              <div className="w-full max-w-4xl p-8 bg-white rounded-lg shadow-lg max-h-[650px] sm:max-h-[380px]">
                <div className="flex flex-col">
                  <div className="mt-2">
                    {errorMessage && (<p className="text-red-500 font-semibold text-sm mt-2">{errorMessage}</p>)}
                    <div className="flex flex-col gap-2">
                      <label className="text-[#121e1a] flex items-start w-full max-w-[753px] h-auto gap-2">
                        <input
                          type="radio"
                          name="motivo"
                          value="opcao1"
                          className="w-5 h-5 border-2 border-[#121e1a] rounded-none checked:bg-[#2f5e42] checked:border-transparent"
                          aria-label="Autorizo o tratamento dos meus dados pessoais"
                          onChange={(e) => {
                            setSelectedOption(e.target.value);
                            if (errorMessage) {
                              setErrorMessage(null);
                            }
                          }}
                        />
                        <span className="text-sm leading-relaxed">
                          Autorizo a MPE - Madeira Parques Empresariais, Sociedade Gestora S.A., a tratar os
                          meus dados pessoais acima identificados, durante o prazo de cinco anos, nos termos
                          do Regulamento (EU) 2016/679, do Parlamento Europeu e do Conselho de 27 de Abril de
                          2016, relativo à proteção de dados pessoais e à livre circulação dos mesmos, para
                          efeitos de futuros contactos, em caso de disponibilidade do espaço adequado ao
                          exercício da atividade da empresa, no âmbito da concessão do serviço público de
                          criação, instalação, gestão, exploração e promoção dos parques empresariais da Região
                          Autónoma da Madeira, tendo sido informado da possibilidade de a todo e qualquer
                          momento, colocar as questões relativas à proteção de dados e, ainda, de retirar o
                          meu consentimento através do {" "}
                          <a
                            href="mailto:rgpd@mpe.pt"
                            className="text-[#2f5e42] underline"
                          >
                            rgpd@mpe.pt
                          </a>{" "}
                          *
                        </span>
                      </label>
                    </div>

                    <label className="text-[#255a37] mb-2">* Preenchimento obrigatório</label>
                    <div className="relative w-full flex justify-between gap-x-4 mt-10">
                      <input
                        className="bg-[#2F5E42] text-[#FFFFFF] w-full md:w-fit md:px-10 py-3 text-lg md:text-1xl font-semibold block shadow-sm rounded-md cursor-pointer hover:shadow-md"
                        type="submit"
                        value="< Retroceder"
                        onClick={manejarClickretroceder}
                      />
                      <input
                        className={`bg-[#2F5E42] text-[#FFFFFF] w-full md:w-fit md:px-10 py-3 text-lg md:text-1xl font-semibold block shadow-sm rounded-md cursor-pointer hover:shadow-md ${!selectedOption ? "opacity-50 cursor-not-allowed" : ""
                          }`}
                        type="submit"
                        value="Submeter >"
                        disabled={!selectedOption}
                        onClick={sendFormulario}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Box>
      </TextureBackground>

      {/* Popup Box */}
      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-50">
          <div className="bg-[#121E1A] rounded-lg shadow-lg text-white flex w-[30%] h-[600px]">

            <div
              className="flex flex-col items-start justify-between w-auto h-64 relative"
              style={{
                backgroundImage: `url(${PaperPlane})`,
                backgroundSize: '450px auto',
                backgroundPosition: 'left top',
                backgroundRepeat: 'no-repeat',
              }}
            >
              <div className="px-12">
                <div className="my-44">
                  <p className="text-3xl font-semibold text-left">OBRIGADO PELO O SEU INTERESSE!</p>
                  <p className="mt-2 text-left text-lg">Recebemos a sua inscrição e em breve entraremos em contacto. Fique atento às novidades!</p>
                </div>
                <button
                  className="my-5 bg-[#255A37] text-white px-8 py-2 rounded-md hover:cursor-pointer"
                  onClick={() => window.location.href = '/'}
                >
                  Continuar a Explorar
                </button>
              </div>
            </div>
            <div className="w-[40px] h-full"></div>
          </div>
        </div>
      )}


      <div className="relative w-full mt-20 md:mt-32">
        <img src={FooterHero} alt="Footer" className="w-full h-auto" />
        <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent from-80% to-[#121E1A]"></div>
      </div>
    </>
  );
}

export default Formulario;
